@import 'styles/base.scss';


.header {
  @include row(center, center);
  position: relative;
  height: 81px;
  background-color: map-get($colors, white);
  user-select: none;
  border-bottom: solid 1px map-get($colors, natural);

  h1 {
    @include font($size: 24px, $weight: medium, $color: midnight);
  }

  .close {
    position: absolute;
    top: calc(50% - 16px);
    right: 25px;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    color: map-get($colors, midnight);

    &:hover {
      color: map-get($colors, midnight);
    }
  }
}

.body {
  @include column(stretch, flex-start);
  height: calc(100vh - 275px);
  background-color: map-get($colors, natural);
  overflow-y: auto;

  .empty {
    @include column(center, flex-start);
    @include font($size: 14px, $weight: light);
    padding: 48px 16px 16px;

    a {
      margin-top: 4px;
    }
  }
}

.footer {
  position: absolute;
  background-color: map-get($colors, white);
  border-top: solid 1px map-get($colors, natural);
  bottom: 0;
  left: 0;
  width: 100%;

  .line {
    @include row(space-between, center);
    position: sticky;
    bottom: 0;
    padding: 4px 16px;
    background-color: map-get($colors, white);

    p {
      @include font($size: 14px, $color: midnight, $weight: light);
    }

    b {
      @include font($size: 16px, $color: midnight, $weight: bold);
    }

    .value {
      @include font($size: 16px, $lineHeight: 24px, $color: midnight, $weight: regular);

      &--credit {
        color: map-get($colors, actionable-green);
      }

      &--heavy {
        @include font($weight: semibold);
      }
    }

    &.hasTopLine {
      margin-top: 8px;
      padding-top: 12px;
      border-top: solid 1px map-get($colors, grey-2);
    }

    &.hasBottomLine {
      margin-bottom: 8px;
      padding-bottom: 12px;
      border-bottom: solid 1px map-get($colors, grey-2);
    }

    &:first-child {
      padding-top: 12px;
    }
  }

  .actions {
    @include column(stretch, flex-start);
    gap: 8px;
    padding: 16px;

    button {
      border-radius: 12px;
    }
  }
}

.items {
  background-color: map-get($colors, natural);
  overflow-y: auto;

  @include for-size(mobile) {
    overflow-y: visible;
  }

  .group {
    padding: 16px 24px;
    user-select: none;
    position: sticky;
    top: 0;
    background-color: map-get($colors, natural);
    border-top: solid 1px map-get($colors, grey-2);
    border-bottom: solid 1px map-get($colors, grey-2);

    &:first-child {
      margin-top: 0px;
      border-top: none;
    }

    .groupName {
      margin: 0;
      @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
    }

    .groupDescription {
      margin: 0;
      @include font($size: 14px, $lineHeight: 24px, $color: grey-10);
    }

  }

  .item {
    @include row(space-between, center);
    padding: 16px 24px;
    background-color: map-get($colors, white);
    user-select: none;
    margin-bottom: 1px;

    b {
      @include font($size: 16px, $lineHeight: 24px, $color: midnight, $weight: semibold);
    }

    p {
      @include font($size: 14px, $lineHeight: 24px, $color: grey-12);
    }

    .info {

    }

    .actions {
      @include row(flex-end, center);
      gap: 12px;

      .dangerIcon {
        &:hover {
          color: red;
        }
      }
    }
  }
}


.flexSpacer {
  flex: 1;

  @include for-size(mobile) {
    display: none;
  }
}


.container {
  background-color: transparent;
  pointer-events: none;
  transition: all 0.4s ease;

  &--toggled {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
  }

  .cart {
    background-color: map-get($colors, natural);
    transition: right 0.4s 0s ease, opacity 0s 0.4s;
    @include for-size(mobile) {
      right: -100%;
    }
  }

  &-type {
    &-fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      width: 100vw;
      height: 100vh;

      .cart {
        position: absolute;
        right: -380px;
        top: 0;
        height: 100%;
        width: 375px;

        @include for-size(mobile) {
          width: 100%;
          right: -100%;
        }

        .body {
          @include for-size(mobile) {
            padding-bottom: 156px;
          }
        }
      }

      &.container--toggled {
        .cart {
          right: 0;
          opacity: 1;
        }
      }
    }

    &-block {
      position: relative;

      .cart {
        border-radius: 12px;
        width: 100%;
        background-color: map-get($colors, natural);
        opacity: 0;
        border: solid 1px map-get($colors, grey-2);

        .body {
          @include column(stretch, flex-start);
          height: auto;
        }

        .footer {
          position: relative;
          border-top: none;

          .total {
            border-bottom: none;
          }
        }
      }

      &.container--toggled {
        background-color: transparent;

        .cart {
          opacity: 1;
        }
      }
    }
  }
}
