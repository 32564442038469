@import 'styles/base.scss';

.base {
  width: 100%;

  &--loading {

  }

  & div > p {
    text-align: center;
  }

  .failure {
    @include font($weight: medium);
    color: map-get($colors, error-dark);
    text-align: center;
    margin-bottom: 24px;
  }

  .view, .processorChoice {
    width: 100%;
    margin: 0 auto;

    .title {
      @include row(flex-start, center);
      @include font($family: secondary, $weight: bold, $size: 24px);
      margin: 0 0 24px;
      position: relative;

      .backIcon {
        top: 0px;
        left: -4px;

        &:hover {
          color: map-get($colors, actionable-green)
        }
      }
    }

    .skeletonField {
      height: 56px;
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .done {

  }
}

.sdk-form-loader {
  font-size: 20px;
  position: absolute;
  color: map-get($colors, grey-4);
  left: calc(50% - 12px);
  top: calc(50% - 9px);
  z-index: 99;
}