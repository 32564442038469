@import 'styles/base.scss';

.base {
  margin-top: 48px;
  border-top: solid 1px map-get($colors, grey-2);
  padding: 48px 0;

  h3 {
    @include font($size: 22px, $lineHeight: 36px, $weight: bold, $color: midnight);
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }

  .detailsBlock {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}