@import 'styles/base.scss';

.accordion {
  background: white;

  .accordionItem {
    height: 51px;
    max-height: 51px;
    overflow: hidden;
    transition: all 0.5s ease;

    &--open {
      max-height: 800px;
      height: auto;
    }

    .accordionTop {
      text-align: left;
      line-height: 40px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid map-get($colors, grey-2);

      .title {
        @include font($family: secondary-semibold, $size: 18px, $lineHeight: 22px);
        position: absolute;
        left: 60px;
      }
      .caret {
        position: absolute;
        right: 10px;
      }
      svg {
        height: 30px;
        width: auto;
      }
    }

    .description {
      padding: 16px;
      padding-top: 5px;
      text-align: left;
      border-bottom: 1px solid map-get($colors, grey-2);
    }

    &--open {
      .accordionTop {
        border-color: transparent;
      }
    }
  }
}
