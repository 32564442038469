@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 28px 24px;
  background: map-get($colors, white);
  text-align: center;

  @include for-size(mobile) {
    padding: 20px 24px;
  }

  h3 {
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.button {
  min-width: 225px;
  @include for-size(mobile) {
    min-width: 0px;
  }
}

.subContent {
  margin-top: 16px;
  p {
    @include font($size: 18px, $lineHeight: 26px);
  }
}
