@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 56px 0px 40px;
  background-color: map-get($colors, natural);
  position: relative;

  h3 {
    width: 100%;
    padding: 0 24px 16px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      font-size: 32px;
    }
  }
}

.carousel {
  width: 1172px;
  margin: auto;
  padding-top: 8px;
  padding-bottom: 20px;

  @include for-size(1172px) {
    width: 100%;
  }

  :global(.slick-dots) {
    li {
      top: -12px;
      margin: 0;

      button {
        padding: 0;
      }
    }
  }

  button[class*='slick-arrow '] {
    &:before {
      display: inline-block;
      font: normal normal normal 14px/1 CustomIconsVF !important;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-size: 50px !important;
      color: map-get($colors, grey-5) !important;
      line-height: 30px !important;

      &:hover {
        color: map-get($colors, midnight) !important;
      }
    }

    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  button[class*='slick-disabled'] {
    display: none !important;
  }

  button[class*='slick-next'] {
    &:before {
      content: '\e830' !important;
    }

    right: -35px;

    @include for-size(1040px) {
      right: -15px;
    }
  }

  button[class*='slick-prev'] {
    &:before {
      content: '\e82f' !important;
    }

    left: -50px;

    @include for-size(1040px) {
      left: -34px;
    }
  }
}

.banner {
  @include font($color: white, $size: 18px, $weight: semiBold);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 27px;
  background-color: map-get($colors, lush);

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: white;
    }
  }
}
