@import 'styles/base.scss';

.link {
  font-family: map-get($font-family, secondary-bold);
  text-decoration: underline;
  cursor: pointer;
}

.linkButton {
  height: 24px;
  font-family: map-get($font-family, secondary-medium);
  font-size: 14px;
  line-height: 24px;
  color: map-get($colors, midnight);
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 15px;

  @include for-size(mobile) {
    float: left;
    width: 100%;
    margin-bottom: 0;
  }

  &:hover {
    color: map-get($colors, lush);
  }
}


.terms {
  margin: 8px 0;
  text-align: center;

  @include for-size(mobile) {
    font-size: 14px;

    @include for-size(330px) {
      font-size: 12px;
    }
  }
}


.inputsWrapper {
  @include row(center, center);
  flex-wrap: nowrap;
  gap: 16px;

  &--disabled {
    pointer-events: none;
  }


  .input {
    width: 100%;
    height: 87px !important;
    background: map-get($colors, grey-1);
    font-size: 28px;
    font-family: map-get($font-family, secondary-medium);
    text-align: center;
    outline: none;
    padding: 0;
    border: 0;
    border-radius: 6px;
    margin-bottom: 10px !important;
    flex-shrink: 1;

    @include for-size(mobile) {
      width: 69px;

      @include for-size(330px) {
        width: 55px;
        height: 75px !important;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}