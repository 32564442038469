@import 'styles/base.scss';

.summaryPricingItem {
  @include row(space-between, center);
  @include font($size: 14px, $lineHeight: 24px);
  width: 100%;
  margin-bottom: 8px;
}

.semiHeavy {
  @include font($size: 14px, $weight: medium, $lineHeight: 24px);
}

.heavy {
  @include font($size: 16px, $weight: bold, $lineHeight: 24px);
}

.highlight {
  @include font($size: 14px, $weight: bold, $lineHeight: 24px, $color: lush);
}

.separateBefore {
  border-top: solid 1px map-get($colors, grey-2);
  margin-top: 8px;
  padding-top: 8px;
}

.tooltipIcon {
  margin-left: 4px;
  cursor: pointer;
}

.right {
  i {
    font-size: 18px;

    &:hover {
      color: map-get($colors, lush);
      cursor: pointer;
    }
  }
}

.theme {
  &-corporate {
    &.highlight {
      color: map-get($colors, teal);
    }
    .right i:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    &.highlight {
      color: map-get($colors, dusty-blue);
    }
    .right i:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    &.highlight {
      color: map-get($colors, health);
    }
    .right i:hover {
      color: map-get($colors, health);
    }
  }
}
