@import 'styles/base.scss';

.slide {
  width: 278px;
  height: 278px;
  margin-left: 15px;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);

  .imageHolder {
    position: relative;

    .image {
      width: 100%;
      height: 190px;
    }
    .blackOverlay {
      background: #000;
      opacity: 0.16;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
    .play {
      position: absolute;
      width: 50px;
      height: 55px;
      top: 50%;
      left: 50%;
      margin-top: -25px;
      margin-left: -25px;
      cursor: pointer;
    }
  }
  .bottom {
    height: 88px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      height: 34px;
      width: auto;
      margin-left: 20px;
      margin-right: 15px;
    }
    .label {
      @include font($size: 18px, $family: secondary-bold);
    }
    .arrow {
      text-align: right;
      font-size: 22px;
      margin-right: 20px;
      margin-left: auto;
      color: map-get($colors, midnight);
      display: flex;
    }
  }
}
