@import 'styles/base.scss';

$infoBlockPadding: 28px;

.block {
  position: relative;
  width: 100%;
}

.parallax {
  background-color: yellow;
  position: relative;
  height: 100%;
}

.hiddenSpacing {
  position: relative;
  opacity: 0;
  width: 40vw;

  @include for-size(mobile) {
    width: 85vw;
  }
}

.mainPhoto {
  position: absolute;
  right: -1px;
  width: 40vw;

  @include for-size(mobile) {
    top: 0;
    width: 85vw;
  }
}

.backgroundPhoto {
  position: absolute;
  right: calc(10vw + 20px);
  width: 30vw;
  height: 75%;
  top: -20px;

  @include for-size(mobile) {
    top: -20px;
    width: 85vw;
    right: 20px;
    height: 90%;
  }
}

.content {
  @include row(center, center);
  flex-direction: row-reverse;
  width: 1220px;
  margin: 28px auto;

  @include for-size(1200px) {
    width: 100%;
    margin-bottom: 80px;
  }

  .testPhoto {
    position: absolute;
    width: 300px;
    height: 300px;
  }

  .photosContainer {
    background-size: cover;

    @include for-size(mobile) {
      position: relative;
    }
  }

  .photoContent {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;

    @include for-size(mobile) {
      width: 85vw;
      top: 0;
    }
  }

  .infoBox {
    width: 600px;
    min-width: 600px;
    // margin-top: 40px; // to offset the content lower than the image

    .topper {
      @include font($size: 16px, $color: grey-5, $lineHeight: 22px, $letterSpacing: 1px, $weight: bold);
      text-transform: uppercase;
      margin-bottom: 8px;
      @include for-size(mobile) {
      }
    }

    .title {
      @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);
      @include for-size(mobile) {
        @include font($size: 28px, $lineHeight: 32px, $family: primary);
      }
    }

    .titleLegend {
      @include font($size: 16px, $color: midnight, $lineHeight: 25px, $weight: bold);
      margin-top: 4px;
      @include for-size(mobile) {
      }
    }

    .description {
      @include font($size: 16px, $color: midnight, $lineHeight: 25px);
      padding: 24px 0;
    }

    .boxContent {
      padding: 24px 0;
      h4 {
        @include font($family: primary, $size: 28px, $lineHeight: 32px);
        margin-bottom: 16px;

        @include for-size(mobile) {
          text-align: center;
        }
      }
      p {
        @include font($size: 16px, $lineHeight: 24px, $weight: regular);
        margin-bottom: 16px;

        &:last-of-type:not(:first-of-type) {
          margin-bottom: 0px;
        }
      }

      b {
        @include font($size: 16px, $lineHeight: 24px, $weight: bold);
      }

      ol {
        @include font($size: 16px, $lineHeight: 24px, $weight: bold);
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 20px;

        li {
          margin-bottom: 20px;
          span {
            @include font($size: 16px, $lineHeight: 24px, $weight: regular);
          }

          ul {
            @include font($size: 16px, $lineHeight: 24px, $weight: regular);
            margin-top: 4px;
            list-style-type: disc;
            list-style-position: outside;
            padding-left: 20px;

            li {
              margin-bottom: 4px;
            }
          }
        }
      }

      ul {
        @include font($size: 16px, $lineHeight: 24px, $weight: regular);
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 28px;

        li {
          padding-left: 4px;
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 24px;
          }
        }
      }
    }

    .pillsContainer {
      margin-top: 8px;
    }

    .actionContainer {
      margin-top: 24px;
    }

    .actionLink {
      margin-top: 8px;
      display: block;

      &--inline {
        display: inline;
      }
    }

    .linkWithInlinePrefix {
      display: inline;
      justify-content: flex-start;
      align-items: center;
      line-height: 26px;

      p {
        margin-top: 8px;
        margin-right: 4px;
        font-size: 18px !important;
        display: inline;
      }
    }

    .actionSubContent {
      @include font($size: 16px, $color: midnight, $lineHeight: 25px, $weight: light);
      font-style: italic;
      margin: 8px 0;
      a {
        @include font($size: 16px, $color: midnight, $lineHeight: 25px, $weight: bold);
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: map-get($colors, lush);
        }
      }
    }

    @include for-size(1200px) {
      width: 400px;
      min-width: 400px;
    }

    @include for-size(mobile) {
      width: 100%;
      min-width: 100%;
      padding: 16px 16px 0px;

      .topper,
      .title,
      .titleLegend,
      .actionSubContent {
        text-align: center;
      }
      .actionContainer {
        width: 100%;
        text-align: center;
      }
    }
  }

  &-alignRight {
    @include row(center, center);
    flex-direction: row;

    .mainPhoto,
    .backgroundPhoto {
      right: auto;
      left: 0;
    }

    .backgroundPhoto {
      left: calc(10vw + 20px);

      @include for-size(mobile) {
        top: -20px;
        left: calc(10vw + 20px);
        width: 75vw !important;
        height: 100% !important;
      }
    }
  }

  @include for-size(mobile) {
    display: block;
    width: 100%;
  }
}

.photoMobileSpacer {
  display: none;
  height: 440px;

  @include for-size(mobile) {
    display: block;
  }
}

.imageTileContainer {
  @include row(flex-start, center);
  margin-top: 24px;
  margin-bottom: 24px;

  @include for-size(mobile) {
    @include row(center, center);
  }

  &--desktop {
    @include for-size(mobile) {
      display: none;
    }
  }

  &--mobile {
    @include row(center, center);
    padding: 0 8px;
    margin-top: 0;
    margin-bottom: 8px;

    .imageTile {
      &:last-of-type {
        margin-right: 0;
      }
    }

    @include for-size(desktop) {
      display: none;
    }
  }

  .imageTile {
    margin-right: 8px;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    box-shadow: 0px 0px 1px 1px map-get($colors, grey-2);
    cursor: pointer;
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: all 0.2s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--active {
      box-shadow: 0px 0px 0px 2px map-get($colors, lush);
    }

    &:hover {
      opacity: 0.9;
    }
  }
}
