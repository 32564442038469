@import 'components/modals/templates/Modal/style.module.scss';

.modal {
  padding: 32px 16px 16px;
  max-width: 375px;
  border-radius: 10px;

  @include for-size(mobile) {
    max-width: 100vw;
    padding-bottom: 120px !important;
  }
}

.slimBase {
  padding: 0px;
}

.slim {
  &-icons-bar {
    height: 0px;
    max-height: 0px;
  }

  &-header {

  }

  &-body {
    padding: 0px 12px 0px;
  }

  &-footer {
    padding: 0px 12px 12px;
    margin-top: 12px;

    button {
      border-radius: 8px;
    }
  }
}
