@import 'styles/base.scss';

.base {
  @include column(center, center);
  margin: 8px 0;
  min-height: 130px;
}

.quote {
  width: 40px;
  margin-bottom: 18px;

  @include for-size(mobile) {
    width: 32px;
  }
}

.title {
  text-align: center;
  margin: 0 28px;
  max-width: 830px;
  @include font($size: 28px, $color: midnight, $family: primary);

  @include for-size(mobile) {
    font-size: 24px;
  }
}

.source {
  margin: 24px 30px 0;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  @include font($size: 14px, $color: midnight, $weight: bold, $lineHeight: 18px);
}
