@import 'styles/base.scss';

.block {
  width: 100%;
  background-color: map-get($colors, white);

  :global(.slick-dots) {
    bottom: 20px;
    li {
      margin: 0;

      button:before {
        color: white !important;
        text-shadow: 0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight);
      }
    }
  }

  @include for-size(mobile) {
    :global(.slick-dots) {
      bottom: 10px;
    }
  }
}
