@import 'styles/base.scss';

.base {
  text-align: center;
  width: 390px;

  @include for-size(mobile) {
    width: auto;
  }

  b {
    @include font($weight: bold);
  }

  .email {
    text-decoration: underline;
  }
}
