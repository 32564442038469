@import 'styles/base.scss';

.timeline {
  display: flex;
  font-size: 0;
}

.timelineItem {
  overflow: visible;
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;

  &:last-child {
    flex: none;
  }

  &:last-child &Tail,
  &:last-child &Title:after {
    display: none;
  }

  &Container {
    display: inline-block;
  }

  &Active {
    .timelineItemIcon {
      border-color: map-get($colors, dusty-blue);
      background-color: map-get($colors, dusty-blue);
      color: map-get($colors, white);
    }
    .timelineItemContent {
      @include font($color: dusty-blue, $family: secondary-bold);
    }
  }

  &Completed {
    .timelineItemIcon {
      background: map-get($colors, white);
      border-color: map-get($colors, dusty-blue);
      color: map-get($colors, dusty-blue);
    }
    .timelineItemTail {
      &::before {
        background: map-get($colors, dusty-blue);
      }
      &::after {
        border-right-color: map-get($colors, dusty-blue);
        border-bottom-color: map-get($colors, dusty-blue);
      }
    }
    .timelineItemContent {
      color: map-get($colors, dusty-blue);
    }
  }

  &Tail {
    padding: 0px 12px;
    margin-left: 64px;
    position: absolute;
    left: 0;
    width: calc(100% - 40px);
    top: 22px;

    &::before {
      content: '';
      display: inline-block;
      height: 1px;
      border-radius: 1px;
      width: 100%;
      background-color: map-get($colors, grey-3);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-right: 1px solid map-get($colors, grey-3);
      border-bottom: 1px solid map-get($colors, grey-3);
      transform: rotate(-45deg);
      position: absolute;
      top: -2.5px;
      right: 12px;
    }
  }

  &Icon {
    @include row(center, center);
    position: relative;
    margin-left: 22px;
    background-color: transparent;
    border: 1.5px solid map-get($colors, grey-4);
    color: map-get($colors, grey-4);
    width: 44px;
    height: 44px;
    text-align: center;
    border-radius: 50%;

    .checkIcon {
      position: absolute;
      border-radius: 50%;
      top: -11px;
      right: -9px;
    }
  }

  &Content {
    text-align: center;
    margin-top: 9px;
    width: 90px;
    @include font($size: 12px, $color: grey-10, $lineHeight: 14px, $family: secondary);
  }
}
