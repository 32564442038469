@import 'styles/base.scss';

.confirmedItem {
  @include row;
  margin-bottom: 32px;

  .iconContainer {
    width: 54px;
    min-width: 54px;
    padding-top: 3px;

    .icon {
      font-size: 24px;
    }
  }

  .info {
    p {
      @include font($size: 16px, $lineHeight: 25px, $weight: regular);
    }

    .title {
      @include font($size: 16px, $lineHeight: 25px, $weight: bold);
    }
  }
}
