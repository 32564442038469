@import 'styles/base.scss';

.topper {
  margin-top: 0px;
}

.link {
  margin: 8px 0;
}

.applePayButton {
  display: block;
  width: 100%;
  margin: 16px 0;
}

.sdk-form-loader {
  font-size: 20px;
  position: absolute;
  color: map-get($colors, grey-4);
  left: calc(50% - 12px);
  top: calc(50% - 9px);
  z-index: 99;
}
