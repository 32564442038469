@import 'styles/base.scss';

.base {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999999999;
  opacity: 0;
  transition: all 0.4s ease;
  background: map-get($colors, white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition-delay: 0.2s;
  pointer-events: none;
  transform: scale(1.5);
}

.visible {
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
  overflow-y: scroll;
}

.logo {
  height: 48px;
}

.loader {
  color: map-get($colors, lush);
  font-size: 40px !important;
}

.theme {
  &-corporate {
    .loader {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    .loader {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .loader {
      color: map-get($colors, health);
    }
  }
}
