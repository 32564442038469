@import 'styles/base.scss';

.summaryItem {
  @include row(flex-start, center);
  position: relative;
  width: 100%;
  min-height: 85px;
  border: solid 1px map-get($colors, grey-2);
  border-radius: 6px;
  padding: 28px 30px;
  cursor: default;
  background: map-get($colors, white);
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.clickable {
  cursor: pointer;
  &:hover {
    border: solid 1px map-get($colors, lush);
    box-shadow: 0 1px 4px 0 rgba(map-get($colors, lush), 0.15);

    .actions {
      i {
        color: map-get($colors, lush);
      }
    }
  }
}

.image {
  border-radius: 50%;
  min-width: 42px;
  min-height: 42px;
  margin-right: 14px;
  background-size: cover;
  background-position: center center;
}

.info {
  @include column();
  padding-right: 12px;
  min-height: 44px;
  width: 100%;

  @include for-size(300px) {
    padding-right: 10px;
  }
}

.actions {
  @include row(flex-end, center);
  flex-shrink: 0;

  i {
    font-size: 20px;
    width: 20px;
    height: 20px;
    color: map-get($colors, midnight);
    cursor: pointer;

    &:hover {
      color: map-get($colors, lush);
    }
  }
}

.title {
  width: 100%;
  min-height: 22px;
  text-align: left;
  @include font($size: 16px, $color: midnight, $lineHeight: 22px, $weight: semibold);
}

.description {
  width: 100%;
  min-height: 22px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @include font($size: 16px, $color: midnight, $lineHeight: 22px);

  &:empty {
    display: none;
  }
}

.subdescription {
  margin-top: 10px;
  width: 100%;
  min-height: 15px;
  text-align: left;
  @include font($size: 13px, $color: grey-4, $lineHeight: 18px);

  &:empty {
    display: none;
  }
}

.invalid {
  .title,
  .description,
  .subdescription {
    color: map-get($colors, error);
  }
}

.theme {
  &-corporate {
    .clickable:hover {
      border: solid 1px map-get($colors, teal);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, teal), 0.15);

      .actions {
        i {
          color: map-get($colors, teal);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    .clickable:hover {
      border: solid 1px map-get($colors, dusty-blue);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, dusty-blue), 0.15);

      .actions {
        i {
          color: map-get($colors, dusty-blue);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .clickable:hover {
      border: solid 1px map-get($colors, health);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, health), 0.15);

      .actions {
        i {
          color: map-get($colors, health);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, health);
    }
  }
}
