@import 'styles/base.scss';

.podcastBlock {
  background: map-get($colors, white);

  .content {
    @include row(stretch, stretch);

    @include for-size(mobile) {
      @include column(stretch, stretch);
      flex-direction: column-reverse;
    }
  }

  .infoContent {
    width: 50%;
    text-align: left;
    padding: 0 100px 0 0;

    @include for-size(mobile) {
      max-width: unset;
      width: 100%;
      padding: 24px 0 0;
    }

    p {
      @include font($size: 16px, $color: grey-4, $lineHeight: 24px, $letterSpacing: 2px, $weight: bold);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    h3 {
      @include font($size: 24px, $lineHeight: 36px, $color: midnight);
      font-family: map-get($font-family, secondary-bold);
    }
  }

  .podcastWrapper {
    width: 50%;

    @include for-size(mobile) {
      width: 100%;
    }
  }

  .iframeWrapper {
    iframe {
      width: 100%;
    }
  }
}
