@import 'styles/base.scss';

.kustomerChat {
  @include row(center, center);
  position: fixed;
  bottom: 36px;
  right: 36px;
  background-color: map-get($colors, lush);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.39);
  transform: scale(1);
  transition: transform 0.1s ease;
  z-index: 2000;

  .icon {
    width: 34px;
    height: 34px;
    path {
      fill: map-get($colors, white);
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.theme {
  &-corporate {
    background-color: map-get($colors, teal);
  }
  &-medical {
    background-color: map-get($colors, dusty-blue);
  }
  &-health {
    background-color: map-get($colors, health);
  }
}
