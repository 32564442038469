@import 'styles/base.scss';

.block {
  background: map-get($colors, white);
  width: 100%;
  padding: 56px 0px;

  @include for-size(856px) {
    padding: 40px 0px;
  }

  &.background {
    background-color: map-get($colors, natural);
  }

  h3 {
    @include font($size: 16px, $color: grey-5, $lineHeight: 22px, $letterSpacing: 2px, $weight: bold);
    text-transform: uppercase;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;

    @include for-size(856px) {
      margin-bottom: 24px;
    }
  }

  .glContainerSmall {
    @include row(space-between, center);

    @include for-size(856px) {
      height: auto;
      line-height: 1;
      @include column(center, space-between);
    }
  }

  .list {
    width: calc(100% - 150px);
    @include row(space-around, center);

    &.full {
      width: 100%;
    }

    .imageBlock {
      display: inline-block;
      text-align: center;

      &--clickable {
        cursor: pointer;
      }
    }

    @include for-size(856px) {
      width: 100%;
      @include column(center, space-between);

      &.stackMobileTwo {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: normal;
        justify-content: space-around;

        > :nth-last-child(2) {
          margin-bottom: 0px;
        }

        .imageBlock {
          width: 40% !important;

          @include for-size(400px) {
            img {
              width: 100% !important;
            }
          }
        }
      }

      .imageBlock {
        margin-bottom: 30px;
      }

      > :last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.text {
  width: 150px;
  display: inline-block;
  @include font($size: 16px, $color: midnight, $weight: bold);

  @include for-size(856px) {
    margin-bottom: 30px;
    width: auto;
    height: 22px;
    line-height: 22px;
  }
}
