@import 'styles/base.scss';

.base {
  @include column(center, center);
  margin: 28px 0 16px;
}

.quote {
  width: 40px;
  margin-bottom: 32px;

  @include for-size(mobile) {
    width: 32px;
  }
}

.title {
  text-align: center;
  margin: 0 28px;
  max-width: 830px;
  @include font($size: 28px, $color: midnight, $lineHeight: 44px, $family: primary, $weight: light);

  @include for-size(mobile) {
    font-size: 24px;
  }
}

.source1 {
  margin: 24px 30px 0;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include font($size: 16px, $color: midnight, $weight: light, $lineHeight: 24px);
}

.source2 {
  margin: 0px 30px 0;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include font($size: 16px, $color: midnight, $weight: semibold, $lineHeight: 24px);
}
