@import 'styles/base.scss';

.base {
  padding-top: 72px;

  h1 {
    @include font($family: headings, $size: 42px, $lineHeight: 54px);
    text-align: center;
    margin-bottom: 24px;

    @include for-size(mobile) {
      @include font($family: headings, $size: 32px, $lineHeight: 36px);
    }
  }

  p {
    @include font($size: 18px, $lineHeight: 24px, $weight: light);
    text-align: center;
    margin-bottom: 48px;
  }

  .imageContainer {
    @include row(center, flex-start);
    margin-bottom: 24px;

    .image {
      width: 450px;
      @include for-size(mobile) {
        width: 120%;
      }
    }
  }

  @include for-size(mobile) {
    padding: 40px 16px 0px;
  }
}
