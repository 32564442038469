@import 'styles/base.scss';

.section {
  .blockTitle {
    margin-bottom: 60px;
  }
  .article {
    text-align: left;
    padding-bottom: 32px;
    margin-bottom: 50px;
    border-bottom: solid 1px map-get($colors, grey-2);

    .topper {
      @include font($size: 16px, $color: grey-4, $lineHeight: 24px, $letterSpacing: 2px, $weight: bold);
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    .title {
      @include font($size: 32px, $lineHeight: 38px, $family: primary);
      width: 100%;
      margin-bottom: 12px;
    }
    .summary {
      @include font($size: 16px, $color: midnight, $lineHeight: 24px, $weight: 400);
      margin-bottom: 32px;

      a {
        color: map-get($colors, midnight);
        text-decoration: underline;

        &:hover {
          color: map-get($colors, lush);
        }
      }

      :global(.more-link) {
        display: none;
      }
    }
    .link {
      color: map-get($colors, light-green);
    }
  }

  .articleCondensed {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    border-bottom: none;
    padding-bottom: 0px;

    &:nth-of-type(2n + 1) {
      margin-right: 30px;
    }

    &:nth-of-type(2n) {
      margin-left: 30px;
    }
    .title {
      @include font($size: 20px, $lineHeight: 24px, $weight: semibold);
    }
    .summary {
      display: none;
    }
    .link {
      display: none;
    }

    @include for-size(mobile) {
      width: 100%;

      &:nth-of-type(2n + 1) {
        margin-right: 0px;
      }

      &:nth-of-type(2n) {
        margin-left: 0px;
      }
    }
  }
}
