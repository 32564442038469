@import 'styles/base.scss';

.base {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  user-select: none;
}

.disabled {
  // pointer-events: none;
  opacity: 0.4;
}

.month {
  margin-bottom: 8px;
  @include font($size: 20px, $weight: medium);

  @include for-size(mobile) {
    padding-left: 16px;
    margin-bottom: 12px;
  }
}

.arrow {
  font-size: 28px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 12px);
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.2s ease;
  color: map-get($colors, midnight);

  &:hover {
    color: map-get($colors, lush);
  }

  @include for-size(mobile) {
    display: none;
  }
}

.arrowLeft {
  left: -33px;
}

.arrowRight {
  right: -33px;

  @include for-size(mobile) {
    display: block;
    right: 20px;
    top: -4px;
    color: black !important;
  }
}

.hideArrow {
  opacity: 0;
  pointer-events: none;
}

.datesWrapper {
  // scrollable div with dates in it
  @include row(flex-start, center);
  position: relative;
  width: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0px !important;
    background: transparent;
  }

  &.startWithTomorrow {
    padding-left: 9px;
  }

  @include for-size(mobile) {
    padding-left: 16px;
  }
}

.dateContainer {
  @include column(flex-start, center);
  height: 100%;
  width: 6.75%;
  min-width: 6.75%;

  @include for-size(850px) {
    width: calc(10% - 5px);
    min-width: calc(10% - 5px);
  }

  @include for-size(625px) {
    width: calc(13% - 5px);
    min-width: calc(13% - 5px);
  }

  @include for-size(500px) {
    width: calc(17% - 5px);
    min-width: calc(17% - 5px);
  }

  @include for-size(410px) {
    width: calc(20% - 5px);
    min-width: calc(20% - 5px);
  }

  .dateTooltip {
    box-shadow: none;
  }

  &--disabled {
    // pointer-events: none;

    .dateCircle {
      background-color: map-get($colors, grey-2);
      border: solid 1px map-get($colors, grey-2);
      cursor: default !important;
    }

    .dateTopLabel,
    .dateDay {
      @include font($color: grey-3);
    }

    .dateCircleContent {
      p {
        @include font($color: grey-3);
      }
    }
  }

  &--highlighted {
    .dateCircle {
      background-color: map-get($colors, lush-light);
      border: solid 1px map-get($colors, lush-light);

      &:hover {
        background-color: rgba(map-get($colors, lush), 0.3);
      }
    }

    .dateCircleContent {
      p {
        @include font($color: midnight);
      }
    }
  }
}

.dateCircle {
  background-color: map-get($colors, white);
  border-radius: 50%;
  width: 78%;
  padding-top: calc(78% - 2px);
  position: relative;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
  border: solid 1px map-get($colors, midnight);

  &:hover {
    background-color: map-get($colors, grey-2);
  }

  &-selected {
    background-color: map-get($colors, lush) !important;
    border: solid 1px map-get($colors, lush);

    &:hover {
      background-color: map-get($colors, lush);
    }
  }

  &-animate {
    transform: scale(1.5);
  }
}

.dateTopLabelContainer {
  @include row(center, center);
  width: 76%;
  height: 20px;
  text-align: center;
}

.dateTopLabel {
  @include font($size: 10px, $color: black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-selected {
    @include font($weight: bold);
  }
}

.dateDay {
  margin-top: 8px;
  text-align: left;
  width: 76%;
  text-align: center;
  @include font($size: 16px, $color: black);

  &-selected {
    @include font($weight: bold);
  }
}

.dateCircleContent {
  @include row(flex-start, center);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;

  p {
    text-align: center;
    width: calc(100% - 1px);
    @include font($size: 24px, $color: midnight, $weight: light);
  }

  &-selected {
    p {
      color: white !important;
      @include font($weight: medium);
    }
  }
}

.theme {
  &-corporate {
    .arrow {
      &:hover {
        color: map-get($colors, teal);
      }
    }

    .dateContainer--highlighted {
      .dateCircle {
        background-color: map-get($colors, teal-light);
        border: solid 1px map-get($colors, teal-light);

        &:hover {
          background-color: rgba(map-get($colors, teal), 0.3);
        }
      }
    }

    .dateCircle-selected {
      background-color: map-get($colors, teal) !important;
      border: solid 1px map-get($colors, teal);

      &:hover {
        background-color: map-get($colors, teal);
      }
    }
  }

  &-medical {
    .arrow {
      &:hover {
        color: map-get($colors, dusty-blue);
      }
    }

    .dateContainer--highlighted {
      .dateCircle {
        background-color: map-get($colors, dusty-blue-light);
        border: solid 1px map-get($colors, dusty-blue-light);

        &:hover {
          background-color: rgba(map-get($colors, dusty-blue), 0.3);
        }
      }
    }

    .dateCircle-selected {
      background-color: map-get($colors, dusty-blue) !important;
      border: solid 1px map-get($colors, dusty-blue);

      &:hover {
        background-color: map-get($colors, dusty-blue);
      }
    }
  }

  &-health {
    .arrow {
      &:hover {
        color: map-get($colors, health);
      }
    }

    .dateContainer--highlighted {
      .dateCircle {
        background-color: map-get($colors, health-light);
        border: solid 1px map-get($colors, health-light);

        &:hover {
          background-color: rgba(map-get($colors, health), 0.3);
        }
      }
    }

    .dateCircle-selected {
      background-color: map-get($colors, health) !important;
      border: solid 1px map-get($colors, health);

      &:hover {
        background-color: map-get($colors, health);
      }
    }
  }
}
