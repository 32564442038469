@import 'styles/base.scss';

.base {
  @include row(flex-start, center);
  position: relative;
  width: 100%;
  min-height: 85px;
  border: solid 1px map-get($colors, grey-2);
  border-radius: 6px;
  padding: 28px 28px 28px 56px;
  cursor: default;
  background: map-get($colors, white);
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  &.hideLeftIcon {
    padding: 28px 28px 28px 28px;
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    border: solid 1px map-get($colors, lush);
    box-shadow: 0 1px 4px 0 rgba(map-get($colors, lush), 0.15);

    .actionIcon {
      color: map-get($colors, lush);
    }
  }
}

.icon {
  position: absolute;
  left: 18px;
}

.info {
  padding-right: 12px;
  width: 100%;

  .title {
    @include font($size: 16px, $color: midnight, $lineHeight: 24px, $weight: semibold);
    width: 100%;
    text-align: left;
  }

  .subtitle {
    @include font($size: 16px, $color: midnight, $lineHeight: 24px);
    width: 100%;
    // min-height: 22px;
    text-align: left;
  }

  .description {
    margin-top: 10px;
    width: 100%;
    min-height: 15px;
    text-align: left;
    @include font($size: 13px, $color: grey-4, $lineHeight: 18px);
  }

  b {
    @include font($weight: bold);
  }

  @include for-size(300px) {
    padding-right: 10px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected {
  border-color: map-get($colors, lush);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15), 0px 0px 0px 1px map-get($colors, lush) inset !important;
}

.actions {
  @include row(flex-end, center);
  flex-shrink: 0;

  .actionIcon {
    color: map-get($colors, midnight);
    cursor: pointer;
    margin-left: 8px;

    &:hover {
      color: map-get($colors, lush);
    }
  }
}

.hideLeftIcon {
  .icon {
    display: none;
  }
}

.state {
  &-valid {
    .icon {
      color: map-get($colors, lush);
    }
  }
  &-warning {
    .icon {
      color: map-get($colors, yellow);
    }
    &.clickable:hover {
      .icon {
        color: map-get($colors, yellow);
      }
    }
  }
  &-invalid {
    .icon,
    .title,
    .subtitle,
    .description {
      color: map-get($colors, error);
    }
    &.clickable:hover {
      border: solid 1px map-get($colors, error);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, error), 0.15);
      .actionIcon {
        color: map-get($colors, error);
      }
    }
  }
}

.theme {
  &-corporate {
    &.clickable:hover {
      border: solid 1px map-get($colors, teal);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, teal), 0.15);
      .actionIcon {
        color: map-get($colors, teal);
      }
    }
    .actions .actionIcon:hover {
      color: map-get($colors, teal);
    }
    &.state {
      &-valid .icon {
        color: map-get($colors, teal);
      }
      &-warning .clickable:hover .icon {
        color: map-get($colors, teal);
      }
    }
    &.selected {
      border-color: map-get($colors, teal);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15), 0px 0px 0px 1px map-get($colors, teal) inset !important;
    }
  }
  &-medical {
    &.clickable:hover {
      border: solid 1px map-get($colors, dusty-blue);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, dusty-blue), 0.15);
      .actionIcon {
        color: map-get($colors, dusty-blue);
      }
    }
    .actions .actionIcon:hover {
      color: map-get($colors, dusty-blue);
    }
    &.state {
      &-valid .icon {
        color: map-get($colors, dusty-blue);
      }
      &-warning .clickable:hover .icon {
        color: map-get($colors, dusty-blue);
      }
    }
    &.selected {
      border-color: map-get($colors, dusty-blue);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15), 0px 0px 0px 1px map-get($colors, dusty-blue) inset !important;
    }
  }
  &-health {
    &.clickable:hover {
      border: solid 1px map-get($colors, health);
      box-shadow: 0 1px 4px 0 rgba(map-get($colors, health), 0.15);
      .actionIcon {
        color: map-get($colors, health);
      }
    }
    .actions .actionIcon:hover {
      color: map-get($colors, health);
    }
    &.state {
      &-valid .icon {
        color: map-get($colors, health);
      }
      &-warning .clickable:hover .icon {
        color: map-get($colors, health);
      }
    }
    &.selected {
      border-color: map-get($colors, health);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15), 0px 0px 0px 1px map-get($colors, health) inset !important;
    }
  }
}
