@import 'styles/base.scss';

.list {
  width: 100%;

  .label {
    display: block;
    width: 100%;
    text-align: left;
    height: 26px;
    user-select: none;
    @include font($size: 14px, $color: grey-5);
  }

  .labelMore {
    float: right;

    a {
      text-decoration: underline;
      cursor: pointer;
      color: map-get($colors, midnight);
    }
  }
}

.card {
  align-self: stretch;
}

.contentContainer {
  @include row(center, center);
}

.vertical {
  .contentContainer {
    @include column(center, flex-start);
  }
}

.carousel {
  display: block;

  .card {
    min-height: 300px;
  }

  button[class*='slick-arrow '] {
    &:before {
      display: inline-block;
      font: normal normal normal 14px/1 CustomIconsVF !important;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-size: 50px !important;
      color: map-get($colors, grey-5) !important;
      line-height: 30px !important;

      &:hover {
        color: map-get($colors, midnight) !important;
      }
    }

    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  button[class*='slick-disabled'] {
    display: none !important;
  }

  button[class*='slick-next'] {
    &:before {
      content: '\e830' !important;
    }

    right: -35px;

    @include for-size(1040px) {
      right: -15px;
    }
  }

  button[class*='slick-prev'] {
    &:before {
      content: '\e82f' !important;
    }

    left: -50px;

    @include for-size(1040px) {
      left: -34px;
    }
  }
}
