@import 'styles/base.scss';

.title {
  @include row(space-between, center);
  @include font($size: 14px, $lineHeight: 24px, $color: midnight, $weight: bold);
  cursor: pointer;

  svg {
    transition: transform 0.5s ease;
  }

  &.collapsed svg {
    transform: rotate(180deg);
  }
}

.description {
  margin-top: 16px;
  @include font($size: 14px, $lineHeight: 24px, $color: black);

  :not(:last-child) {
    margin-bottom: 15px;
  }
}
