@import 'styles/base.scss';

.item {
  @include font($size: 16px, $color: midnight, $lineHeight: 44px, $weight: light);
  height: 47px;
  display: inline-block;
  width: auto;
  border: 1px solid map-get($colors, grey-3);
  border-radius: 25px;
  background: map-get($colors, white);
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  transition: all 0.1s ease;

  &:hover {
    border-color: map-get($colors, lush);
  }
}

.disabled {
  pointer-events: none;
  background: map-get($colors, grey-2);
  border-color: map-get($colors, grey-2);
  @include font($color: grey-5, $weight: light);
}

.theme {
  &-corporate {
    &:hover {
      border-color: map-get($colors, teal);
    }
  }
  &-medical {
    &:hover {
      border-color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    &:hover {
      border-color: map-get($colors, health);
    }
  }
}
