@import 'styles/base.scss';

.base {
  width: 100%;

  .label {
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 26px;
    user-select: none;
    @include font($size: 14px, $color: grey-5);
  }

  .container {
    outline: none;
  }

  .extraText {
    font-family: map-get($font-family, secondary);
    font-size: 16px;
    margin-top: 10px;
  }
}

.checkbox {
  margin: 0px;
  padding-top: 20px;
  border-top: solid 1px map-get($colors, grey-2);

  &:first-of-type {
    margin-top: 10px;
  }
}

.wrapLabel {
  .label {
    overflow: visible;
    height: auto;
    white-space: normal;
    text-overflow: inherit;
    margin-bottom: 4px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.readOnly {
  opacity: 0.5;
  pointer-events: none;
}

.item {
  height: 50px;
  width: 100%;
}

.invalid {
  .active {
    color: map-get($colors, error);
    border-color: map-get($colors, error);
    box-shadow: inset 0px 0px 0px 1px map-get($colors, error);
  }
}

.validationText {
  min-height: 17px;
  margin-top: 5px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  color: map-get($colors, error);
  @include font($size: 14px, $lineHeight: 17px);
}

.theme {
  &-corporate {
    .active {
      @include font($color: teal, $weight: bold);
      border: 1px solid map-get($colors, teal);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, teal);
    }
  }
  &-medical {
    .active {
      @include font($color: dusty-blue, $weight: bold);
      border: 1px solid map-get($colors, dusty-blue);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, dusty-blue);
    }
  }
  &-health {
    .active {
      @include font($color: health, $weight: bold);
      border: 1px solid map-get($colors, health);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, health);
    }
  }
}
