@import 'styles/base.scss';

.base {
  position: relative;
}

.hasPagination {
  margin-bottom: 24px;
}

.slideInnerAbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navigationPrev,
.navigationNext {
  position: absolute;
  top: calc(50% - 10px);
  cursor: pointer;
  color: map-get($colors, grey-5);

  &:focus {
    color: black;
  }
}

.navigationPrev {
  left: -30px;
}

.navigationNext {
  right: -30px;
}

.navigationDisabled {
  opacity: 0.3;
  pointer-events: none;
}

.pagination {
  @include row(center, center);
  position: absolute;
  bottom: -15px;
  width: 100%;

  span {
    margin: 0 3px;
    width: 6px;
    height: 6px;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px map-get($colors, midnight);
      border-radius: 50%;
    }
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: map-get($colors, midnight);
  }
}

.bulletActive {
  opacity: 1 !important;
  background-color: red !important;
}
