@import 'styles/base.scss';

$finishTransitionTime: 0.25s;

.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 3px;
  z-index: 1002;
  transition: all $finishTransitionTime;
  pointer-events: none;

  &.loaded {
    transition-delay: $finishTransitionTime;
    opacity: 0;
  }
}

.bottom {
  top: unset;
  bottom: 0px;
}

.loaderProgress {
  height: 100%;
  float: left;
  width: 0%;
  min-width: 0%;
  background: rgba(map-get($colors, lush), 0.7);
  box-shadow: 0px 0px 7px rgba(map-get($colors, lush), 0.7);
  transition: width 10s, min-width $finishTransitionTime;
}

.prepareLoading {
}

.loading {
  .loaderProgress {
    width: 85%;
  }
}

.loaded {
  .loaderProgress {
    min-width: 100%;
  }
}

.theme {
  &-corporate {
    .loaderProgress {
      background: rgba(map-get($colors, teal), 0.7);
      box-shadow: 0px 0px 7px rgba(map-get($colors, teal), 0.7);
    }
  }

  &-medical {
    .loaderProgress {
      background: rgba(map-get($colors, dusty-blue), 0.7);
      box-shadow: 0px 0px 7px rgba(map-get($colors, dusty-blue), 0.7);
    }
  }

  &-health {
    .loaderProgress {
      background: rgba(map-get($colors, health), 0.7);
      box-shadow: 0px 0px 7px rgba(map-get($colors, health), 0.7);
    }
  }
}
