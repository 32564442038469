@import 'styles/base.scss';

.base {
  width: 100%;
  background-color: map-get($colors, white);
  padding: 28px 12px;

  h3 {
    width: 100%;
    padding: 34px 24px 56px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      padding: 20px 32px 32px;
    }
  }

  .faqContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 120px;
    row-gap: 38px;

    @include for-size(mobile) {
      grid-template-columns: 1fr;
    }

    .faq {
      h5 {
        margin-bottom: 16px;
        @include font($size: 16px, $color: midnight, $lineHeight: 22px, $weight: semibold);
      }

      p {
        letter-spacing: normal;
        @include font($size: 16px, $color: midnight, $lineHeight: 23px);
      }
    }
  }

  .actionButtonContainer {
    @include row(center, center);
    margin-top: 12px;
  }

  .actionButton {
    @include for-size(mobile) {
      width: 100%;
    }
  }
}
