@import 'styles/base.scss';

.block {
  width: 100%;
  height: 28px;
  background: map-get($colors, white);

  @include for-size(mobile) {
    height: 20px;
  }

  &.background {
    background-color: map-get($colors, natural);
  }
}

.hideOnMobile {
  @include for-size(mobile) {
    display: none;
  }
}

.showOnMobile {
  display: none;
  @include for-size(mobile) {
    display: block;
  }
}
