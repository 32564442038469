@import 'styles/base.scss';

.base {
  @include column(stretch, stretch);
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.3);
  width: 500px;
  margin: auto auto;
  transition: transform 0.2s ease;
  transform: translateY(100px);
}

.show {
  transform: translateY(0px);
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.loaderContainer {
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  pointer-events: none;
  user-select: none;

  .loader {
    position: absolute;
  }
}

.iconsBar {
  @include row(space-between, center);
  pointer-events: none;
  min-height: 48px;
  padding: 0 12px;
  position: sticky;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1001;
  top: -80px; // right now, this is based on backdrop padding (so they need to match)

  @include for-size(mobile) {
    top: -10px;
  }

  &-inner {
    pointer-events: all;
    background-color: red;
  }
}

.iconsWrapper {
  pointer-events: all;
}

.header {
  padding: 0 32px 0;
  margin-bottom: 24px;
  position: relative;
  text-align: center;

  @include for-size(mobile) {
    padding: 0 16px 0;
  }

  .mainIcon {
    margin-bottom: 16px;
  }

  .mainImage {
    margin-bottom: 16px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .title {
    @include font($size: 24px, $lineHeight: 36px, $weight: bold);
    margin-bottom: 8px;
    text-align: center;
  }

  .description {
    @include font($size: 16px, $lineHeight: 24px);
    text-align: center;
  }

  .alert {
    margin-bottom: 24px;
  }
}

.body {
  @include font($size: 16px, $lineHeight: 24px);
  padding: 0 32px;
  flex: 1;

  &--centerContent {
    text-align: center;
  }

  &--mobileCenterContent {
    @include for-size(mobile) {
      text-align: center;
    }
  }

  ul {
    list-style: disc;
    padding-left: 30px;
    margin: 16px 0;
    text-align: left;

    li {
      margin-bottom: 16px;
    }
  }

  @include for-size(mobile) {
    padding: 0 16px 0;
  }
}

.footer {
  padding: 0 32px 32px;
  margin-top: 32px;

  @include for-size(mobile) {
    padding: 0px 16px 16px;
  }

  .actions {
    @include row(stretch, stretch);

    & > button {
      margin-right: 16px;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }

    &--stacked {
      @include column(stretch, stretch);

      & > button {
        margin-right: 0;
        margin-bottom: 8px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--mobileStacked {
      @include for-size(mobile) {
        @include column(stretch, stretch);
        & > button {
          margin-right: 0;
          margin-bottom: 8px;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .submitLoading {
    color: map-get($colors, white);
  }
}

.mobile-fullscreen {
  @include for-size(mobile) {
    @include column(stretch, stretch);
    max-height: 100vh;
    border-radius: 0;

    &.hasButtons {
      padding-bottom: 80px; // to fix issue on ios safari where bottom navbar hides button.
    }

    .loaderContainer {
      border-radius: 0;
    }

    .bodyWrapper {
      flex: 1;
      overflow: auto;
    }

    .footer {
      margin-top: 16px;
    }
  }
}

.mobile-drawer {
  @include for-size(mobile) {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;

    .bodyWrapper {
      flex: 1;
      overflow: auto;
    }

    .footer {
      margin-top: 16px;
    }
  }
}

.mobile-drawer-form {
  position: relative;
  width: unset;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 90vh;
}

.slim {
  .iconsBar {
    min-height: 32px;
    padding: 0 12px;
  }

  .iconsWrapper {
    position: relative;
    top: 10px;
  }

  .header {
    padding: 0 16px 0;
    margin-bottom: 16px;
    @include for-size(mobile) {
      padding: 0 16px 0;
    }
  }

  .body {
    padding: 0 16px;
    @include for-size(mobile) {
      padding: 0 16px 0;
    }
  }

  .footer {
    padding: 0 16px 16px;
    margin-top: 16px;
    @include for-size(mobile) {
      padding: 0px 16px 16px;
    }
  }
}
