@import 'styles/base.scss';

.providerLeadFormBlock {
  background-color: map-get($colors, dusty-green);

  &-content {
    @include row(stretch, flex-start);
    @include for-size(mobile) {
      display: block;
    }
  }

  h2 {
    @include font($size: 32px, $lineHeight: 46px, $color: white, $weight: semibold);
    max-width: 534px;
    text-align: left;

    @include for-size(mobile) {
      @include font($size: 28px, $lineHeight: 38px, $weight: semibold);
      max-width: unset;
      margin-bottom: 48px;
    }
  }

  .errorBox {
    @include font($size: 14);
    background-color: map-get($colors, error);
    color: map-get($colors, white);
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .successContainer {
    padding: 67px 0;
    margin: auto;
    width: auto;

    @include column(center, center);
    svg {
      color: map-get($colors, white);
      margin-bottom: 24px;
    }

    h2 {
      max-width: 850px;
      text-align: center;
    }

    p {
      text-align: center;
      margin: 8px 0 0;
      @include font($size: 18px, $lineHeight: 24px, $color: white);
    }

    a {
      color: map-get($colors, white);
    }
  }

  .formContainer {
    flex: 1;
    margin-left: 80px;
    text-align: right;
    min-width: 400px;

    .fieldRow {
      display: flex;
      gap: 46px;
      width: 100%;
      margin-bottom: 16px;

      @include for-size(mobile) {
        flex-direction: column;
        gap: 12px;

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }
    }

    .subfieldRow {
      display: flex;
      gap: 24px;

      width: 100%;
    }

    .segmentsRow {
      width: 100%;
      margin-bottom: 16px;

      .segments {
        margin-top: 8px;
        width: 120px;
      }
    }

    .fieldLabel {
      @include font($color: white, $size: 14px, $lineHeight: 18px);
      text-align: left;

      b {
        @include font($weight: bold);
      }
    }

    .input {
      label,
      input {
        color: white;
      }
    }
    .inputWrapper {
      border-bottom-color: white;
    }
    .select {
      :global(.select__control) {
        background-color: transparent !important;
        border-color: white !important;
      }
      :global(.select__value-container *) {
        color: white !important;
      }
    }

    .button {
      width: 50%;

      button {
        width: inherit;
        margin-bottom: 17px;
      }

      @include for-size(mobile) {
        width: 100%;

        button {
          margin-bottom: 0;
        }
      }
    }

    @include for-size(mobile) {
      margin-left: 0px;
      min-width: unset;
    }
  }

  .terms {
    width: 50%;
    text-align: left;
    @include font($size: 14px, $lineHeight: 24px, $color: white, $family: secondary);

    .link {
      font-family: map-get($font-family, secondary-medium);
      letter-spacing: 0px;
      text-decoration: underline;
      color: map-get($colors, white);

      &:hover {
        color: map-get($colors, lush);
        cursor: pointer;
      }
    }

    @include for-size(mobile) {
      width: 100%;
    }
  }
}
