@import 'styles/base.scss';

.base {

}

.card {
  b {
    @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
  }

  p {
    @include font($size: 14px, $lineHeight: 24px, $color: grey-12);
  }

  .editIcon {
    &:hover {
      color: map-get($colors, blue-zeel-work);
    }
  }

  .deleteIcon {
    &:hover {
      color: map-get($colors, error);
    }
  }
}

.addNewLink {
  display: flex;
  color: map-get($colors, blue-zeel-work);
  @include row(flex-start, center);
  margin-top: 8px;

  &:hover {
    color: map-get($colors, blue-zeel-work-hover);
  }
}