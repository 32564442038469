@import 'styles/base.scss';

.progressBar {
  width: 100%;
  height: 50px;
  background: map-get($colors, white);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  border-top: solid 1px map-get($colors, grey-2);
  text-align: center;
  z-index: 1000;

  @include for-size(mobile) {
    height: 38px;
    top: 63px;
  }

  .innerContainer {
    height: 100%;
    margin: 0 auto;
    display: inline-flex;

    @include for-size(mobile) {
      @include row(space-between, center);
      max-width: 500px;
      padding: 0 12px;
    }

    li {
      height: 100%;
    }
  }
}

.progressItem {
  height: 100%;
  border-bottom: 3px solid transparent;
  display: inline-block;
  margin: 0 30px;
  @include font($size: 16px, $color: grey-4, $weight: regular, $lineHeight: 49px);

  @include for-size(mobile) {
    @include font($size: 12px, $color: grey-4, $weight: regular, $lineHeight: 38px);
    margin: 0 10px;
  }

  @include for-size(320px) {
    @include font($size: 11px);
    margin: 0 8px;
  }

  &:last-of-type {
    @include for-size(mobile) {
      position: relative;
      border-bottom: none;
      margin-right: 0;
      border-right: solid 15px transparent;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
      }
    }
  }

  &--current {
    @include font($weight: bold, $color: midnight);
    border-bottom: 3px solid map-get($colors, lush);

    &:last-of-type {
      @include for-size(mobile) {
        border-bottom: none;
        &:after {
          background-color: map-get($colors, lush);
        }
      }
    }
  }

  &--disabled {
    user-select: none;
    pointer-events: none;
    cursor: default;
  }
}

// temporary fix for right padding of last item
.mobileSpacer {
  display: none;

  @include for-size(mobile) {
    display: inline-block;
    padding-left: 10px;
    visibility: hidden;
  }
}

.theme {
  &-corporate {
    .progressItem--current {
      border-bottom: 3px solid map-get($colors, teal);
      &:last-of-type {
        @include for-size(mobile) {
          border-bottom: none;
          &:after {
            background-color: map-get($colors, teal);
          }
        }
      }
    }
  }
  &-medical {
    .progressItem--current {
      border-bottom: 3px solid map-get($colors, dusty-blue);
      &:last-of-type {
        @include for-size(mobile) {
          border-bottom: none;
          &:after {
            background-color: map-get($colors, dusty-blue);
          }
        }
      }
    }
  }
  &-health {
    .progressItem--current {
      border-bottom: 3px solid map-get($colors, health);
      &:last-of-type {
        @include for-size(mobile) {
          border-bottom: none;
          &:after {
            background-color: map-get($colors, health);
          }
        }
      }
    }
  }
}
