@import 'styles/base.scss';

.base {
  height: 315px;
  width: 100%;
  padding: 0 0px;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  @include for-size(1100px) {
    padding: 0;
  }
  @include for-size(mobile) {
    height: auto;
    padding: 0 0;
  }
}

.mobileImage {
  height: 300px;
  width: 100%;
  object-fit: cover;

  @include for-size(desktop) {
    display: none !important;
  }
}

.content {
  height: 100%;
  width: 1100px;
  margin: auto;

  @include for-size(1200px) {
    width: calc(100vw - 60px);
  }

  .mobileTitle {
    display: none;
  }

  .title {
    max-width: 600px;
    margin-bottom: 4px;
    @include font($size: 32px, $lineHeight: 40px, $color: white, $family: primary);
  }

  .description {
    max-width: 500px;
    margin-bottom: 8px;
    @include font($size: 16px, $color: white, $lineHeight: 23px);
  }

  .source {
    text-transform: uppercase;
    letter-spacing: 1px;
    max-width: 500px;
    margin-bottom: 8px;
    opacity: 0.6;
    @include font($size: 16px, $color: white, $lineHeight: 23px, $weight: bold);
  }

  .link {
    margin-top: 16px;
    color: map-get($colors, white);
  }

  .button {
    margin-top: 16px;
  }

  @include for-size(mobile) {
    width: 100vw;
    background-color: map-get($colors, midnight);
    padding: 16px 16px 40px;
    bottom: 0;

    .title {
      max-width: none;
      width: 100%;
      font-size: 22px;
      line-height: 33px;
      margin-bottom: 8px;
      max-height: none;
      text-align: center;
    }

    .description {
      max-width: none;
      text-align: center;
      @include font($size: 14px, $color: white, $lineHeight: 23px);
    }

    .source {
      display: none;
    }

    .link {
      text-align: center;
      margin-top: 16px;
      width: 100%;
      font-size: 15px;
    }

    .button {
      align-self: center;
    }
  }
}

.contentTheme {
  &-light {
    .mobileTitle {
      color: white;
    }
    .title {
      color: white;
    }
    .description {
      color: white;
    }
    .source {
      color: white;
    }
    .link {
      color: white;
    }
  }

  &-dark {
    .mobileTitle {
      color: map-get($colors, midnight);
    }
    .title {
      color: map-get($colors, midnight);
    }
    .description {
      color: map-get($colors, midnight);
    }
    .source {
      color: map-get($colors, midnight);
    }
    .link {
      color: map-get($colors, midnight);
    }

    @include for-size(mobile) {
      background-color: map-get($colors, natural);
    }
  }
}

.align-left {
  @include column(flex-start, center);
  text-align: left;
}
.align-center {
  @include column(center, center);
  text-align: center;
}
.align-right {
  @include column(flex-end, center);
  text-align: right;
}
