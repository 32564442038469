@import 'styles/base.scss';

.base {
  position: relative;
  width: 100%;
}

.hasError {
  .icon {
    color: map-get($colors, error);
  }
}

.fieldContainer {
  @include row(center, center);
  outline: none;
  width: auto;
}

.disabled {
  .number,
  .icon {
    opacity: 0.2;
    pointer-events: none;
  }
}

.label {
  width: 100%;
  height: 26px;
  text-align: center;
  user-select: none;
  @include font($size: 14px, $color: grey-5);
}

.number {
  font-style: normal;
  font-stretch: normal;
  min-width: 24px;
  padding: 0 14px;
  letter-spacing: normal;
  text-align: center;
  user-select: none;
  @include font($size: 32px, $color: midnight, $lineHeight: 0.96, $weight: medium);
}

.icon {
  font-size: 21px;
  cursor: pointer;

  &-disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.errorText {
  margin-top: 6px;
  min-height: 17px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  width: 100%;
  @include font($size: 14px, $lineHeight: 17px, $color: error);
}

.theme {
  &-corporate {
  }
  &-medical {
  }
  &-health {
  }
}
