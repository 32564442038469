@import 'styles/base.scss';

.statsContainer {
  @include row(center, center);
  @include for-size(800px) {
    @include column(center, flex-start);
  }
}

.stat {
  @include row();
  flex: 1 1 0;

  @include for-size(800px) {
    width: 440px;
    padding-bottom: 40px;
    @include row(flex-start, center);

    > :last-child {
      padding-bottom: 0px;
    }

    @include for-size(mobile) {
      width: 333px;
    }
  }

  @include for-size(484px) {
    width: 100%;
  }

  svg {
    width: 120px;

    @include for-size(mobile) {
      width: 100px;
    }

    @include for-size(400px) {
      width: 80px;
    }
  }

  .info {
    text-align: left;
    width: calc(100% - 120px);
    padding-left: 30px;

    @include for-size(mobile) {
      width: calc(100% - 80px);
      padding-left: 20px;
    }

    @include for-size(400px) {
      width: calc(100% - 80px);
    }

    .title {
      margin-bottom: 10px;
      width: 100%;
      @include font($size: 70px, $lineHeight: 70px, $family: primary);

      @include for-size(mobile) {
        font-size: 50px;
        line-height: 50px;
      }
    }

    .description {
      margin-bottom: 10px;
      width: 295px;
      @include font($size: 24px, $lineHeight: 27px, $weight: medium);

      @include for-size(mobile) {
        width: 100%;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subdescription {
      width: 100%;
      @include font($size: 13px, $lineHeight: 17px);
    }
  }
}

.icon1 {
  path {
    stroke: map-get($colors, lush);
  }
}
.icon2 {
  path {
    fill: map-get($colors, lush);
  }
}

.small {
}

.theme {
  &-corporate {
    background-color: map-get($colors, teal-light);

    .icon1 path,
    .icon2 path {
      stroke: map-get($colors, teal);
    }
  }
}
