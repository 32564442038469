@import 'styles/base.scss';

.base {
  width: 100%;

  & div > p {
    text-align: center;
  }

  .loading {
  }

  .done {
  }
}
