@import 'styles/base.scss';

.footer,
.vaFooter {
  background-color: map-get($colors, natural);
  padding: 35px 0;
  width: 100%;
  float: left;
  position: relative;

  @include for-size(mobile) {
    padding: 25px 0px;

    .glContainerSmall {
      display: flex;
      flex-direction: column;
    }
  }

  box-shadow: 0 50vh 0 50vh map-get($colors, natural);
}

.vaFooter {
  padding-bottom: 0px;
}

.hsafsa {
  position: absolute;
  right: 0;
  top: 0;

  @include for-size(mobile) {
    margin-top: 53px;
  }
}

.vaContainer {
  @include row(space-between, center);
  @include for-size(mobile) {
    display: block;
  }
  margin-bottom: 45px;

  .linksSection {
    li {
      @include font($size: 16px, $weight: bold);
      padding: 5px 0;
    }
  }

  .imageCopySection {
    // @include column(flex-end, center);
    @include row(space-between, center);
    width: 100%;

    .logo {
      height: 53px;
      // margin-bottom: 40px;
    }

    .logoVet {
      height: 53px;
      margin-left: 56px;
    }

    p {
      @include font($size: 11px, $color: grey-4);
      max-width: 550px;
      text-align: right;
    }

    @include for-size(mobile) {
      @include column(flex-start, center);
      .logosContainer {
        @include row(space-between, flex-end);
        width: 100%;
      }
      .logo,
      .logoVet {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      .logo {
        height: 42px;
      }
      .logoVet {
        height: 42px;
        margin-left: 0px;
      }
      p {
        text-align: left;
      }
    }
  }
}

.infoBanner {
  @include font($size: 16px, $lineHeight: 24px, $color: white);
  background-color: map-get($colors, dusty-blue-dark);
  padding: 13px;
  text-align: center;

  b {
    @include font($weight: bold);
  }

  a {
    color: map-get($colors, white);
  }

  &-inner {
    @include row(space-between, center);
    text-align: left;
    @include for-size(mobile) {
      display: block;
      .learnMore {
        margin-bottom: 16px;
      }
    }
  }
}

.links {
  @include row(space-between, flex-start);
  width: 100%;
  padding-bottom: 24px;
  border-bottom: solid 1px map-get($colors, grey-2);
  margin-bottom: 32px;

  @include for-size(mobile) {
    display: block;
    padding-left: 22px;
  }

  ul {
    &:not(:last-child) {
      padding-right: 24px;
    }

    .link,
    .deepLink {
      cursor: pointer;
      user-select: none;
      padding: 5px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include font($size: 14px, $lineHeight: 1.7em, $weight: regular);

      svg {
        width: 18px;
        height: 18px;
        margin-right: 16px;
      }

      @include for-size(mobile) {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .deepLink {
      padding: 5px 20px;
      @include for-size(mobile) {
        padding: 0px 45px;
      }
    }

    .headerLink {
      user-select: none;
      padding: 5px 0px;
      display: block;
      @include font($size: 14px, $lineHeight: 1.8em, $weight: regular);
    }

    @include for-size(mobile) {
      width: 100%;
      text-align: left;
      margin-bottom: 30px;

      &:last-of-type {
        width: 100%;
      }
    }
  }

  .menuTitle {
    @include font($size: 14px, $lineHeight: 1.6em, $weight: bold);
    margin: 25px 0 10px;
  }

  .localList {
    @include for-size(desktop) {
      columns: 2;
    }
  }

  .businessList {
    @include for-size(desktop) {
      max-width: 160px;
    }
  }
}

.footerBottom {
  display: flex;
  flex-direction: row;

  .rightSide {
    position: relative;
    margin-left: 36px;
  }

  @include for-size(mobile) {
    flex-direction: column;

    .rightSide {
      margin-left: 0;
    }
  }
}

.appsBox {
  width: 135px;
  height: 88px;

  @include for-size(mobile) {
    text-align: center;
    height: 40px;
    width: 100%;
    order: 1;
  }

  .appBox {
    height: 40px;
    width: 135px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include for-size(mobile) {
      display: inline-block;
    }

    @include for-size(340px) {
      width: calc(50% - 10px);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &.storeApple {
      background-image: map-get($assets, store-apple);
      margin-bottom: 8px;

      @include for-size(mobile) {
        margin-bottom: 0px;
        margin-right: 20px;
      }

      @include for-size(340px) {
        margin-bottom: 0px;
      }
    }

    &.storeGoogle {
      background-image: map-get($assets, store-google);
    }
  }
}

.copyrightBox {
  @include font($size: 12px, $color: grey-4, $lineHeight: 18px);

  @include for-size(mobile) {
    width: 100%;
    padding-left: 0px;
    padding-top: 25px;
    text-align: justify;
    order: 3;
  }

  a {
    color: map-get($colors, grey-4);
    text-decoration: underline;
  }

  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }
}

.socialBox {
  display: inline-flex;

  width: 100%;
  min-height: 20px;
  text-align: left;
  margin-bottom: 30px;

  @include for-size(mobile) {
    text-align: center;
    margin-top: 55px;
  }

  & > :last-child a {
    margin-right: 0px;
  }
}
