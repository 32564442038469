@import 'styles/base.scss';

.detail {
  width: 50%;
  padding: 28px 24px;
  @include row(flex-start, flex-start);

  @include for-size(mobile) {
    width: 285px;
    padding: 20px 24px;
    display: block;
    text-align: center;
  }

  @include for-size(350px) {
    width: 100%;
  }

  img,
  svg {
    display: inline-block;
    width: 60px;
    margin-right: 20px;

    @include for-size(mobile) {
      margin-right: 0px;
      max-height: 70px;
      width: auto;
      margin-bottom: 20px;
    }
  }

  .info {
    width: 100%;
    text-align: left;

    @include for-size(mobile) {
      text-align: center;
    }

    h3 {
      margin-bottom: 16px;
      @include font($size: 18px, $color: midnight, $lineHeight: 22px, $weight: semibold);
    }

    p {
      letter-spacing: normal;
      @include font($size: 16px, $color: midnight, $lineHeight: 23px);
    }
  }
}
