@import 'styles/base.scss';

.base {
  @include row(stretch, flex-end);
  width: 100%;

  @include for-size(mobile) {
    width: 100% !important;
  }
}

.skeletonWrapper {
  display: flex;
}

.marginX {
  > div,
  > a,
  > button,
  > input,
  > ul,
  > span,
  > p {
    margin-left: 8px;
    margin-right: 8px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &.reverseDesktop {
    @include for-size(desktop) {
      > div,
      > a,
      > button,
      > input,
      > ul,
      > span,
      > p {
        margin-left: 8px;
        margin-right: 8px;

        &:first-child {
          margin-right: 0px;
        }

        &:last-child {
          margin-left: 0px;
        }
      }
    }
  }
}

.marginY {
  margin-bottom: 12px;

  &.wrap {
    margin-bottom: 0px;

    > div,
    > a,
    > button,
    > input,
    > ul,
    > span,
    > p {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &.wrap2 {
    margin-bottom: 0px;

    > div,
    > a,
    > button,
    > input,
    > ul,
    > span,
    > p {
      margin-bottom: 12px;
    }
  }

  &.wrapMobile {
    @include for-size(mobile) {
      margin-bottom: 0px;

      > div,
      > a,
      > button,
      > input,
      > ul,
      > span,
      > p {
        margin-bottom: 12px;
      }
    }
  }
}

.alignX {
  &-left {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: flex-end;
  }
  &-stretch {
    justify-content: stretch;
  }
}

.alignY {
  &-top {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-bottom {
    align-items: flex-end;
  }
  &-stretch {
    align-items: stretch;
  }
}

.filler {
  width: 100%;
}

.wrap {
  display: block;

  > div,
  > a,
  > button,
  > input,
  > ul,
  > span,
  > p {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.wrap2 {
  @include for-size(desktop) {
    display: flex;
    flex-wrap: wrap;

    > div,
    > a,
    > button,
    > input,
    > ul,
    > span,
    > p {
      width: calc(50% - 8px);
      margin-left: 0px;
      margin-right: 0px;

      &:nth-child(2n-1) {
        margin-right: 8px;
      }
      &:nth-child(2n) {
        margin-left: 8px;
      }
    }
  }
}

.wrapMobile {
  @include for-size(mobile) {
    display: block;

    > div,
    > a,
    > button,
    > input,
    > ul,
    > span,
    > p {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.reverseDesktop {
  @include for-size(desktop) {
    flex-direction: row-reverse;
  }
}
