@import 'styles/base.scss';

.block {
  position: relative;
  width: 100%;
  padding: 28px 0px 54px 0px;
  background-color: map-get($colors, white);

  &.single {
    padding: 28px 0px 20px 0px;
  }

  @include for-size(mobile) {
    padding: 20px 0px 45px 0px;

    &.single {
      padding: 20px 0px 12px 0px;
    }
  }
}

.withDots {
  padding-top: 45px;
  background-color: map-get($colors, natural);

  @include for-size(mobile) {
    padding-top: 40px;
  }
}

.dots {
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  height: 54px;
  object-fit: cover;

  @include for-size(mobile) {
    height: 52px;
  }
}

:global(.slick-dots) {
  bottom: -20px;
  li {
    margin: 0;
  }
}

.quoteContainer {
  text-align: center;
}

.quote {
  width: 40px;

  @include for-size(mobile) {
    width: 32px;
  }
}
