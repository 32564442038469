@import 'styles/base.scss';

.base {
  width: 100%;
  padding: 32px 0px 28px;
  background: map-get($colors, white);
  text-align: center;

  @include for-size(mobile) {
    padding: 28px 0px;
  }
}

.container {
  .successIcon {
    margin-bottom: 18px;
  }

  h2 {
    @include font($family: primary, $size: 32px, $lineHeight: 38px);
    padding: 0px 24px;
  }

  p {
    @include font($size: 16px, $color: midnight);
    padding: 25px 24px;
  }

  .button {
    width: 260px;

    @include for-size(mobile) {
      width: 100%;
    }
  }
}

.form {
  @include row(flex-start, flex-start);
  position: relative;
  overflow: visible;
  overflow-x: hidden;
  overflow-y: auto;
}

.step {
  position: relative;
  left: 0px;
  flex: 1;
  min-width: 100%;
  transition: left 0.5s ease, visibility 0.5s 0s ease;
  visibility: visible;
  padding: 8px;
  overflow: visible;

  .innerStep {
    transition: all 0.5s ease;
    background-color: red;
    width: 100%;
  }

  &--hidden {
    transition: left 0.5s ease, visibility 0.5s 0.5s ease, max-height 0.5s ease;
    visibility: hidden;
  }

  &--completed {
    transition: left 0.5s ease, visibility 0.5s 0.5s ease, max-height 0.5s ease;
    visibility: visible;
  }
}


.finePrint {
  // @include row(center, flex-start);
  @include font($size: 15px, $lineHeight: 24px, $color: grey-12);

  a {
    margin: 0 4px;
  }

  b {
    @include font($size: 15px, $lineHeight: 24px, $color: grey-12, $weight: bold);
  }
}


.terms {
  // @include row(center, flex-start);
  @include font($size: 14px, $lineHeight: 24px, $color: grey-12);
  padding-bottom: 8px;

  a {
    margin: 0 4px;
  }
}

.fixedHeight {
  .step--hidden,
  .step--completed {
    max-height: auto;
    overflow: visible;
  }

  .terms {
    height: 60px;
  }
}

.variableHeight {
  .step--hidden,
  .step--completed {
    max-height: 200px;
    overflow: hidden;
  }

  .terms {
    height: auto;
  }
}

// .variableHeight {
//   .step--hidden, .step--completed {
//     max-height: auto;
//     overflow: visible;
//   }
//   .terms {
//     height: auto;
//   }
// }

.footer {
  text-align: center;
  margin-top: 16px;
}
