@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 56px 0px;
  background-color: map-get($colors, natural);

  h3 {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      padding: 0px 24px 20px;
      font-size: 32px;
    }
  }

  .description {
    text-align: center;
    margin-bottom: 16px;
  }

  .tilesContainer {
    @include row(center, stretch);
    @include for-size(1477px) {
      display: none;
    }
    margin-top: 24px;
  }

  .carousel {
    display: none;
    @include for-size(1477px) {
      display: block;
    }
  }

  .tile {
    text-align: center;
    background-color: map-get($colors, white);
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
    min-width: 285px;
    width: auto;
    min-height: 285px;
    padding: 26px 16px;
    margin: 0 6px !important;
    border-width: 1.5px;
    line-height: 24px !important;

    &--selected {
      border-color: map-get($colors, lush);
    }

    &:first-of-type {
      margin-left: 0px;
    }
    &:last-of-type {
      margin-right: 0px;
    }

    &-icon {
      // margin: 16px 0 33px;
      height: 70px;
    }

    @include for-size(mobile) {
      width: auto;
      margin-left: 16px !important;
      margin-bottom: 4px !important;
    }
  }

  .articleContainer {
    @include row(stretch, flex-start);
    margin-top: 40px;

    .imageArticleContainer {
      width: 50%;
      margin-right: 16px;

      @include for-size(mobile) {
        width: 100%;

        .imageArticle {
          padding-top: 70% !important;
        }
      }

      .imageArticle {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
      }

      .ctaUnderneathImage {
        margin-top: 32px;
        button {
          width: 100%;
        }
      }
    }

    .content {
      width: 50%;
      margin-left: 16px;
      h4 {
        @include font($family: primary, $size: 28px, $lineHeight: 32px);
        margin-bottom: 16px;

        @include for-size(mobile) {
          text-align: center;
        }
      }
      p {
        @include font($size: 16px, $lineHeight: 24px, $weight: regular);
        margin-bottom: 32px;
      }

      b {
        @include font($size: 16px, $lineHeight: 24px, $weight: bold);
      }

      ol {
        @include font($size: 16px, $lineHeight: 24px, $weight: bold);
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 20px;

        li {
          margin-bottom: 20px;
          span {
            @include font($size: 16px, $lineHeight: 24px, $weight: regular);
          }

          ul {
            @include font($size: 16px, $lineHeight: 24px, $weight: regular);
            margin-top: 4px;
            list-style-type: disc;
            list-style-position: outside;
            padding-left: 20px;

            li {
              margin-bottom: 4px;
            }
          }
        }
      }

      ul {
        @include font($size: 16px, $lineHeight: 24px, $weight: regular);
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 28px;

        li {
          padding-left: 4px;
          margin-bottom: 4px;
        }
      }
    }

    @include for-size(mobile) {
      display: block;
      padding: 0 16px;

      .imageArticle {
        width: 100%;
        margin-bottom: 40px;
        padding-top: 70%;
        background-size: cover;
      }
      .content {
        width: 100%;
        margin-left: 0px;
      }
    }
  }

  .actionsContainer {
    @include column(center, flex-start);

    .actionButton,
    .actionLink {
      margin-top: 24px;
    }
  }
}

:global(.slick-track) {
  padding-bottom: 6px;
}
