@import 'styles/base.scss';

.infoContainer {
  @include row(flex-start, center);
  padding-bottom: 70px;
  position: relative;

  @include for-size(880px) {
    @include column(flex-end, center);
    padding-bottom: 48px;
  }

  &.right {
    .infoBox {
      order: 2;
    }

    .images {
      order: 1;
      left: calc((100vw - 100%) / -2);
    }

    @include for-size(880px) {
      .images {
        right: 24px !important;
        left: 0px !important;
      }
    }
  }
}

.infoBox {
  width: 445px;
  min-width: 445px;

  @include for-size(880px) {
    order: 2;
    width: 100%;
    min-width: 100%;
  }

  h3 {
    width: 100%;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(880px) {
      font-size: 28px;
    }
  }

  .description {
    margin-top: 25px;
    width: 100%;
    @include font($size: 16px, $color: midnight, $lineHeight: 25px);

    @include for-size(880px) {
      margin-top: 20px;
    }

    ul {
      > :last-child {
        margin-bottom: 0px;
      }
    }

    li {
      margin-bottom: 39px;
    }

    h5 {
      margin-bottom: 4px;
      margin-top: 0px;
      @include font($size: 16px, $color: midnight, $lineHeight: 22px, $weight: semibold);
    }

    p {
      letter-spacing: normal;
      margin: 0px;
      @include font($size: 16px, $color: midnight, $lineHeight: 22px, $weight: light);
    }

    a {
      color: map-get($colors, midnight);
      text-decoration: underline;
    }
  }

  .action {
    margin-top: 25px;
  }

  .actionButton {
    margin-top: 39px;
  }
}

.images {
  width: calc(100% - 445px);
  position: relative;
  right: calc((100vw - 100%) / -2);

  @include for-size(880px) {
    order: 1;
    left: 24px !important;
    right: 0px !important;
    width: calc(100% + 24px);
    margin-bottom: 28px;
  }

  @include for-size(wide) {
    //right: calc((100vw - 100%) / -2 + (45vw - (100% - 445px)));
    right: calc(445px - 50% - 5vw);

    .image {
      width: 45vw;
    }
  }
}

.image {
  position: relative;
  width: 100%;
  z-index: 2;
}

.image2 {
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0px;
  width: calc(100% - 44px);

  @include for-size(wide) {
    width: calc(45vw - 44px);
  }
}

.info1 {
  .image2 {
    top: -52px !important;
    right: unset !important;
    bottom: unset !important;
    left: 44px !important;

    @media (max-width: $mobileWidth) {
      top: -15px !important;
      right: 0px !important;
      bottom: unset !important;
      left: unset !important;
    }
  }
}

.info2 {
  @media (max-width: $mobileWidth) {
    .infoBox {
      padding-top: 22px;
    }
  }

  .image {
    left: -20px;

    @media (max-width: $mobileWidth) {
      left: 0px;
    }
  }

  .image2 {
    top: -42px !important;
    right: -2px !important;
    bottom: unset !important;
    left: unset !important;

    @media (min-width: 1500px) {
      right: unset !important;
      left: 66px !important;
    }

    @media (max-width: $mobileWidth) {
      top: unset !important;
      right: unset !important;
      bottom: -15px !important;
      left: 0px !important;
    }
  }
}

.info3 {
  @media (max-width: $mobileWidth) {
    .infoBox {
      padding-top: 22px;
    }
  }

  .image2 {
    top: -45px !important;
    right: unset !important;
    bottom: unset !important;
    left: 44px !important;

    @media (max-width: $mobileWidth) {
      top: unset !important;
      right: 0px !important;
      bottom: -15px !important;
      left: unset !important;
    }
  }
}
