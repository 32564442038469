@import 'styles/base.scss';

.linkButton {
  // @include row(flex-start, center);
  cursor: pointer;
  @include font($size: 18px, $weight: bold);

  .iconBeforeText {
    margin-right: 6px;
    position: relative;
  }

  .iconAfterText {
    margin-left: 5px;
    top: 2px;
    position: relative;
  }

  .iconAfterTextCaret {
    margin-left: 5px;
    top: 5px;
    position: relative;
  }

  i {
    font-weight: bold;
  }
}
