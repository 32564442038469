@import 'styles/base.scss';

.dialog {
  @include for-size(desktop) {
    width: 518px;
  }
}

.base {
  @include font($size: 11px, $color: white, $lineHeight: 14px, $weight: medium);
  display: inline-flex;
  justify-content: center !important;
  align-items: center !important;
  width: auto;
  border-radius: 12px;
  padding-left: 5px;
  padding-right: 8px;
  background: map-get($colors, dusty-blue-dark);
  height: 24px;
  user-select: none;
  text-align: center;
  transition: all 0.1s ease;
  position: relative;
  cursor: pointer;

  svg {
    margin-right: 2px;
  }

  .label {
    color: white !important;
  }

  &:hover {
    background: map-get($colors, dusty-blue);
  }

  &.outlined {
    background: transparent;
    border: 1px solid map-get($colors, dusty-blue-dark);
    color: map-get($colors, dusty-blue-dark) !important;

    .label {
      color: map-get($colors, dusty-blue-dark) !important;
    }

    &:hover {
      background: map-get($colors, dusty-blue-dark);
      color: white !important;

      .label {
        color: white !important;
      }
    }
  }

  &.outlinedWhite {
    background-color: white;
    color: map-get($colors, dusty-blue-dark) !important;

    .label {
      color: map-get($colors, dusty-blue-dark) !important;
    }

    &:hover {
      background: map-get($colors, dusty-blue-dark);
      color: white !important;

      .label {
        color: white !important;
      }
    }
  }
}


.modalHeader {
  margin-bottom: 0px;
}

.link {
  color: map-get($colors, actionable-green) !important;
  @include font($weight: semibold);

  &:hover {
    color: map-get($colors, actionable-green-hover) !important;
    text-decoration: underline;
  }
}