@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 0px;
  background-color: map-get($colors, white);

  h3 {
    width: 100%;
    padding: 56px 24px 16px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      padding: 38px 24px 20px;
      font-size: 32px;
    }
  }

  .subtitle {
    font-style: italic;
    width: 100%;
    text-align: center;
    @include font($size: 16px, $color: midnight);
  }
}

.glContainerSmall {
  padding-top: 24px;
  @include row(space-between, flex-start);

  > :first-child {
    padding-left: 0px;
  }

  > :last-child {
    padding-right: 0px;
  }

  &.center {
    @include row(center, flex-start);

    @include for-size(mobile) {
      @include column(center, flex-start);
    }
  }

  @include for-size(mobile) {
    @include column(center, flex-start);
  }
}
