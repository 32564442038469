@import 'styles/base.scss';

.backdrop {
  display: flex;
  flex-grow: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
  overflow: auto;
  padding: 80px 24px;
  opacity: 0;
  transition: opacity 0.1s ease;

  @include for-size(mobile) {
    padding: 8px;
  }
}

.hidden {
  display: none;
}

.margin {
  @include for-size(mobile) {
    padding: 0px;
  }
}

.show {
  opacity: 1;
}
