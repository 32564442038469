@import 'styles/base.scss';

.section {
  h4 {
    @include font($size: 16px, $color: grey-5, $lineHeight: 22px, $family: secondary-semibold, $letterSpacing: 2.5px);
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .carousel {
    max-width: 781px;
    margin: auto;

    button[class*='slick-arrow '] {
      &:before {
        display: inline-block;
        font: normal normal normal 14px/1 CustomIconsVF !important;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 50px !important;
        color: map-get($colors, grey-5) !important;
        line-height: 30px !important;

        &:hover {
          color: map-get($colors, midnight) !important;
        }
      }

      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    button[class*='slick-disabled'] {
      display: none !important;
    }

    button[class*='slick-next'] {
      &:before {
        content: '';
        background-image: url('../../../components/common/IconSvg/svgs/arrow-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
        width: 24px;
        height: 24px;
      }

      right: -35px;

      @include for-size(1240px) {
        right: -10px;
      }
      @include for-size(mobile) {
        right: -19px;
      }
    }

    button[class*='slick-prev'] {
      &:before {
        content: '';
        background-image: url('../../../components/common/IconSvg/svgs/arrow-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
      }

      left: -50px;

      @include for-size(1240px) {
        left: -20px;
      }
    }

    .insideSlide {
      @include row(center, center);

      .image {
        height: 273px;
        width: 273px;
        border-radius: 50%;
        object-fit: cover;
      }
      .info {
        max-width: 340px;
        text-align: left;
        margin-left: 49px;

        line-height: 30px;
        color: #333333;

        h5 {
          @include font($family: secondary-medium, $size: 18px);
        }

        .location {
          @include font($family: secondary, $size: 16px);
          margin-bottom: 25px;
        }

        .quote {
          @include font($family: primary, $size: 18px);
          margin-bottom: 30px;
        }
      }
    }
  }
  @include for-size(mobile) {
    .carousel {
      .insideSlide {
        flex-direction: column;

        .image {
          width: 250px;
          height: 250px;
        }

        .info {
          text-align: center;
          width: auto;
          margin: 20px 0 0;
          max-width: 100vw;

          .quote {
            font-size: 16px;
          }
        }
      }
    }
  }
}
