@import 'styles/base.scss';

.base {
  @include column(center, flex-start);
  padding: 28px 16px 28px;
  border: 1px solid map-get($colors, grey-2);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  background: map-get($colors, white);
  user-select: none;
  outline-offset: 2px;
  position: relative;
}

.selected {
  border-color: map-get($colors, lush);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19), inset 0 0 0 1px map-get($colors, lush);
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
}

.image {
  @include row(center, center);
  width: 100%;
  // height: auto;
  text-align: center;
  // flex: 1;
  height: 80px;
  margin-bottom: 24px;

  img {
    display: inline-block;
  }
}

.title {
  padding: 0px 12px;
  text-align: center;
  @include font($size: 16px, $lineHeight: 24px, $color: midnight, $weight: bold);
}

.background {
  width: auto;
  height: auto;
}

.underContent {
  width: 100%;
  margin-top: 20px;
}

.description {
  padding: 0px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0;
  @include font($size: 14px, $color: p-3, $lineHeight: 24px);

  @include for-size(400px) {
    height: auto;
  }
}

.subCardContent {
  padding: 0px;
  text-align: left;
  text-wrap: balance;
  margin-top: 3px;
  margin-bottom: 0;
  @include font($size: 14px, $color: p-3, $lineHeight: 22px);

  @include for-size(400px) {
    height: auto;
  }
}

.icons {
  @include row(space-around, center);
  margin-top: auto;
  padding-top: 16px;
  width: 100%;
}

.iconBox {
  @include row(flex-start, flex-start);
}

.icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  margin-right: 6px;
}

.iconTitle {
  height: 24px;
  @include font($size: 14px, $color: p-3, $lineHeight: 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.theme {
  &-corporate {
    &.selected {
      border-color: map-get($colors, teal) !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19), inset 0 0 0 1px map-get($colors, teal) !important;
    }
  }

  &-medical {
    &.selected {
      border-color: map-get($colors, dusty-blue) !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19), inset 0 0 0 1px map-get($colors, dusty-blue) !important;
    }
  }

  &-health {
    &.selected {
      border-color: map-get($colors, health) !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19), inset 0 0 0 1px map-get($colors, health) !important;
    }
  }
}
