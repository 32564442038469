@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 56px 0;
  position: relative;
  background-color: map-get($colors, white);

  @include for-size(mobile) {
    padding: 56px 0;
  }

  .content {
    max-width: 630px;
    text-align: center;
  }

  h3 {
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    @include for-size(mobile) {
    }
  }

  p {
    @include font($size: 16px, $lineHeight: 24px, $weight: regular);
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }
}
