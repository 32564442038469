@import 'styles/base.scss';

.segments {
  width: 100%;
  overflow: hidden;

  label {
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 26px;
    user-select: none;
    @include font($size: 14px, $color: grey-5);
  }

  .container {
    @include row(stretch, flex-start);
    outline: none;
  }

  .extraText {
    font-family: map-get($font-family, secondary);
    font-size: 16px;
    margin-top: 10px;
  }
}

.wrapLabel {
  label {
    overflow: visible;
    height: auto;
    white-space: normal;
    text-overflow: inherit;
    margin-bottom: 4px;
    position: relative;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.readOnly {
  opacity: 0.5;
  pointer-events: none;
}

.item {
  height: 50px;
  float: left;
  width: 100%;
  @include font($size: 16px, $color: midnight, $lineHeight: 47px);
  border: 1px solid map-get($colors, grey-3);
  background: white;
  padding: 0px 5px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child) {
    border-right-width: 0px;
  }

  &.active {
    @include font($color: lush, $weight: bold);
    border: 1px solid map-get($colors, lush);
    box-shadow: inset 0px 0px 0px 1px map-get($colors, lush);
    + .item {
      border-left-width: 0px;
    }
  }

  .invisible {
    display: none;
  }
}

.invalid {
  .active {
    color: map-get($colors, error);
    border-color: map-get($colors, error);
    box-shadow: inset 0px 0px 0px 1px map-get($colors, error);
  }
}

.validationText {
  min-height: 17px;
  margin-top: 5px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  color: map-get($colors, error);
  @include font($size: 14px, $lineHeight: 17px);
}

.theme {
  &-corporate {
    .active {
      @include font($color: teal, $weight: bold);
      border: 1px solid map-get($colors, teal);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, teal);
    }
  }
  &-medical {
    .active {
      @include font($color: dusty-blue, $weight: bold);
      border: 1px solid map-get($colors, dusty-blue);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, dusty-blue);
    }
  }
  &-health {
    .active {
      @include font($color: health, $weight: bold);
      border: 1px solid map-get($colors, health);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, health);
    }
  }
}
