@import 'styles/base.scss';

.block {
  background-color: map-get($colors, grey-2);
  padding: 56px 0;
}

.logoContainer {
  @include row(space-between, center);
  margin-bottom: 24px;

  .imgContainer {
    height: 76px;
    width: 350px;

    @include for-size(mobile) {
      height: 45px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }

  .logoZeel {
    object-fit: contain;
  }

  .logoHyperice {
    object-fit: contain;
  }

  i {
    font-size: 50px;
    color: map-get($colors, midnight);
  }
}

.textContainer {
  @include row(space-between, center);
  p {
    @include font($size: 18px, $weight: bold, $lineHeight: 22px, $color: midnight);
    width: 350px;
    text-align: center;

    @include for-size(mobile) {
      display: none;
    }

    &:nth-of-type(2) {
      @include font($size: 16px, $weight: regular, $lineHeight: 24px);

      @include for-size(mobile) {
        display: block;
        width: 100%;
      }
    }
  }
}
