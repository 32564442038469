@import '../inputs.module.scss';

.inputBox {
  overflow: visible;
}
.wrapper > div:first-child {
  width: 100%;
}

.hideCalendar {
  display: none;
}
