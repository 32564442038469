@import 'styles/base.scss';

.base {
  @include row(stretch, flex-start);
  margin: 50px 0 65px;

  @include for-size(mobile) {
    display: block;
  }

  .left {
    min-width: 270px;
    max-width: 270px;
    margin-right: 40px;

    @include for-size(mobile) {
      min-width: 100%;
      max-width: 100%;
    }

    .image {
      width: 100%;
    }
  }

  .title {
    @include font($size: 24px, $weight: bold, $color: midnight, $lineHeight: 24px);
    margin-bottom: 30px;

    @include for-size(mobile) {
      margin-top: 10px;
      margin-bottom: 18px;
    }
  }

  .description {
    @include font($size: 16px, $weight: light, $color: midnight, $lineHeight: 24px);
  }

  a,
  b {
    @include font($size: 16px, $weight: bold, $color: midnight, $lineHeight: 24px);
    text-decoration: underline;
  }
}
