@import 'components/modals/templates/Modal/style.module.scss';

.feature {
  width: calc(100% - 15px);
  margin-bottom: 38px;
  display: flex;
  margin-top: 1px;

  @include for-size(mobile) {
    width: 100%;
  }
}

.featureImage {
  flex: 0 0 47px;
  width: 47px;
  height: 47px;
  float: left;
  margin-right: 22px;
  margin-left: 1px;
}

.featureInfo {
  width: 100%;
  color: map-get($colors, midnight);
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}

.featureTitle {
  width: 100%;
  @include font($weight: bold);
}

.featureDescription {
  width: 100%;
  @include font($weight: medium);
}

.modalBodyWrapper {
  margin-bottom: 0;
}

@include for-size(mobile) {
  .insideModal {
    max-height: 79vh;
  }
}
