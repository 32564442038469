@import 'styles/base.scss';

.faqSection {
  position: relative;
  background-color: map-get($colors, white);

  &-content {
    @include row(space-between, flex-start);
    position: static !important;

    h2 {
      text-align: left;
      padding-right: 56px;
      font-size: 32px;
    }

    ul {
      flex: 1;
    }

    li {
      text-align: left;
      position: relative;
      border-top: solid 1px map-get($colors, grey-2);
      padding: 13px 16px;
    }

    @include for-size(mobile) {
      padding: 0 !important;
      flex-direction: column;

      h2 {
        width: 100%;
        font-size: 32px;
        padding-right: 0;
        text-align: center;
        margin-bottom: 56px;
        line-height: 36px;
      }
      ul {
        width: 100%;
      }
    }
  }

  .collapsibleContainer {
    @include for-size(desktop) {
      display: none;
    }

    @include row(center, center);
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 80%);
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 24px 0 8px;

    .arrowIcon {
      transition: transform 0.5s ease;
    }
  }

  &.isVisible {
    max-height: 2000px;

    .arrowIcon {
      transform: rotate(180deg);
    }
  }

  @include for-size(mobile) {
    overflow: hidden;
    max-height: calc(100vw - 48px + 220px);
    transition: max-height 0.5s ease;
  }
}
