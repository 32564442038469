@import 'styles/base.scss';

.base {
  width: 100%;
  position: relative;
  margin-bottom: 12px;

  label {
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 20px;
    user-select: none;
    @include font($size: 14px, $color: grey-5);
  }
}

.chipDisabled {
  pointer-events: none;
  opacity: 0.3;
}

.chipHolder {
  position: relative;
  display: inline-block;
}

.underElement {
  position: absolute;
  top: 55px;
  width: calc(100% - 10px);
}

.item {
  margin-right: 10px;
  margin-top: 10px;
  height: 47px;
  display: inline-block;
  width: auto;
  border: 1px solid map-get($colors, grey-3);
  border-radius: 25px;
  background: white;
  padding: 0 20.4px;
  cursor: pointer;
  user-select: none;
  outline: none;
  @include font($size: 16px, $color: midnight, $lineHeight: 44px, $weight: light);

  &.active {
    color: map-get($colors, lush);
    border: 1px solid map-get($colors, lush);
    box-shadow: 0 0 0 1px map-get($colors, lush);
    @include font($weight: bold);
    padding: 0 20px;
  }

  .invisible {
    display: none;
  }
}

.choiceButtons {
  border-radius: 0;
  margin-left: 0;
  border-right-width: 0;

  &:last-child {
    border-right-width: 1px;

    &.active {
      border-right-width: 2px;
    }
  }
}

.wrapper {
  width: 100%;
  text-align: left;
  outline: none;
}

.wrapLabel {
  label {
    overflow: visible;
    height: auto;
    white-space: normal;
    text-overflow: inherit;
    margin-bottom: 4px;
  }
}

.validationText {
  min-height: 17px;
  margin-top: 5px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  color: map-get($colors, error);
  text-align: left;
  @include font($size: 14px, $lineHeight: 17px);
}

.theme {
  &-corporate {
    .active {
      color: map-get($colors, teal);
      border: 1px solid map-get($colors, teal);
      box-shadow: 0 0 0 1px map-get($colors, teal);
    }
  }
  &-medical {
    .active {
      color: map-get($colors, dusty-blue);
      border: 1px solid map-get($colors, dusty-blue);
      box-shadow: 0 0 0 1px map-get($colors, dusty-blue);
    }
  }
  &-health {
    .active {
      color: map-get($colors, health);
      border: 1px solid map-get($colors, health);
      box-shadow: 0 0 0 1px map-get($colors, health);
    }
  }
}
