@import '../../style.module.scss';

.fortis {
}

.fortisFooter {
  margin-top: 0px !important;

  button {
    margin-top: 0px;
  }
}
