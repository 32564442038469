@import 'styles/base.scss';
@import '../Pricing/style.module.scss';

.pricing {
  height: 310px;
  max-height: 310px;
  overflow: hidden;
  transition: all 0.5s ease;
  justify-content: flex-start;
  position: relative;

  &--open {
    max-height: 1000px;
    height: auto;
  }
  .button {
    overflow: visible;
    min-height: 50px;
  }
  .subtitle {
    @include font($size: 14px, $color: grey-5, $lineHeight: 1.43);
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .detailedItems {
    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .leftSide {
        margin-right: 8px;

        svg {
          width: 24px;
        }
      }
      .rightSide {
        h4 {
          @include font($size: 14px, $color: midnight, $family: secondary-bold, $lineHeight: 22px);
          text-align: left;
          margin: 0;
        }
        p {
          @include font($size: 14px, $color: grey-5, $lineHeight: 22px);
          margin-bottom: 15px;
        }
      }
    }
  }
  .learnLink {
    @include font($size: 14px, $color: midnight, $family: secondary-semibold);
    text-decoration: underline;
    margin-bottom: 30px;

    i {
      color: map-get($colors, midnight) !important;
    }
  }
  .toggler {
    background: -moz-linear-gradient(
      bottom,
      rgba(255, 255, 255, 1) 19%,
      rgba(255, 255, 255, 0.28) 77%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(255, 255, 255, 1) 19%,
      rgba(255, 255, 255, 0.28) 77%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 19%,
      rgba(255, 255, 255, 0.28) 77%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0px;
    text-align: center;
    cursor: pointer;
    padding-top: 15px;
  }

  @include for-size(mobile) {
    .details {
      line-height: 20px;
      height: 40px;
      min-height: 40px;
    }
  }
}
