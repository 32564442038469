@import 'styles/base.scss';

.base {
  @include row(flex-start, center);
  @include font($size: 18px, $weight: semibold);
  width: 100%;
  margin: 16px 0;
  position: relative;

  .label {
    @include font($size: 18px, $weight: semibold);
    text-align: left;
  }

  .contentRight {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
  }
}

.icon {
  margin-right: 8px;
}

.disabled {
  opacity: 0.5;
}

.marginTop {
  margin-top: 40px;
}
