@import 'styles/base.scss';

.block {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: map-get($colors, white);

  @include for-size(mobile) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  > :last-child {
    padding-bottom: 0px;
  }
}
