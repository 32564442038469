@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 0px;
  padding-top: 24px;
  background-color: map-get($colors, white);

  h2 {
    width: 100%;
    padding: 28px 24px 32px 24px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      padding: 20px 24px;
      font-size: 28px;
    }
  }
}

.glContainerSmall {
  @include row(space-between, flex-start);
  flex-wrap: wrap;
  padding: 0px;

  &.center {
    @include row(center, flex-start);

    @include for-size(mobile) {
      @include column(center, flex-start);
    }
  }

  @include for-size(mobile) {
    @include column(center, flex-start);
  }
}
