@import 'components/modals/templates/Modal/style.module.scss';

.modal {
  padding: 0px 0px 32px;
  max-width: 440px;
  border-radius: 10px;

  @include for-size(mobile) {
    max-width: 100vw;
    max-height: 160px !important;
  }
}

.iconsBar {
  min-height: 0px;
  top: 10px;
}

.header {
  padding: 8px 16px;
  margin-bottom: 0px;
}

.body {
  display: none;
}

.footer {
  display: none;
}
