@import 'styles/base.scss';

.valuePropListSection {
  @include row(center, center);
  ul {
    width: fit-content;
    margin: auto;
    margin-top: 36px;
    li {
      @include row(flex-start, center);
      text-align: left;
      margin: 24px 0;
      .checkIcon {
        margin-right: 16px;
      }
      p {
        @include font($size: 18px, $lineHeight: 22px, $weight: semibold);
      }
    }
  }

  .button {
    margin-top: 30px;

    @include for-size(mobile) {
      margin-top: 8px;
    }
  }
}
