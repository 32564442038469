@import 'styles/base.scss';

.icon {
  position: relative;
  flex-shrink: 0;
  outline: none;

  &--clickable {
    cursor: pointer;
    outline: default;
  }
}
