@import 'styles/base.scss';

.container {
  position: fixed;
  padding: 8px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(map-get($colors, error), 0.9);
  z-index: 99999;
}

.content {
  @include font($size: 16px, $color: white);
  @include row(center, center);
  text-align: center;
  max-width: 400px;
  margin: auto;

  p,
  a {
    margin: 0 8px;
  }

  .expired {
    flex: 1;
  }

  a {
    color: map-get($colors, white);
    &:hover {
      color: map-get($colors, white);
    }
  }

  @include for-size(mobile) {
    @include row(space-between, center);
  }
}
