@import 'styles/base.scss';

.block {
  width: 100%;

  .rule {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 5px 0;

    &-pass {
      color: map-get($colors, lush-alt);
    }
    &-fail {
      color: map-get($colors, error);
    }
    .icon {
      color: inherit;
      width: 20px;
      height: auto;
      margin-right: 7px;
    }

    .passwordStrength {
      @include font($family: secondary-medium, $color: error, $size: 14px);

      &-strong {
        color: map-get($colors, lush);
      }
      &-medium {
        color: map-get($colors, yellow);
      }
    }
  }
}
