@import 'styles/base.scss';

.base {

}


.error {
  @include font($weight: medium);
  color: map-get($colors, error-dark);
  text-align: center;
  margin-bottom: 24px;
}