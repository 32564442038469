@import 'styles/base.scss';

:global(.superMenu-enter) {
  opacity: 0.01;
  transform: scale(1.1);
}

:global(.superMenu-enter-active) {
  opacity: 1;
  transform: scale(1);
  transition: all $transitionTime;
}

:global(.superMenu-exit) {
  opacity: 1;
  transform: scale(1);
  transition: all $transitionTime;
}

:global(.superMenu-exit-active) {
  opacity: 0.01;
  transform: scale(1.1);
}

.superMenu {
  @include row(stretch, stretch);
  position: fixed;
  z-index: 1001;
  background: map-get($colors, white);
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow-y: auto;
}

.close {
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  text-align: center;
  position: fixed;
  top: 15px;
  right: 25px;
  font-size: 28px !important;
  color: map-get($colors, midnight);
  text-shadow: 0 1px 0 map-get($colors, white);
  opacity: 0.2;
  z-index: 1;

  @include for-size(mobile) {
    top: 8px;
    left: 2px;
    right: inherit;
  }

  &:hover {
    color: map-get($colors, midnight);
    cursor: pointer;
    opacity: 0.5;
  }
}

.left {
  width: 66.66%;
  background: map-get($colors, white);
  padding: 50px 75px 0px 90px;

  @include for-size(1200px) {
    padding: 50px 50px 0px 50px;
  }

  @include for-size(991px) {
    width: 100%;
    position: relative;
    bottom: inherit;
    min-height: 300px;
    padding-top: 50px;
  }

  @include for-size(mobile) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }

  .logo {
    display: block;
    width: 88px;
    height: 36px;
    background-image: map-get($assets, logo);
    background-size: 88px 36px;
    background-repeat: no-repeat;
    background-position: 50% 43%;
    background-clip: content-box;

    @include for-size(mobile) {
      margin-left: 50%;
      width: 88px;
      height: 63px;
      transform: translateX(-50%);
      background-size: 68px 28px;
      background-position: 50% 50%;
    }
  }

  .tabs {
    @include for-size(desktop) {
      display: none;
    }

    @include for-size(mobile) {
      > div {
        padding: 0 19px;
      }
    }
  }

  .tabDetailBox {
    @include font($size: 14px, $lineHeight: 24px, $weight: regular, $color: grey-4);
    padding: 8px 0px 16px;
    border-bottom: solid 1px map-get($colors, grey-2);
    margin: 10px 25px 18px;

    .boxIcon {
      font-size: 24px;
      margin-right: 14px;
      color: map-get($colors, lush);

      &-business {
        height: 24px;
        width: auto;
        color: map-get($colors, teal);
      }

      &-provider {
      }

      &-health {
        height: 22px;
        color: map-get($colors, health);
      }
    }
    @include for-size(mobile) {
      display: flex;
      align-items: center;
    }
  }

  .businessTabActiveLine {
    background-color: map-get($colors, teal);
  }

  .healthTabActiveLine {
    background-color: map-get($colors, health);
  }

  .links {
    @include row(space-between, flex-start);
    width: 100%;
    margin-top: 60px;

    @include for-size(mobile) {
      margin-top: 0;
      display: block;
      padding-bottom: 100px;
    }

    .menuTitle {
      @include font($size: 16px, $lineHeight: 1.8em, $weight: bold);
      margin: 25px 0 10px;

      @include for-size(mobile) {
        display: none;
      }
    }

    .menuTitleForce {
      @include for-size(mobile) {
        display: block;
        padding-left: 29px;
      }
    }

    ul {
      width: 25%;
      padding-right: 24px;

      .headerLink {
        user-select: none;
        padding: 5px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include font($size: 16px, $lineHeight: 1.8em, $weight: regular);

        @include for-size(mobile) {
          border-left: solid 4px transparent;
          padding: 0px 25px;
          margin: 12px 0;
        }
      }

      .link,
      .deepLink {
        cursor: pointer;
        user-select: none;
        padding: 5px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include font($size: 16px, $lineHeight: 1.8em, $weight: regular);

        p {
          flex: 1;

          @include row(space-between, center);
        }

        .pill {
          display: none;
          background-color: map-get($colors, grey-4);
          margin-left: 7px;
        }

        svg {
          width: 18px;
          height: 18px;
          margin-right: 16px;
        }

        @include for-size(mobile) {
          .pill {
            display: block;
          }

          border-left: solid 4px transparent;
          padding: 0px 25px;
          margin: 12px 0;

          svg {
            width: 22px;
            height: 22px;
          }
        }

        &:hover {
          color: map-get($colors, lush);
        }

        &.small {
          font-size: 16px;
          margin-left: 20px;

          @include for-size(mobile) {
            margin-left: 0;
          }
        }

        &--active {
          @include font($size: 16px, $lineHeight: 1.8em, $weight: bold);
          color: map-get($colors, lush);

          @include for-size(mobile) {
            color: map-get($colors, midnight);
            border-left-color: map-get($colors, lush);
          }
        }

        &-business {
          &:hover {
            color: map-get($colors, teal);
          }
          &--active {
            @include font($size: 16px, $lineHeight: 1.8em, $weight: bold);
            color: map-get($colors, teal);

            @include for-size(mobile) {
              color: map-get($colors, midnight);
              border-left-color: map-get($colors, teal);
            }
          }
        }

        &-health {
          &:hover {
            color: map-get($colors, health);
          }
          &--active {
            @include font($size: 16px, $lineHeight: 1.8em, $weight: bold);
            color: map-get($colors, health);

            @include for-size(mobile) {
              color: map-get($colors, midnight);
              border-left-color: map-get($colors, health);
            }
          }
        }
      }

      .deepLink {
        padding: 5px;
        font-size: 16px;

        .pill {
          display: none;
        }

        > a {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        svg {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }

        &.topItem {
          svg {
            width: 20px;
            margin-left: 20px;
          }
        }

        @include for-size(mobile) {
          padding: 10px 45px;
          font-size: 12px;

          .pill {
            display: inline-block;
            background: map-get($colors, grey-4);
            margin-left: 17px;
          }

          svg {
            width: 24px;
            height: auto;
            margin-right: 15px;
          }

          &.topItem {
            svg {
              width: 18px;
            }
          }
        }
      }

      &:last-child {
        padding-right: 0px;
      }

      @include for-size(mobile) {
        width: 100%;
        text-align: left;

        &:last-of-type {
          width: 100%;
          li a {
            color: map-get($colors, grey-3);
          }
        }
      }
    }

    .extraContent {
      @include for-size(mobile) {
        h3,
        li a {
          color: map-get($colors, grey-3);
        }
      }

      @include for-size(desktop) {
        width: 25%;
        ul {
          width: 100%;
          columns: 2;
        }
      }
    }
  }

  .separator {
    margin: 18px 25px;
    border-bottom: solid 1px map-get($colors, grey-2);
    height: 1px;
  }

  .bottom {
    @include row(space-between, center);
    padding-top: 40px;
    border-top: 1px solid map-get($colors, grey-3);
    background: map-get($colors, white);
    height: 100px;
    margin-top: 55px;
    width: 100%;

    @include for-size(991px) {
      height: 128px;
    }

    @include for-size(mobile) {
      display: none;
    }

    @include for-size(wide) {
      margin-top: 80px;
    }

    .question {
      @include row(flex-start, center);
      cursor: pointer;
      @include font($lineHeight: 38px);

      @include for-size(mobile) {
        text-align: center;
        height: 28px;
        line-height: 28px;
        padding-bottom: 20px;
      }

      .questionIcon {
        width: 28px;
        height: 28px;
        background-image: map-get($assets, question);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 15px;

        @include for-size(mobile) {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }

    .socialBox {
      display: inline-flex;
      line-height: 40px;

      @include for-size(mobile) {
        line-height: 28px;
        text-align: center;

        a {
          width: 28px;
          height: 28px;
          line-height: 26px !important;
          font-size: 20px !important;
          margin-right: 5px;
        }
      }

      & > :last-child a {
        margin-right: 0px;
      }
    }
  }
}

.right {
  width: 33.33%;
  background: map-get($colors, old-more-screen-grey);
  padding: 50px 113px;
  min-height: 100%;

  @include for-size(1450px) {
    padding: 50px 50px;
  }

  @include for-size(991px) {
    width: 33%;
    position: relative;
    bottom: inherit;
    min-height: 300px;
  }

  @include for-size(mobile) {
    width: 100%;
    min-height: initial;
    padding: 15px;
    background: map-get($colors, white);
    position: fixed;
    bottom: 0;
    height: 80px;
    top: initial;
    border-top: solid 1px map-get($colors, grey-2);
  }

  h2 {
    margin-top: 117px;
    @include font($size: 32px, $family: primary);

    @include for-size(mobile) {
      display: none;
    }
  }

  .loginBox {
    @include font();

    h2 {
      margin-bottom: 10px;
    }

    .dontHaveAccount {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.6;
      color: map-get($colors, midnight);

      a {
        text-decoration: underline;
        @include font($color: midnight, $weight: semibold);

        &:hover {
          color: map-get($colors, lush);
        }
      }
    }

    .forgotPassword {
      width: 100%;
      text-align: right;
      min-height: 20px;
      cursor: pointer;
      @include font($size: 14px, $color: midnight, $lineHeight: 20px, $weight: semibold);

      &:hover {
        color: map-get($colors, lush);
      }
    }

    .submitButton {
      margin-top: 30px;
      margin-bottom: 40px;
      width: 100%;
    }
  }

  .buttons {
    margin-top: 80px;
    width: 100%;

    @include for-size(mobile) {
      margin-top: 0px;
    }

    a,
    button {
      margin-bottom: 16px;
      width: 100%;
      min-width: inherit;

      @include for-size(mobile) {
        width: calc(50% - 10px);
        margin-bottom: 0px;
      }
    }

    .glVisibleOnMobile {
      margin-right: 20px;
    }

    & > :last-child {
      margin-bottom: 0px;
    }
  }
}

.button {
  width: 100%;
}

.mobileSignInLink {
  width: 100%;
}
