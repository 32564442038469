@import 'styles/base.scss';

.base {

}

.submitButton {
  border-radius: 12px;
  @include for-size(mobile) {
    min-width: 220px;
  }
}

.tipExplainer {
  @include font($size: 14px, $lineHeight: 22px, $color: grey-4);
  @include row(flex-start, center);
  gap: 6px;
  margin-top: 8px;
  padding: 0 16px 16px;
  cursor: pointer !important;
  user-select: none;


  &:hover {
    span {
      border-bottom: solid 1px map-get($colors, grey-4);
    }
  }
}