@import 'styles/base.scss';

.detailsListContainer {
  @include row(center, flex-start);
  @include for-size(mobile) {
    display: none;
  }

  &.forceWrap {
    display: none !important;
  }

  &--stacked {
    @include for-size(mobile) {
      display: block;
    }

    &.forceWrap {
      display: block;
    }
  }
}

.detail {
  width: 33%;
  margin-bottom: 14px;
  text-align: center;
  padding: 0 24px;

  .title {
    margin-bottom: 12px;
    @include font($size: 18px, $color: midnight, $lineHeight: 22px, $weight: semibold);
    @include for-size(mobile) {
      margin-bottom: 8px;
    }
  }

  .description {
    @include font($size: 16px, $color: midnight, $lineHeight: 24px);
  }

  .imgContainer {
    @include row(center, center);
    height: 50px;
    width: 50px;
    margin: auto;
    margin-bottom: 16px;

    @include for-size(mobile) {
      margin-bottom: 8px;
    }
  }

  img {
    display: inline-block;
    max-height: 70px;

    @include for-size(mobile) {
      margin-bottom: 0px;
    }
  }

  @include for-size(mobile) {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 32px;
  }
}

.carousel {
  @include for-size(desktop) {
    display: none;
  }

  &.forceWrap {
    display: block !important;
  }

  @include row(flex-start, center);
  padding-top: 24px;

  width: 100vw; // to compensate the padding to have swiping flush to edge
  left: -24px; // ^

  :global(.slick-dots) {
    li {
      top: -12px;
      margin: 0;

      button {
        padding: 0;
      }
    }
  }
}

.button {
  margin-top: 24px;
}

.subContent {
  margin-top: 16px;

  p {
    @include font($size: 18px, $lineHeight: 26px);
  }
}
