@import 'styles/base.scss';

.base {
  width: 100%;
  overflow: hidden;
  position: relative;

  label {
    width: 100%;
    height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    @include font($size: 14px, $color: grey-5, $family: tertiary, $weight: 500);
  }
}

.selectedState {
  width: 100%;
  border-radius: 4px;
  height: 50px;
  padding: 0px 20px;
  outline: none;
  border: 1px solid map-get($colors, lush);
  box-shadow: inset 0px 0px 0px 1px map-get($colors, lush);
  user-select: none;
}

.text {
  cursor: default;
  @include font($size: 16px, $color: lush, $lineHeight: 45px, $weight: bold);
}

.icon {
  position: absolute;
  right: 10px;
  top: 27px;
  color: map-get($colors, midnight);
  cursor: pointer;
  height: 45px;
  line-height: 45px;
  font-size: 20px;

  &:hover {
    color: map-get($colors, lush);
  }
}

.theme {
  &-corporate {
    .selectedState {
      border: 1px solid map-get($colors, teal);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, teal);
    }
    .text {
      @include font($size: 16px, $color: teal, $lineHeight: 45px, $weight: bold);
    }
    .icon:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    .selectedState {
      border: 1px solid map-get($colors, dusty-blue);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, dusty-blue);
    }
    .text {
      @include font($size: 16px, $color: dusty-blue, $lineHeight: 45px, $weight: bold);
    }
    .icon:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .selectedState {
      border: 1px solid map-get($colors, health);
      box-shadow: inset 0px 0px 0px 1px map-get($colors, health);
    }
    .text {
      @include font($size: 16px, $color: health, $lineHeight: 45px, $weight: bold);
    }
    .icon:hover {
      color: map-get($colors, health);
    }
  }
}
