@import 'styles/base.scss';

.contactFormBlock {
  background-color: map-get($colors, dusty-green);

  &-content {
    @include row(stretch, flex-start);
    @include for-size(mobile) {
      display: block;
    }
  }

  h2 {
    @include font($size: 32px, $lineHeight: 46px, $color: white, $weight: semibold);
    max-width: 490px;
    text-align: left;

    @include for-size(mobile) {
      @include font($size: 28px, $lineHeight: 38px, $weight: semibold);
      max-width: unset;
      margin-bottom: 48px;
    }
  }
  .successTitle {
    text-align: center;
    margin: auto;
    width: auto;
  }
  .formContainer {
    flex: 1;
    margin-left: 80px;
    text-align: right;
    min-width: 400px;

    .input {
      label,
      input {
        color: white;
      }
    }
    .inputWrapper {
      border-bottom-color: white;
    }
    .select {
      :global(.select__control) {
        background-color: transparent !important;
        border-color: white !important;
      }
      :global(.select__value-container *) {
        color: white !important;
      }
    }
    .button {
      @include for-size(mobile) {
        margin-left: 0px;
      }
    }

    @include for-size(mobile) {
      margin-left: 0px;
      min-width: unset;
    }
  }
  .termsOfUse {
    color: white;
    margin-top: 16px;
    a {
      color: white;

      &:hover {
        color: map-get($colors, actionable-green) !important;
      }
    }
  }
}
