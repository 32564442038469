@import 'styles/base.scss';

.base {
  padding: 40px 0px;
  min-height: 100dvh;

  @include for-size(mobile) {
    padding: 24px 0px !important;
  }

  &--withTimeline {
    padding-top: 20px;
  }
}

.flowHeader {
  h3 > a {
    @include font($weight: semibold);
  }
}

.flowBody {
  width: 940px !important;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - 32px);

  &--default {

  }

  &--narrow {
    width: 422px !important;
  }

  &--large {

  }

  &--full {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.flowFooter {
  margin-top: 32px !important;
  text-align: center;

  button {
    width: 422px !important;
    max-width: 100%;
  }
}

.row {
  @include row(stretch, flex-start);
  margin-bottom: 32px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;

  @include for-size(mobile) {
    display: block;
  }
}

.cell {
  width: 50%;
  min-width: 50%;
  max-width: 50%;

  @include for-size(mobile) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @include for-size(desktop) {
    padding-left: 24px;
    padding-right: 24px;

    &:first-child {
      padding-left: 0px;
      padding-right: 24px;
    }

    &:last-child {
      padding-left: 24px;
      padding-right: 0px;
    }
  }
}