@import 'components/modals/templates/Modal/style.module.scss';

.agreementText {
  @include font();
}

.agreementText p {
  margin-top: 5px !important;
}

.agreementText a {
  color: map-get($colors, midnight);
}

.agreementText a:hover {
  color: map-get($colors, lush);
}

.modalBodyWrapper {
  @include for-size(desktop) {
    max-height: 350px;
  }
}
