@import 'styles/base.scss';

.block {
  position: relative;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 140px;
  background-color: map-get($colors, white);

  @include for-size(mobile) {
    margin: 0 0 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  > :last-child {
    padding-bottom: 0px;
  }
}

.productInfoContainer {
  @include row(flex-start, center);

  &-right {
    @include row(flex-end, center);
  }
}

.largePhoto {
  position: absolute;
  left: 0;
  top: 70px;
  height: calc(100% - 140px);
  width: 40%;
  background-color: map-get($colors, grey-1) !important;
  background-size: cover !important;
  background-position: center !important;

  &-right {
    left: auto;
    right: 0;
  }

  @include for-size(mobile) {
    height: 360px;
    width: calc(100% - 40px);
    top: 40px;
  }
}

.parallaxPhoto {
  position: absolute;
  left: 10%;
  top: 50px;
  height: calc(80% - 140px);
  width: calc(30% + 20px);
  transition: top 0.1s;

  @include for-size(mobile) {
    height: 420px;
    left: 0;
    top: 20px;
    width: calc(100% - 20px);
  }

  &-right {
    left: auto;
    right: 5%;
    width: calc(35% - 20px);

    @include for-size(mobile) {
      height: 420px;
      left: auto;
      right: 0;
      width: calc(100% - 20px);
      top: 20px;
    }
  }
}

.photoMobileSpacer {
  display: none;
  height: 360px;

  @include for-size(mobile) {
    display: block;
  }
}

.imageTileContainer {
  @include row(flex-start, center);
  flex-wrap: wrap;
  margin-top: 36px;

  @include for-size(mobile) {
    display: none;
  }

  &-mobile {
    @include row(center, center);
    padding: 0 8px;
    margin-bottom: 8px;

    @include for-size(desktop) {
      display: none;
    }
  }

  .imageTile {
    margin-bottom: 8px;
    margin-right: 8px;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    box-shadow: 0px 0px 1px 1px map-get($colors, grey-2);
    cursor: pointer;
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: all 0.2s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--active {
      box-shadow: 0px 0px 0px 2px map-get($colors, lush);
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.infoBox {
  width: 600px;
  min-width: 600px;

  .titleContainer {
    @include row(flex-start, center);
    margin-bottom: 12px;

    @include for-size(mobile) {
      @include column(center, flex-start);
      flex-direction: column-reverse;
    }
  }

  h3 {
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);
    cursor: pointer;
    &:hover {
      color: map-get($colors, lush);
    }
  }

  .rating {
    position: relative;
    margin-left: 20px;

    .star {
      font-size: 25px;
      color: map-get($colors, midnight);
      margin-left: 0px;
    }

    @include for-size(mobile) {
      margin: 8px 0 4px;
    }
  }

  .slogan {
    @include font($size: 18px, $color: midnight, $lineHeight: 22px, $weight: bold);
    margin-bottom: 28px;
  }

  .description {
    margin-bottom: 20px;
    width: 100%;
    @include font($size: 16px, $color: midnight, $lineHeight: 25px);
    @include for-size(mobile) {
      margin-top: 20px;
    }
  }

  ul {
    margin-bottom: 36px;
  }

  li {
    @include row(flex-start, center);
    @include font($size: 16px, $color: midnight, $lineHeight: 25px);
    min-height: 35px;
    margin-bottom: 8px;

    .benefitIcon {
      height: 24px;
      width: 24px;
      margin-right: 14px;
    }
  }

  .priceContainer {
    @include row(flex-start, center);

    .priceBox {
      @include row(flex-start, center);
      position: relative;
      margin-right: 24px;

      &-striked {
        &::after {
          content: '';
          position: absolute;
          top: 11px;
          left: -5px;
          width: calc(100% + 10px);
          height: 1px;
          background-color: map-get($colors, midnight);
        }
      }

      .price {
        @include font($size: 18px, $color: midnight, $lineHeight: 22px);
        margin-right: 8px;
      }

      .priceHighlight {
        @include font($size: 18px, $color: lush, $lineHeight: 22px, $weight: bold);
        margin-right: 8px;
      }

      .label {
        @include font($size: 14px, $color: midnight);
      }

      .labelHighlight {
        @include font($size: 14px, $color: lush);
        border: solid 1px map-get($colors, lush);
        border-radius: 2px;
        padding: 1px 4px;
      }
    }

    @include for-size(mobile) {
      @include row(space-around, center);

      .priceBox {
        margin-right: 0px;
      }
    }
  }

  .colorContainer {
    margin-top: 18px;

    @include for-size(mobile) {
      padding-bottom: 2px;
    }
  }

  .buttonContainer {
    @include row(flex-start, center);
    margin-top: 28px;

    .actionButton {
      margin-right: 8px;
    }

    @include for-size(1100px) {
      width: 100%;
      min-width: 100%;
    }

    @include for-size(mobile) {
      margin-top: 18px;

      .actionButton {
        width: 100%;
        min-width: 0px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @include for-size(1100px) {
    width: 50%;
    min-width: 50%;
  }

  @include for-size(mobile) {
    width: 100%;
    min-width: 100%;

    h3,
    .slogan,
    .description {
      text-align: center;
      width: 100%;
    }
  }
}
