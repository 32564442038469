@import 'styles/base.scss';

.base {
  @include row(stretch, flex-start);

  p {
    @include font($size: 14px, $lineHeight: 22px, $color: grey-12);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.accent {
  p {
    @include font($size: 14px, $lineHeight: 22px, $color: blue-zeel-work, $weight: semibold);
    margin: 0;
  }
}

.expanded {
  p {
    @include font($size: 14px, $lineHeight: 22px);
    overflow: visible;
    white-space: wrap;
    text-overflow: unset;
  }
}

.gutterTop {
  margin-top: 8px;
}

.start {
  margin-right: 6px;
  padding-top: 1px;
  font-size: 21px;
}

.expandLink {
  @include font($size: 14px, $lineHeight: 22px, $color: blue-zeel-work, $weight: semibold);
  cursor: pointer;
  margin-left: 8px;
}