@import 'styles/base.scss';

:global(.dropdown-enter) {
  opacity: 0.01;
  transform: translateY(-5%);
}

:global(.dropdown-enter-active) {
  opacity: 1;
  transform: translateY(0%);
  transition: all $transitionTime, position 0s;
}

:global(.dropdown-exit) {
  opacity: 1;
  transform: translateY(0%);
  transition: all $transitionTime;
}

:global(.dropdown-exit-active) {
  opacity: 0.01;
  transform: translateY(-5%);
}

.dropdownContainer {
  position: relative;

  > button {
    border: none;
    padding: unset;
    background: transparent;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 100000;

    &.right {
      right: 0px;
      left: auto;
    }

    &.fixed {
      position: fixed;
      left: 0;
      transition-duration: 0s !important;
    }

    > div {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-color: map-get($colors, white);
      border-radius: 0px;
      border-top: 1px solid map-get($colors, grey-1);
    }
  }
}
