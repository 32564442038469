@import 'styles/base.scss';

.address {
  width: 100%;
  height: 100px;
  border: solid 1px map-get($colors, grey-3);
  border-radius: 2px;
  padding: 28px 30px;
  float: left;
  cursor: pointer;
  background: map-get($colors, white);
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
    position: absolute;
    border: 0.5px solid transparent;
  }

  @include for-size(mobile) {
    width: 100%;
  }

  &:hover:not(.disabled) {
    border-color: map-get($colors, lush);

    &:before {
      border-color: map-get($colors, lush);
    }
  }

  &.selected {
    border-color: map-get($colors, lush);

    &:before {
      border-color: map-get($colors, lush);
    }
  }

  &.disabled {
    opacity: 0.4;
  }
}

.location {
  width: 100%;
  max-width: calc(100% - 70px);
  line-height: 24px;
  font-size: 16px;
  float: left;
  color: map-get($colors, midnight);
  font-family: map-get($font-family, secondary-medium);
  height: 44px;
  text-align: left;

  span {
    display: block;
    width: 100%;
    float: left;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.actions {
  flex: 0 0 70px;
  line-height: 44px;
  text-align: right;
  position: relative;
  z-index: 1;

  > :first-child {
    margin-left: 0px;
  }

  i {
    font-size: 20px;
    margin-left: 15px;
    width: 20px;
    height: 20px;

    &:hover {
      color: map-get($colors, lush);
      cursor: pointer;
    }
  }
}

.theme {
  &-corporate {
    .address {
      &:hover:not(.disabled) {
        border-color: map-get($colors, teal);
        &:before {
          border-color: map-get($colors, teal);
        }
      }
      &.selected {
        border-color: map-get($colors, teal);
        &:before {
          border-color: map-get($colors, teal);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    &.address {
      &:hover:not(.disabled) {
        border-color: map-get($colors, dusty-blue);
        &:before {
          border-color: map-get($colors, dusty-blue);
        }
      }
      &.selected {
        border-color: map-get($colors, dusty-blue);
        &:before {
          border-color: map-get($colors, dusty-blue);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    &.address {
      &:hover:not(.disabled) {
        border-color: map-get($colors, health);
        &:before {
          border-color: map-get($colors, health);
        }
      }
      &.selected {
        border-color: map-get($colors, health);
        &:before {
          border-color: map-get($colors, health);
        }
      }
    }
    .actions i:hover {
      color: map-get($colors, health);
    }
  }
}
