@import 'styles/base.scss';

.base {
  @include row;
  margin: 12px 0;
}

.carousel {
  @include for-size(desktop) {
    display: none !important;
  }
  @include for-size(mobile) {
    display: block;
  }
}

.container {
  @include row(space-around, flex-start);
  min-width: 100%;

  @include for-size(mobile) {
    display: none;
  }

  &-wrap {
    display: block !important;
  }

  &-wrapMobile {
    @include for-size(mobile) {
      display: block !important;
    }
  }
}

.icon {
  margin-right: 8px;
  min-width: 34px;
  position: relative;
}

.itemCarousel {
  @include row(flex-start, flex-start);
  @include font();

  p {
    margin-top: 8px;

    @include for-size(mobile) {
      font-size: 15px;
    }

    @include for-size(500px) {
      font-size: 15px;
    }

    @include for-size(360px) {
      font-size: 12px;
    }
    @include for-size(310px) {
      font-size: 11px;
    }
  }
}

.item {
  @include row(flex-start, flex-start);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  @include font($size: 14px, $color: midnight, $lineHeight: 24px);

  p {
    margin-top: 3px;
  }
}
