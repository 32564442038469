@import 'styles/base.scss';

.layout {
  width: 100%;
}
.loader {
  top: 20px;
}

.errorBox {
  @include row(center, center);
  font-family: map-get($font-family, secondary-bold);
  color: white;
  opacity: 0.8;
  position: relative;
  background-color: map-get($colors, error);
  top: 0px;
  padding: 10px 12px;
  text-align: center;

  @include for-size(mobile) {
    top: 45px;
  }
}

.flowContainer {
  width: 762px;
  padding: 40px 24px 24px 24px;
  margin: 0 auto;
  color: map-get($colors, midnight);

  &--full {
    width: 100%;
    padding: 0px;

    @include for-size(mobile) {
      width: 100%;
      padding: 0px !important;
    }
  }

  &--ultraWide {
    width: 1150px;
  }

  &--wide {
    width: 940px;
  }

  &--small {
    width: 488px;
  }

  &--narrow {
    width: 390px;
  }

  @include for-size(mobile) {
    width: 100%;
    padding: 40px 16px 16px 16px;
  }

  // Header
  :global(.flow-header) {
    @include for-size(mobile) {
      padding-top: 0px;
    }

    :global(.headerIcon) {
      margin-bottom: 24px;
    }
    // & > svg {
    //   margin-bottom: 24px;
    // }

    h1 {
      @include font($size: 24px, $lineHeight: 36px, $weight: bold, $color: midnight);
      width: 100%;
      text-align: center;
      margin-bottom: 6px;

      @include for-size(mobile) {
        font-size: 24px;
      }
    }

    h3 {
      @include font($size: 16px, $lineHeight: 24px, $color: midnight);
      margin-top: 7px;
      width: 100%;
      text-align: center;
    }

    .underline {
      text-decoration: underline;

      &:hover {
        color: map-get($colors, lush);
        cursor: pointer;
      }
    }
  }

  // Body
  :global(.flow-body) {
    width: 100%;
    margin-top: 50px;

    @include for-size(mobile) {
      flex-direction: column;
      margin-bottom: 83px;
      margin-top: 30px;
    }

    h3 {
      margin-top: 7px;
      font-family: map-get($font-family, secondary);
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-align: center;
      color: map-get($colors, midnight);
    }

    &.center {
      @include row(center, flex-start);
    }
  }

  // Footer
  :global(.flow-footer) {
    margin-top: 50px;
    text-align: center;
    p {
      font-family: map-get($font-family, secondary);
    }

    @include for-size(mobile) {
      position: fixed;
      bottom: 0px;
      z-index: 5;
      background: map-get($colors, white);
      left: 0px;
      margin-top: 30px;
      margin-bottom: 0px;
      padding: 16px;
      width: 100%;
      border-top: 1px solid map-get($colors, grey-3);
    }

    button {
      display: inline-block;
      width: 345px;

      @include for-size(mobile) {
        width: calc(100%);
      }
    }
  }

  .footerFiller {
    background-color: red;
    @include for-size(desktop) {
      display: none;
    }
    @include for-size(mobile) {
      display: block;
    }
  }

  :global(.flow-button) {
    display: inline-block;
    width: 345px;

    @include for-size(mobile) {
      width: calc(100%);
    }
  }

  :global(.flow-footer-block) {
    color: map-get($colors, midnight);
    background-color: map-get($colors, natural);
    padding: 40px 0 40px;

    .inner {
      width: 1040px;
      margin: 0 auto;
    }

    h1 {
      font-family: map-get($font-family, secondary-medium);
      font-size: 28px;
      line-height: 32px;
      width: 100%;
      text-align: center;
      color: map-get($colors, midnight);
      margin-bottom: 24px;

      @include for-size(mobile) {
        font-size: 24px;
      }
    }

    h3 {
      margin-top: 7px;
      font-family: map-get($font-family, secondary);
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-align: center;
      color: map-get($colors, midnight);
    }

    @include for-size(mobile) {
      width: 100%;
      padding: 40px 0 130px;
    }
  }
}
