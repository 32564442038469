@import 'styles/base.scss';

.block {
  min-height: 240px;
  background-image: map-get($assets, full-width-greenery-blurred);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  text-align: center;
  padding: 56px 24px;

  @include for-size(mobile) {
    padding: 40px 24px;
  }

  @include column(center, center);

  h3 {
    position: relative;
    width: 100%;
    margin-bottom: 11px;
    @include font($size: 32px, $color: white, $lineHeight: 38px, $family: primary);
    @include for-size(mobile) {
      font-size: 28px;
    }
  }

  h5 {
    width: 590px;
    margin-top: 10px;
    @include font($size: 16px, $color: white, $lineHeight: 24px, $weight: light);

    @include for-size(650px) {
      width: 100%;
    }
  }

  .button {
    margin-top: 33px;
    width: 203px;
  }

  .rating {
    margin-right: 10px;
  }
}

.ratingBlock {
  display: inline-block;
}
.ratingBlockStar {
  color: map-get($colors, yellow);
}
