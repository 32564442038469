@import 'components/modals/templates/Modal/style.module.scss';

.customHeader {
  margin-top: 0px;
}

.customBody {
  margin-bottom: 0px;
}

.input {
  margin-bottom: 20px !important;
}
