@import 'styles/base.scss';

.verticalTabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  -webkit-box-shadow: 0px 9px 16px 0px rgba(37, 44, 49, 0.1);
  -moz-box-shadow: 0px 9px 16px 0px rgba(37, 44, 49, 0.1);
  box-shadow: 0px 9px 16px 0px rgba(37, 44, 49, 0.1);

  .titles {
    width: 35%;
    display: flex;
    flex-direction: column;

    > .title {
      flex-grow: 1;
      @include font($family: secondary, $size: 24px);
      padding: 37px;
      background: map-get($colors, lush-lighter);
      text-align: center;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      position: relative;

      &.activeTitle {
        @include font($family: secondary-bold, $size: 24px);
        background: white;

        .textUnderline {
          border-bottom: 3px solid map-get($colors, green-1);
        }
      }
      &:not(.activeTitle) {
        border-bottom: 1px solid map-get($colors, natural);
      }

      &:hover .textUnderline {
        border-bottom: 3px solid map-get($colors, green-1);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .content {
    width: 65%;
    padding: 56px;
    font-size: 19px;
    line-height: 1.4em;
    text-align: left;
  }

  .hideContent {
    display: none;
  }
}
