@import 'styles/base.scss';

.star {
  color: map-get($colors, lush);
  margin-left: 4px;
  font-size: 35px;
  position: relative;
  top: 1px;
}

.rating-label {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.theme {
  &-corporate {
    .star {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    .star {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .star {
      color: map-get($colors, health);
    }
  }
}
