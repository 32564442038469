@import 'styles/base.scss';

.base {
  @include row(flex-start, flex-end);
  position: relative;
  border-bottom: solid 1px map-get($colors, grey-2);
  outline: none;
  overflow-y: hidden;
}

.disabled {
  .tab {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
  .activeLine {
    opacity: 0.5;
  }
}

.scrollable {
  &.base {
    overflow: auto;
    overflow-y: hidden;
  }
  .tab {
    flex-shrink: 0;
    width: auto;
    @include for-size(mobile) {
      padding: 0 8px;
    }
  }
}

.tab {
  @include font($family: secondary, $size: 16px, $weight: regular, $color: midnight);
  height: 50px;
  line-height: 50px;
  padding: 0 14px;
  text-align: center;
  letter-spacing: 0.2px;
  border-bottom: solid 3px transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.25s;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  @include for-size(mobile) {
    width: 100%;
    padding: 0 4px;
  }

  &--active {
    @include font($family: secondary, $size: 16px, $weight: bold);
    letter-spacing: 0.4px;

    &:hover {
      background-color: transparent;
    }
  }

  .label {
    @include for-size(mobile) {
      text-align: center;
      display: none;
    }
  }

  .labelMobile {
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    @include for-size(mobile) {
      display: block;
    }
  }

  @include for-size(mobile) {
    overflow: hidden;
  }
}

.note {
  @include font($size: 11px, $color: lush);
  display: inline-block;
  position: relative;
  top: -1px;
  line-height: 12px;
  border-radius: 2px;
  border: solid 1px map-get($colors, lush);
  padding: 1px 4px;
  margin-left: 7px;
  letter-spacing: 0.5px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &--show {
    opacity: 1;
    transform: scale(1);
  }
}

.activeLine {
  position: absolute;
  bottom: -1px; // to hide container bottom-border
  height: 3px;
  background-color: map-get($colors, lush);
  transition: all 0s;

  &--animated {
    transition: all 0.25s ease;
  }
}

.theme {
  &-corporate {
    .note {
      @include font($size: 11px, $color: teal);
      border: solid 1px map-get($colors, teal);
    }
    .activeLine {
      background-color: map-get($colors, teal);
    }
  }
  &-medical {
    .note {
      @include font($size: 11px, $color: dusty-blue);
      border: solid 1px map-get($colors, dusty-blue);
    }
    .activeLine {
      background-color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .note {
      @include font($size: 11px, $color: health);
      border: solid 1px map-get($colors, health);
    }
    .activeLine {
      background-color: map-get($colors, health);
    }
  }
}
