@import 'styles/base.scss';

.infoBlock {
  position: relative;

  &-content {
    @extend .glContainer;
    @include row(flex-start, center);
    width: 100%;
    padding: 0px 80px;
    margin: 0 auto;
    position: relative;
    min-height: 452px;

    .info {
      width: 50%;
      margin-left: auto;
      margin-right: unset;
      padding-right: unset;
      padding-left: 110px;
      padding-top: 80px;
      padding-bottom: 80px;

      h2 {
        @include font($family: primary, $size: 32px, $lineHeight: 42px);
        margin-bottom: 18px;
      }

      p {
        @include font($size: 16px, $lineHeight: 24px);

        strong {
          @include font($size: 16px, $lineHeight: 24px, $family: secondary-medium);
        }

        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      @include for-size(1550px) {
        padding-left: 80px;
      }
    }
  }

  .image {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 50%;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  @include for-size(mobile) {
    display: block;

    &-content {
      min-height: auto;
      padding: 0px 24px;
    }

    .info {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 32px;
      padding-bottom: 56px;
    }

    .image {
      position: relative;
      height: 75vw;
      width: 100%;
    }
  }
}
