@import 'styles/base.scss';

.base {
  b {
    @include font($weight: semibold);
  }
}
.cardsContainer {
  @include column(stretch, flex-start);
  gap: 8px;
}

.card {
  position: relative;
}

.clientLogo {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50px;
  height: calc(100% - 16px);
  margin: 8px 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
