@import 'styles/base.scss';

.sliderContainer {
  @include column(flex-start, flex-start);
  width: 100%;
  position: relative;
  user-select: none;
}

.sliderDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.domainContainer {
  width: 100%;
  @include row(space-between, center);
}

.edgeLabel {
  text-align: center;
  white-space: nowrap;
  @include font($size: 16px, $color: grey-4);

  @include for-size(mobile) {
    font-size: 14px;
  }
}

.handle {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  background-color: map-get($colors, lush);
  border: 1px solid map-get($colors, grey-2);
}

.handleBackground {
  position: absolute;
  transform: translate(-50%, -50%);
  --webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 28px;
  height: 42px;
  cursor: pointer;
  background-color: none;
}

.railInner {
  position: absolute;
  width: 100%;
  height: 4px;
  transform: translate(0%, -50%);
  border-radius: 2px;
  pointer-events: none;
  background-color: map-get($colors, grey-2);
}

.railInnerMultiple {
  background-color: map-get($colors, grey-2);
}

.railOuter {
  position: absolute;
  width: 100%;
  height: 12px;
  transform: translate(0%, -50%);
  border-radius: 20px;
  cursor: pointer;
}

.track {
  position: absolute;
  transform: translate(0%, -50%);
  height: 4px;
  z-index: 1;
  background-color: map-get($colors, lush);
  border-radius: 2px;
  cursor: pointer;
}

.tickLine {
  position: absolute;
  margin-top: 6px;
  width: 1px;
  height: 5px;
  background-color: map-get($colors, grey-2);
}

.tickValue {
  position: absolute;
  margin-top: 13px;
  font-size: 10px;
  color: map-get($colors, grey-5);
  text-align: center;
}

.theme {
  &-corporate {
    .handle {
      background-color: map-get($colors, teal);
    }
    .track {
      background-color: map-get($colors, teal);
    }
  }
  &-medical {
    .handle {
      background-color: map-get($colors, dusty-blue);
    }
    .track {
      background-color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .handle {
      background-color: map-get($colors, health);
    }
    .track {
      background-color: map-get($colors, health);
    }
  }
}
