@import 'components/modals/templates/Modal/style.module.scss';

.modal {
  width: 782px;
  padding: 0;

  @include for-size(mobile) {
    padding: 0 !important;
  }
}

.bottomSpacer {
  display: none;
  height: 150px;
  min-height: 150px;
  @include for-size(mobile) {
    display: block;
  }
}

.carousel {
  height: 280px;

  &-slide {
    width: 100%;
    height: 280px;
    background-size: cover !important;
    background-position: center !important;
  }

  @include for-size(mobile) {
    height: 90vw !important;

    &-slide {
      height: 90vw !important;
    }
  }

  :global(.slick-dots) {
    li {
      top: -36px;
      margin: 0;

      button {
        padding: 0;

        &::before {
          color: white !important;
        }
      }
    }
  }
}

.topInfoContainer {
  @include row(center, flex-start);
  background-color: map-get($colors, natural);

  @include for-size(mobile) {
    text-align: center;
    border-bottom: solid 1px map-get($colors, grey-1);
    display: block;
  }

  & > div {
    width: 100%;
    padding: 32px;

    @include for-size(mobile) {
      padding: 16px;
    }
  }

  .titleContainer {
    @include row(flex-start, center);
    margin-bottom: 8px;

    @include for-size(mobile) {
      @include column(center, flex-start);
      flex-direction: column-reverse;
    }
  }

  h1 {
    @include font($size: 24px, $lineHeight: 36px, $color: midnight, $weight: bold);
  }

  .rating {
    position: relative;
    margin-left: 20px;

    .star {
      font-size: 25px;
      color: map-get($colors, midnight);
      margin-left: 0px;
    }

    @include for-size(mobile) {
      margin: 8px 0 4px;
    }
  }

  p {
    @include font($size: 18px, $lineHeight: 22px, $color: midnight);
    text-align: left;
    margin: 0;

    @include for-size(mobile) {
      text-align: center;
    }
  }

  .topInfo {
    .colorTiles {
      margin-top: 20px;

      @include for-size(desktop) {
        display: none;
      }
    }
  }

  .addCartContainer {
    @include for-size(mobile) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      background-color: map-get($colors, white);
      border-top: solid 1px map-get($colors, grey-1);
      z-index: 1;
    }

    .colorTiles {
      margin-bottom: 20px;

      @include for-size(mobile) {
        display: none;
      }
    }
  }

  .priceContainer {
    @include row(space-between, center);
    margin-top: 10px;

    .priceBox {
      @include row(flex-start, center);
      position: relative;

      &-striked {
        &::after {
          content: '';
          position: absolute;
          top: 11px;
          left: -5px;
          width: calc(100% + 10px);
          height: 1px;
          background-color: map-get($colors, midnight);
        }
      }

      .price {
        @include font($size: 18px, $color: midnight, $lineHeight: 22px);
        margin-right: 8px;
        width: auto;
      }

      .priceHighlight {
        @include font($size: 18px, $color: lush, $lineHeight: 22px, $weight: bold);
        margin-right: 8px;
        width: auto;
      }

      .label {
        @include font($size: 14px, $color: midnight);
      }

      .labelHighlight {
        @include font($size: 14px, $color: lush);
        border: solid 1px map-get($colors, lush);
        border-radius: 2px;
        padding: 1px 4px;
      }
    }

    @include for-size(mobile) {
      @include row(space-around, center);

      .priceBox {
        margin-right: 0px;
      }
    }
  }
}

.zeelTakeContainer {
  @include row(center, center);
  padding: 32px 0;
  background-color: rgba(map-get($colors, lush), 0.05);
  border-top: solid 1px rgba(map-get($colors, lush), 0.5);
  border-bottom: solid 1px rgba(map-get($colors, lush), 0.5);

  @include for-size(mobile) {
    display: block;
  }

  & > div {
    width: 100%;
    padding: 0 32px;

    @include for-size(mobile) {
      padding: 0 16px;
    }
  }

  p {
    @include font($size: 16px, $lineHeight: 25px);
    text-align: left;
    margin: 0;
  }

  h2 {
    @include font($size: 20px, $lineHeight: 22px, $weight: bold);
    text-align: left;
    margin-bottom: 16px;
  }

  h3 {
    @include font($size: 14px, $lineHeight: 25px, $weight: bold);
    margin-bottom: 10px;

    @include for-size(mobile) {
      margin-top: 20px;
    }
  }

  li {
    @include row(flex-start, center);
    @include font($size: 14px, $lineHeight: 25px);
    min-height: 35px;
    margin-bottom: 8px;

    .bestForIcon {
      height: 24px;
      width: 24px;
      margin-right: 14px;
      color: map-get($colors, lush);
    }
  }
}

.detailsContainer {
  @include row(center, flex-start);

  @include for-size(mobile) {
    //@include column(center, flex-start);
    display: block;
  }

  & > div {
    width: 100%;
    padding: 32px;

    @include for-size(mobile) {
      display: none;
    }
  }

  h2 {
    @include font($size: 20px, $lineHeight: 22px, $weight: bold);
    text-align: left;
    margin-bottom: 16px;
  }

  h3 {
    @include font($size: 16px, $lineHeight: 25px, $weight: bold);
  }

  p + h3 {
    margin-top: 32px;
  }

  p + ul {
    margin-top: 24px;
  }

  li,
  p {
    @include font($size: 16px, $lineHeight: 25px);
    text-align: left;
    margin: 0;
  }

  .specRow {
    @include row(flex-start, flex-start);
    p:nth-of-type(1) {
      width: 180px;
    }
  }

  .box {
    margin-bottom: 32px;

    ul {
      list-style: disc;
      padding-left: 18px;
    }

    @include for-size(mobile) {
      display: none;
    }
  }

  .togglableBox {
    position: relative;
    overflow: hidden;
    padding: 16px;
    max-height: 55px;
    height: 55px;
    border-bottom: solid 1px map-get($colors, grey-1);
    transition: all 0.5s ease;

    @include for-size(desktop) {
      display: none;
    }

    i {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 20px;
      color: map-get($colors, midnight);
      transition: transform 0.2s ease;
    }

    &--open {
      max-height: 1000px;
      height: auto;

      i {
        transform: rotate(180deg);
      }
    }

    ul {
      list-style: disc;
      padding-left: 18px;
    }

    @include for-size(mobile) {
      display: block;
    }
  }
}

.videosContainer {
  padding: 16px 0 40px;
  background-color: map-get($colors, grey-1);
  overflow: hidden;
  min-height: 256px;

  h2 {
    @include font($size: 20px, $lineHeight: 22px, $weight: bold);
    text-align: left;
    padding-left: 32px;
    margin-bottom: 16px;

    @include for-size(mobile) {
      padding-left: 16px;
    }
  }

  .videoCarousel {
    &-slide {
      position: relative;
      margin-left: 24px;
      height: 158px;
      max-width: 289px;
      width: 289px;
      background-size: cover !important;
      background-position: center !important;
      cursor: pointer;
      filter: brightness(1);
      transition: all 0.1s;

      &-overlay {
        @include row(center, center);
        border-radius: 50%;
        width: 54px;
        height: 54px;
        border: solid 1px map-get($colors, white);
        position: absolute;
        left: calc(50% - 27px);
        top: calc(50% - 27px);

        &-arrow {
          position: relative;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 20px solid map-get($colors, white);
          left: 2px;
        }
      }

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  :global(.slick-arrow) {
    position: absolute !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    z-index: 1;
    color: map-get($colors, grey-5) !important;
  }

  :global(.slick-next) {
    &:before {
      content: '\e830' !important;
      font: normal normal normal 14px/1 CustomIconsVF !important;
      color: map-get($colors, grey-5) !important;
      font-size: 40px !important;
    }
    right: 0px;
    top: 69px;
  }

  :global(.slick-prev) {
    &:before {
      content: '\e82f' !important;
      font: normal normal normal 14px/1 CustomIconsVF !important;
      color: map-get($colors, grey-5) !important;
      font-size: 40px !important;
    }
    left: -9px;
    top: 69px;
  }
}
