@import 'styles/base.scss';

.carousel {
  width: 100%;
}

.showOverflow {
  div[class='slick-list'] {
    overflow: visible;
  }
}

.showMobileOverflow {
  @include for-size(mobile) {
    div[class='slick-list'] {
      overflow: visible;
    }
  }
}

:global(.slick-dots) {
  li {
    margin: 0;
    width: 15px !important;
    height: 15px !important;
    padding: 0;

    button {
      width: 15px !important;
      height: 15px !important;
      padding: 0;

      &::before {
        width: 15px !important;
        height: 15px !important;
        font-size: 8px !important;

        @include for-size(mobile) {
          font-size: 10px !important;
        }
      }
    }
  }
}

.slide {
  height: 100%;
  outline: none;
}
