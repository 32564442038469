@import 'styles/base.scss';

.pill {
  @include row(flex-start, center);
  gap: 16px;
  border: 1px solid map-get($colors, grey-3);
  border-radius: 80px;
  padding: 8px 24px 8px 8px;
  text-align: left;
  width: fit-content;

  .label {
    @include font(
      $size: 18px,
      $lineHeight: 24px,
      $letterSpacing: 0.3px,
      $family: secondary,
      $weight: bold,
      $color: actionable-green
    );
  }

  &Clickable {
    cursor: pointer;
    &:hover {
      background-color: map-get($colors, alternative-green-light);
      border-color: map-get($colors, grey-4);
    }
  }

  .icon {
    @include row(center, center);
    min-width: 56px;
    height: 56px;
    background: #e3eee3;
    border-radius: 50%;

    svg {
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }

    img {
      min-width: 56px;
      max-width: 56px;
      height: 56px;
      border-radius: 50%;
    }
  }

  @include for-size(mobile) {
    gap: 4px;
    padding: 6px 12px 6px 6px;

    .label {
      @include font(
        $size: 14px,
        $lineHeight: 17px,
        $letterSpacing: 0.3px,
        $family: secondary,
        $weight: bold,
        $color: actionable-green
      );
    }

    .icon {
      min-width: 40px;
      height: 40px;

      svg {
        width: 38px;
        height: 38px;
      }

      img {
        min-width: 40px;
        max-width: 40px;
        height: 40px;
      }
    }
  }
}
