@import 'styles/base.scss';

.modalityCard {
  width: 320px;
  margin: 0 5px 5px; // bottom margin to show box-shadow

  @include for-size(1040px) {
    margin: 0 5px;
    width: calc(100% - 10px);
  }

  @include for-size(mobile) {
    margin: 0 5px;
    width: calc(100% - 10px);
  }
}

.shrink {
  padding: 27px 22px 48px;
}
