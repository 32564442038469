@import 'components/modals/templates/Modal/style.module.scss';

.base {
  width: 660px;

  b {
    @include font($weight: bold);
  }

  p {
    margin-top: 16px;
  }

  ul {
    margin-top: 0;
    margin-bottom: 24px;

    li {
      margin-bottom: 8px;
    }
  }


}