@import 'styles/base.scss';

.section {
  h1 {
    font-size: 42px;
    margin-bottom: 25px;
  }
  .carousel {
    button[class*='slick-arrow '] {
      &:before {
        display: inline-block;
        font: normal normal normal 14px/1 CustomIconsVF !important;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 50px !important;
        color: map-get($colors, grey-5) !important;
        line-height: 30px !important;

        &:hover {
          color: map-get($colors, midnight) !important;
        }
      }

      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    button[class*='slick-disabled'] {
      display: none !important;
    }

    button[class*='slick-next'] {
      &:before {
        content: '\e830' !important;
      }

      right: -35px;

      @include for-size(1240px) {
        right: -10px;
      }
      @include for-size(mobile) {
        right: -19px;
      }
    }

    button[class*='slick-prev'] {
      &:before {
        content: '\e82f' !important;
      }

      left: -50px;

      @include for-size(1240px) {
        left: -20px;
      }
      @include for-size(mobile) {
        left: -36px;
      }
    }

    .insideSlide {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .image {
        height: auto;
        width: auto;
        max-height: 365px;
        max-width: 365px;
        margin: 0 auto;
      }
      .info {
        max-width: 600px;
        text-align: left;
        margin-left: 20px;

        h2 {
          font-size: 28px;
          margin-bottom: 5px;
        }
        .location {
          @include font($family: secondary-medium, $size: 14px, $color: grey-5);
          text-transform: uppercase;
          margin-bottom: 25px;

          .license {
            @include font($family: secondary-bold);
          }
        }
        .description {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .quote {
          @include font($family: secondary-semibold, $size: 18px);
          margin-bottom: 20px;
        }
      }
    }
  }
  @include for-size(mobile) {
    h1 {
      font-size: 32px;
    }

    .carousel {
      .insideSlide {
        flex-direction: column;

        .image {
          max-width: 250px;
          max-height: 250px;
        }
        .info {
          text-align: center;
          width: auto;
          margin: 0;
          max-width: 100vw;

          .description,
          .quote {
            font-size: 16px;
          }
        }
      }
    }
  }
}
