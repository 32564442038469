@import 'styles/base.scss';

.layout {
  width: 100%;
}

.errorBox {
  @include row(center, center);
  @include font($weight: bold);
  color: white;
  opacity: 0.8;
  position: relative;
  background-color: map-get($colors, error);
  top: 0px;
  padding: 10px 12px;
  text-align: center;

  @include for-size(mobile) {
    top: 45px;
  }

  &--withProgressBar {
    top: 40px;
  }
}

.elasticCover {
  &::after {
    content: '';
    background-color: map-get($colors, natural);
    width: 100vw;
    height: 250px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

.content {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  @include for-size(mobile) {
    min-height: calc(100vh - 108px);
  }
}

.error {
  padding: 25px 0px;
}

.loader {
  font-size: 40px !important;
  color: map-get($colors, lush);
}

.partialLoader {
  @include row(center, center);
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(map-get($colors, white), 0.5);

  &--full {
    background-color: rgba(map-get($colors, white), 1);
  }

  .loadingIcon {
    font-size: 40px;
    color: map-get($colors, lush);
  }
}

.theme {
  &-corporate {
    .loader {
      color: map-get($colors, teal);
    }
    .partialLoader .loadingIcon {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    .loader {
      color: map-get($colors, dusty-blue);
    }
    .partialLoader .loadingIcon {
      color: map-get($colors, dusty-blue);
    }
  }
}
