@import 'styles/base.scss';

.base {

}

.newLocationCard {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  padding: 16px;
  border: 1px solid map-get($colors, grey-3);
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &--none {
    color: map-get($colors, grey-4);
    font-style: italic;
  }

  .editIcon {
    position: absolute;
    right: 8px;
    top: 14px;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.priceLineItem {
  @include row(space-between, center);
  gap: 16px;
  padding: 13px 0;
  border-top: 1px solid map-get($colors, grey-2);
  border-bottom: 1px solid map-get($colors, grey-2);

  .price {
    font-weight: bold;
  }
}

.addedButton {
  filter: contrast(120%) brightness(120%);
}

.editLocationIcon {
  color: map-get($colors, blue-zeel-work) !important;
}

.requestSummary {
  opacity: 1;
  transition: all 0.75s ease;
  max-height: 200px;

  .lineItem {
    @include font($size: 14px, $lineHeight: 24px, $weight: regular);
    padding: 6px 0;
    border-top: 1px solid map-get($colors, grey-2);
    border-bottom: 1px solid map-get($colors, grey-2);
    margin-bottom: 24px;
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    max-height: 0px;
  }
}