@import 'styles/base.scss';

.headerFake {
  width: 100%;
}

.header {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  background: map-get($colors, white);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.headerTop {
  @include row(space-between, center);
  width: 100%;
  height: 28px;
  padding: 0px 25px 0px 20px;
  background: map-get($colors, natural);
  position: relative;
  z-index: 1000;

  @include for-size(mobile) {
    display: none;
  }

  &-left {
    display: inline-flex;

    .tab {
      @include font($size: 13px, $color: midnight, $lineHeight: 28px, $weight: medium);
      padding: 0px 12px;
      outline-offset: -1px;

      &--active {
        @include font($size: 13px, $weight: bold);
        background-color: map-get($colors, white);
      }
    }
  }

  &-right {
    display: inline-flex;

    .link {
      @include font($size: 13px, $color: midnight, $lineHeight: 18px, $weight: medium);
      margin: 0;
      margin-left: 25px;
      height: 18px;
      user-select: none;
      outline-offset: -1px;
      margin-bottom: 4px;

      span {
        height: 18px;
        line-height: 18px;
        display: block;
      }

      &:hover {
        color: map-get($colors, lush);
      }
    }
  }
}

.headerNav {
  @include row(stretch, center);
  width: 100%;
  height: 80px;
  background: map-get($colors, white);
  position: relative;
  z-index: 1;

  @include for-size(mobile) {
    height: 65px;
  }
}

.completionBar {
  position: absolute;
  top: 100%;
}

.logo {
  margin-left: 25px;
  min-width: 88px;
  height: 80px;
  background-image: map-get($assets, logo);
  background-size: 88px 36px;
  background-repeat: no-repeat;
  background-position: 50% 43%;
  background-clip: content-box;
  outline-offset: -1px;

  @include for-size(mobile) {
    margin-left: 0px;
    position: absolute;
    height: 63px;
    left: 50%;
    transform: translateX(-50%);
    background-size: 68px 28px;
    background-position: 50% 50%;
  }
}

.navItems {
  @include row(flex-start, center);
  width: 100%;
  height: 100%;
  margin-left: 40px;
  position: relative;

  @include for-size(1167px) {
    margin-left: 10px;
  }
  @include for-size(mobile) {
    display: none;
  }

  > :last-child {
    margin-right: 0px !important;
  }

  &-underneath {
    @include for-size(mobile) {
      display: block;
    }
    @include for-size(desktop) {
      display: none;
    }
    @include row(flex-start, center);
    position: relative;
    height: 45px;
    margin: 0px;
    width: 100%;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid map-get($colors, grey-2);

    &-inner {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      white-space: nowrap;

      > :last-child {
        margin-right: 0px;
      }
    }
  }
}

.spaNavLinkContainer {
  width: 100%;
  @include row(flex-end, center);
  padding-right: 50px;
}

.link,
.linkBusiness,
.linkSpa {
  @include font($size: 16px, $color: midnight, $lineHeight: 19px, $weight: semibold);
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 31px;
  height: 19px;
  margin-right: 46px;
  cursor: pointer;
  user-select: none;

  &--active {
    color: map-get($colors, lush) !important;
  }

  span {
    font-family: map-get($font-family, secondary-semibold);
  }

  @include for-size(1167px) {
    font-size: 16px;
    margin-right: 20px;
  }

  @include for-size(966px) {
    font-size: 14px;
  }

  @include for-size(890px) {
    font-size: 12px;
  }

  @include for-size(832px) {
    font-size: 10px;
  }

  @include for-size(mobile) {
    font-size: 12px;
    height: 45px;
    line-height: 45px;
    padding: 0px 15px;
    display: inline-block;
    margin: 0px;
  }

  i {
    top: 1px;
    position: relative;
    font-weight: 600;
  }
}

.linkServices {
  user-select: none;
}

.navButtonContainer,
.navButtonContainer--hidden {
  position: absolute;
  right: 80px;
  top: calc(50% - 25px);
  opacity: 1;
  pointer-events: all;
  transition: all 0.25s ease;

  @include for-size(mobile) {
    display: none;
  }
}

.navButtonContainer--hidden {
  opacity: 0;
  pointer-events: none;
}

.navIcon {
  font-size: 32px;
  line-height: 32px;
  cursor: pointer;
  color: map-get($colors, midnight);

  &:hover {
    color: map-get($colors, lush);
  }

  @include for-size(mobile) {
    font-size: 26px;
  }
}

.navIconsContainer {
  &-left {
    // the left container is displayed block by default to flow in the header properly
    @include row(flex-start, center);

    &-desktop {
      @include for-size(mobile) {
        display: none;
      }
    }

    &-mobile {
      @include for-size(desktop) {
        display: none;
      }
    }

    .navIcon {
      margin-left: 25px;
      @include for-size(mobile) {
        margin-left: 18px;
      }
    }
  }

  &-right {
    @include row(flex-end, center);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    &-desktop {
      @include for-size(mobile) {
        display: none;
      }
    }

    &-mobile {
      @include for-size(desktop) {
        display: none;
      }
    }

    .navIcon {
      margin-right: 25px;
      @include for-size(mobile) {
        margin-right: 12px;
      }
    }
  }
}

.cartIconWrapper {
  position: relative;

  .badge {
    @include font($size: 10px, $color: white, $weight: bold);
    @include row(center, center);
    width: 16px;
    height: 16px;
    position: absolute;
    top: -4px;
    right: 18px;
    background-color: map-get($colors, lush);
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.2s ease;
    opacity: 1;

    @include for-size(mobile) {
      right: 11px;
    }
  }
}

// GATED NAV OVERRIDES
.gated {
  .navItems,
  .headerTop {
    display: none;
  }

  .navIconsContainer-left {
    // floating left to allow for centered logo
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .logo {
    width: 112px;
    height: 100%;
    margin: 0 auto;
    background-size: contain;
    background-position: center;

    @include for-size(mobile) {
      width: auto;
      height: 25px;
    }
  }
}

// SIMPLE NAV OVERRIDES
.simple {
  .navItems,
  .headerTop {
    display: none;
  }

  .navIconsContainer-left {
    // floating left to allow for centered logo
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .logo {
    margin: 0 auto;
    background-size: contain;
    background-position: center;

    @include for-size(mobile) {
      width: auto;
      height: 25px;
    }
  }
}

// ALL NAV DROPDOWNS
.dropdownAccount {
  left: auto !important;
  right: 0px;
  min-width: 160px;
  padding: 5px 0;
  font-size: 16px;
  text-align: left;
  background-clip: padding-box;

  a {
    color: map-get($colors, midnight);
    font-size: 16px;
    padding: 3px 20px 3px 20px;
    display: block;
    clear: both;
    height: 36px;
    font-weight: normal;
    line-height: 32px;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background-color: map-get($colors, grey-1);
    }
  }
}

.dropdownBusiness {
  @include row(center, center);
  margin-left: -6px;
  height: 300px;
  color: map-get($colors, midnight);
  margin-top: 30px;

  @include for-size(1110px) {
    margin-left: -147px;
  }

  @include for-size(991px) {
    margin-left: -230px;
    width: 669px;
  }

  @include for-size(mobile) {
    @include column(center, flex-start);
    width: 100%;
    left: 0px !important;
    margin: 0px;
    right: 0px;
    height: auto;
    top: 108px !important;
    position: fixed !important;
    overflow: hidden;
    padding: 11px 0px;
  }

  .businessBox {
    padding: 21px 39px 10px 30px;
    width: 249px;
    height: 100%;
    font-family: map-get($font-family, secondary);

    @include for-size(991px) {
      padding: 21px 21px 10px 21px;
      width: 223px;
    }

    @include for-size(mobile) {
      @include row();
      padding: 10px 21px 10px 21px;
      width: 100%;
      min-height: 127px;
    }

    &:hover {
      background-color: map-get($colors, grey-1);
    }

    img {
      width: 180px;
      height: 120px;
      background-color: map-get($colors, grey-2);

      @include for-size(mobile) {
        margin-right: 15px;
        width: 150px;
        height: 100px;
      }

      @include for-size(370px) {
        width: 120px;
        height: 80px;
      }

      @include for-size(300px) {
        width: 100px;
        height: 67px;
      }
    }

    h5 {
      margin-top: 12.5px;
      margin-bottom: 12.5px;
      text-transform: none;
      @include font($size: 17px, $color: midnight, $weight: medium);

      @include for-size(mobile) {
        margin-top: 6px;
        height: 16px;
        margin-bottom: 6px;
        line-height: 16px;
      }

      sup {
        vertical-align: super;
        font-size: 10px;
        position: absolute;
        margin-top: -5px;
      }
    }

    p {
      font-weight: 400;
      color: map-get($colors, midnight);
      text-transform: none;
      font-size: 12px;
      white-space: normal;
      text-align: left;

      @include for-size(mobile) {
        width: calc(100%);
        line-height: 15px;
      }

      @include for-size(370px) {
        width: calc(100% - 120px - 15px);
      }

      @include for-size(300px) {
        width: calc(100% - 100px - 15px);
      }
    }
  }
}

.dropdownServices {
  @include row(stretch, flex-start);
  padding: 0 16px;
  position: fixed;
  left: 140px;
  top: 100px;
  color: map-get($colors, midnight);

  .content {
    @include column(center, space-between);

    li {
      width: 100%;
    }

    .item {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      text-align: center;
      height: 48px;

      .label {
        flex: 1;
        @include row(space-between, center);
      }

      .pill {
        background-color: map-get($colors, health);
        margin-left: 7px;
      }

      svg {
        width: 24px;
      }

      p {
        @include font($size: 14px, $lineHeight: 24px);
        margin-left: 10px;
      }

      &:hover {
        p,
        svg {
          color: map-get($colors, lush);
        }
      }

      &-business {
        &:hover {
          p {
            color: map-get($colors, lush);
          }
        }
      }

      &.topItem {
        justify-content: space-between;
        border-bottom: 1px solid map-get($colors, grey-2);

        p {
          @include font($size: 12px, $lineHeight: 24px, $weight: medium);
          margin-left: 0;
        }
      }
    }
  }

  @include for-size(1167px) {
    left: 110px;
  }

  @include for-size(mobile) {
    left: 10px;

    .content {
      .item {
        margin-bottom: 16px;
      }
    }
  }
}

.withCart {
  .navButtonContainer {
    display: none;
  }
}

.theme {
  &-corporate {
    .link,
    .linkBusiness {
      &--active {
        color: map-get($colors, teal);
      }
    }

    .headerTop .link:hover {
      color: map-get($colors, teal);
    }

    .navIcon:hover {
      color: map-get($colors, teal);
    }

    .cartIconWrapper .badge {
      background-color: map-get($colors, teal);
    }

    .bottomContent .item:hover p {
      color: map-get($colors, teal);
    }

    .section {
      &-services .topContent {
        border-color: map-get($colors, teal);
      }

      &-group .topContent {
        border-color: map-get($colors, teal);
      }
    }
  }

  &-medical {
    .link,
    .linkBusiness {
      &--active {
        color: map-get($colors, dusty-blue);
      }
    }

    .headerTop .link:hover {
      color: map-get($colors, dusty-blue);
    }

    .navIcon:hover {
      color: map-get($colors, dusty-blue);
    }

    .cartIconWrapper .badge {
      background-color: map-get($colors, dusty-blue);
    }

    .bottomContent .item:hover p {
      color: map-get($colors, dusty-blue);
    }

    .section {
      &-services .topContent {
        border-color: map-get($colors, dusty-blue);
      }

      &-group .topContent {
        border-color: map-get($colors, dusty-blue);
      }
    }
  }

  &-health {
    .link,
    .linkBusiness {
      &--active {
        color: map-get($colors, health);
      }
    }

    .headerTop .link:hover {
      color: map-get($colors, health);
    }

    .navIcon:hover {
      color: map-get($colors, health);
    }

    .cartIconWrapper .badge {
      background-color: map-get($colors, health);
    }

    .bottomContent .item:hover p {
      color: map-get($colors, health);
    }

    .section {
      &-services .topContent {
        border-color: map-get($colors, health);
      }

      &-group .topContent {
        border-color: map-get($colors, health);
      }
    }
  }
}

.customLogo {
  @include row(center, center);
  margin-left: 25px;

  margin: 0 auto;
  background-size: contain;
  background-position: center;

  &--zeel {
    min-width: 88px;
    height: 80px;
    background-image: map-get($assets, logo);
    background-size: 88px 36px;
    background-repeat: no-repeat;
    background-position: 50% 43%;
    background-clip: content-box;
    outline-offset: -1px;

    @include for-size(mobile) {
      min-width: 51px;
      background-size: 51px 21px;
      background-position: 50% 50%;
    }
  }

  &--separator {
    min-width: 1px;
    width: 1px;
    height: 34px;
    margin: 0 16px;
    background-color: map-get($colors, grey-2);

    @include for-size(mobile) {
      margin: 0 12px;
    }
  }

  &--companyLogo {
    img {
      max-height: 34px;
      position: relative;
      top: 2px;
      max-width: 200px;
      object-fit: contain;
    }

    p {
      @include font($size: 18px, $lineHeight: 24px, $weight: semibold);
      color: map-get($colors, grey-12);
      max-width: 175px;
    }

    .longName {
      @include font($size: 14px, $lineHeight: 17px, $weight: regular);
      overflow-wrap: break-word;
    }

    @include for-size(mobile) {
      img {
        max-height: 26px;
        max-width: 20vw;
      }
    }
  }

  @include for-size(mobile) {
    margin-left: 0px;
    position: absolute;
    height: 63px;
    left: 50%;
    transform: translateX(-50%);
    background-size: 68px 28px;
    background-position: 50% 50%;

    width: auto;
    height: 25px;
  }
}

.bookMassageBtn {
  & button {
    position: absolute;
    right: 3px;
    min-width: 175px;

    background-color: map-get($colors, actionable-green) !important;

    border: solid 1px transparent;
    color: white;

    &:hover {
      background-color: map-get($colors, actionable-green-hover) !important;
    }
  }

  &--inverted {
    & button {
      background-color: transparent !important;
      color: map-get($colors, actionable-green) !important;
      border: 1px solid map-get($colors, actionable-green) !important;

      &:hover {
        background-color: map-get($colors, actionable-green) !important;
        color: white !important;
      }
    }
  }
}
