@import '../inputs.module.scss';

.inputPayment {
  width: 100%;
  text-align: left;
  position: relative;

  .wrapper {
    border-bottom: 1px solid map-get($colors, grey-3);
  }

  label {
    color: map-get($colors, grey-5);
  }

  &.valid {
    .wrapper {
      border-color: map-get($colors, lush);
    }

    .validationIcon,
    .validationText {
      color: map-get($colors, lush);
    }
  }

  &.invalid {
    .wrapper {
      border-color: map-get($colors, error);
    }

    .validationIcon,
    .validationText {
      color: map-get($colors, error);
    }
  }

  &.readOnly {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
}

.hostedField {
  height: 47px;
  width: 100%;
}

.theme {
  &-corporate {
    &.valid {
      .wrapper {
        border-color: map-get($colors, teal);
      }
      .validationIcon,
      .validationText {
        color: map-get($colors, teal);
      }
    }
  }
  &-medical {
    &.valid {
      .wrapper {
        border-color: map-get($colors, dusty-blue);
      }
      .validationIcon,
      .validationText {
        color: map-get($colors, dusty-blue);
      }
    }
  }
  &-health {
    &.valid {
      .wrapper {
        border-color: map-get($colors, health);
      }
      .validationIcon,
      .validationText {
        color: map-get($colors, health);
      }
    }
  }
}
