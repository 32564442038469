@import 'styles/base.scss';

.bannerBlock {
  padding: 40px 0;
  background-color: map-get($colors, dusty-blue-dark);

  &-content {
    @include row(center, flex-start);

    p {
      text-align: left;
    }

    a {
      @include font($size: 16px, $lineHeight: 24px, $family: secondary-bold, $color: white);

      &:hover {
        text-decoration: underline;
        color: map-get($colors, white);
      }
    }

    > svg {
      margin-right: 32px;
      margin-bottom: 0;
    }

    @include for-size(mobile) {
      @include column(center, flex-start);
      p {
        text-align: center;
      }

      a {
        display: block;
      }

      > svg {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.title {
  margin-bottom: 4px;
  @include font($size: 32px, $lineHeight: 36px, $family: primary, $color: white);
}

.subtitle {
  @include font($size: 16px, $lineHeight: 24px, $family: secondary, $color: white);
}
