@import 'styles/base.scss';

.section {
  padding: 0px;
  .content {
    @include row(stretch, stretch);
    width: 100%;
    padding: 0px;

    @include for-size(mobile) {
      display: block;
    }
  }
  .infoContent {
    width: 50%;
    text-align: left;
    padding: 56px 0px 56px 100px;

    @include for-size(1300px) {
      max-width: unset;
      padding: 56px 24px 56px 56px;
    }

    @include for-size(mobile) {
      max-width: unset;
      width: 100%;
      padding: 24px 24px 56px;
    }

    p {
      @include font($size: 16px, $color: grey-4, $lineHeight: 22px, $letterSpacing: 2px, $weight: bold);
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    h3 {
      @include font($size: 32px, $lineHeight: 38px, $family: primary);
      width: 100%;
      margin-bottom: 24px;
      max-width: 610px;
    }
    .link {
      color: map-get($colors, light-green);
    }
  }

  .videoWrapper {
    width: 50%;

    @include for-size(mobile) {
      width: 100%;
    }
  }
  .iframeWrapper {
    position: relative;
    min-height: calc(50vw / 1.77777777778);
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }

    @include for-size(mobile) {
      height: calc(100vw / 1.77777777778);
    }
  }
}
