@import '../inputs.module.scss';

.inputBox {
  overflow: visible;
}
.boxWrapper {
  position: relative;
}

.icons {
  position: relative;
  height: 47px;
  line-height: 47px;
  font-size: 18px;
  @include row(center, center);

  > :last-child {
    margin-right: 5px;
  }
}

.lockedIcon {
  color: map-get($colors, grey-3);
}

.dropdown {
  position: absolute;
  margin-top: 4px !important;
  z-index: 100;
  overflow: auto;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(3px);
  border-radius: 0px;
  border-top: 1px solid map-get($colors, grey-1);
  min-height: 40px;
  max-height: 300px;

  @include for-size(mobile) {
    width: 100%;
  }

  &--radius {
    border-radius: 12px !important;
  }

  .dropdownSectionTitle {
    font-size: 16px;
    padding: 18px 20px 4px;
    color: map-get($colors, lush);
    font-family: map-get($font-family, secondary-medium);
  }

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;

    li,
    .option {
      width: auto;
      height: 50%;
      white-space: nowrap;
      cursor: pointer;
      padding: 10px 20px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis !important;
      clear: both;
      text-decoration: none;
      @include font($size: 16px, $color: midnight, $lineHeight: 20px);

      @include for-size(mobile) {
        width: 100%;
        white-space: normal;
      }

      &:hover,
      &.selected {
        color: map-get($colors, lush);
        background-color: map-get($colors, grey-1);
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  .noResults {
    font-size: 16px;
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 10px 20px;
    cursor: default;
    color: map-get($colors, midnight);
  }
}

.noLabel {
  .dropdown {
    top: 50px;
  }
}

.suggestion {
}

.theme {
  &-corporate {
    .dropdown {
      .dropdownSectionTitle {
        color: map-get($colors, teal);
      }
      ul {
        li {
          &:hover,
          &.selected {
            color: map-get($colors, teal);
          }
        }
      }
    }
  }
  &-medical {
    .dropdown {
      .dropdownSectionTitle {
        color: map-get($colors, dusty-blue);
      }
      ul {
        li {
          &:hover,
          &.selected {
            color: map-get($colors, dusty-blue);
          }
        }
      }
    }
  }
  &-health {
    .dropdown {
      .dropdownSectionTitle {
        color: map-get($colors, health);
      }
      ul {
        li {
          &:hover,
          &.selected {
            color: map-get($colors, health);
          }
        }
      }
    }
  }
}
