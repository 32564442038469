@import 'styles/base.scss';

.base {
  @include row(space-between, center);
  padding-bottom: 14px;
}

.node {
  @include row(center, center);
  min-width: 20px;
  width: 20px;
  max-width: 20px;
  min-height: 20px;
  height: 20px;
  max-height: 20px;
  border: solid 1px map-get($colors, grey-2);
  background-color: transparent;
  color: map-get($colors, lush);
  position: relative;
  border-radius: 12px;

  .label {
    @include font($size: 11px, $weight: regular, $color: grey-1);
    @include row(center, center);
    position: absolute;
    top: calc(100% + 4px);
    color: map-get($colors, midnight);
    text-align: center;
    opacity: 0.95;
    pointer-events: none;
    user-select: none;
  }

  &--active {
    background-color: map-get($colors, blue-zeel-work);
    border-color: map-get($colors, blue-zeel-work);

    .label {
      @include font($size: 11px, $weight: semibold, $color: blue-zeel-work);
    }
  }

  &--completed {
    border-color: map-get($colors, blue-zeel-work);
  }

  .inner-checkmark {

  }

  .inner-dot {
    background-color: white;
    width: 44%;
    height: 44%;
    border-radius: 50%;
  }
}

.connector {
  width: 100%;
  height: 1px;
  background-color: map-get($colors, grey-2);

  &--completed {
    background-color: map-get($colors, blue-zeel-work);
  }
}