@import 'styles/base.scss';

.base {
  &:hover {
    background-color: map-get($colors, grey-1) !important;
    color: map-get($colors, midnight) !important;
  }

  .appleIcon {
    margin-right: 6px;
  }
}

.errored {
  border-color: map-get($colors, error) !important;
  color: map-get($colors, error) !important;

  &:hover {
    background-color: lighten(map-get($colors, error), 50%) !important;
    color: map-get($colors, error) !important;
  }
}

.disabled {
  pointer-events: none;
}

.loading {
  span,
  .appleIcon {
    opacity: 0;
  }

  i {
    color: map-get($colors, midnight);
  }
}
