@import 'styles/base.scss';

.videoContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 550px;
  z-index: -1;
  background-size: cover;

  &-size-short {
    height: 350px;
  }

  &-size-normal {
    height: 550px;
  }

  &-size-full {
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.2);
  }

  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.backgroundCover {
  position: fixed !important;

  top: 0;
  width: 100%;
  height: 550px;
  z-index: -1;
  background-size: cover;

  &-size-short {
    height: 350px;
  }

  &-size-normal {
    height: 550px;
  }

  &-size-full {
    height: 800px;
  }
}

.block {
  height: 450px;
  padding: 56px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;

  @include for-size(mobile) {
    &.size-full {
      height: 450px;
    }

    padding: 40px 0px;
  }

  &.size-full {
    height: calc(100vh - 108px);
  }

  &.size-normal {
    height: 450px;
  }

  &.size-short {
    height: auto;
    min-height: 250px;
  }

  &.type-video {
  }

  &.type-image {
    background-color: map-get($colors, natural);
  }

  .subActionContainer {
    margin-top: 18px;

    .subActionLink {
      @include font($size: 18px, $color: white, $weight: bold);
    }

    .subActionNoLink {
      color: white;
      font-size: 18px;
      font-family: map-get($font-family, secondary);
    }
  }

  .subActionContent {
    @include font($size: 18px, $color: white);
    margin-top: 12px;

    a {
      color: map-get($colors, white);
      &:hover {
        color: map-get($colors, lush);
      }
    }
  }

  h1 a,
  h2 a,
  .subtitle a,
  .subtitleShort a {
    color: map-get($colors, white);
    text-decoration: underline;
  }

  h1 {
    display: inline-block;
    @include font($size: 42px, $color: white, $lineHeight: 54px, $family: primary);

    @include for-size(mobile) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .subtitle {
    margin-top: 12px;
    @include font($size: 18px, $color: white, $lineHeight: 24px, $weight: regular);
    max-width: 600px;
    display: inline-block;

    @include for-size(660px) {
      max-width: 100%;
    }

    @include for-size(mobile) {
      font-size: 16px;
      line-height: 24px;
    }

    sup {
      font-size: 9px;
      position: relative;
      top: -7px;
    }
  }

  h2 {
    @extend h1;
    font-size: 32px;

    @include for-size(mobile) {
      font-size: 28px;
    }
  }

  .subtitleShort {
    @extend .subtitle;
    font-size: 16px;
    margin-top: 7px;
  }

  .button {
    margin-top: 30px;
  }

  .additional {
    margin-top: 30px;
    width: 100%;
    display: inline-block;

    > :last-child {
      margin-bottom: 0px;
    }
  }

  .sideButton {
    width: auto;
    margin-right: 11px;
    display: inline-block;
    margin-top: 24px;
  }

  &.horizontal-left {
    text-align: left;
  }

  &.horizontal-right {
    text-align: right;
  }

  &.vertical-top.horizontal-left {
    @include column(flex-start, flex-start);
  }

  &.vertical-top.horizontal-center {
    @include column(center, flex-start);
  }

  &.vertical-top.horizontal-right {
    @include column(flex-end, flex-start);
  }

  &.vertical-center.horizontal-left {
    @include column(flex-start, center);
  }

  &.vertical-center.horizontal-center {
    @include column(center, center);
  }

  &.vertical-center.horizontal-right {
    @include column(flex-end, center);
  }

  &.vertical-bottom.horizontal-left {
    @include column(flex-start, flex-end);
  }

  &.vertical-bottom.horizontal-center {
    @include column(center, flex-end);
  }

  &.vertical-bottom.horizontal-right {
    @include column(flex-end, flex-end);
  }
}
