@import 'styles/base.scss';

.base {
  .therapistAvatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-size: cover;
    margin: auto;
    margin-bottom: 16px;
  }

  p {
    margin: 2px 0;
  }

  h5 {
    text-align: center;
    margin-top: 28px;
    @include font($size: 16px, $weight: bold);
  }

  .body {
    @include for-size(mobile) {
      padding-bottom: 120px;
    }
  }
}
