@import 'styles/base.scss';

.base {
  margin-top: 24px;

  button {
    width: 100%;
    border-radius: 12px;
    background-color: map-get($colors, actionable-green) !important;
    border-color: transparent !important;

    &:hover {
      background-color: map-get($colors, actionable-green-hover) !important;
    }

    &:disabled {
      background-color: map-get($colors, grey-2) !important;
      opacity: 0.4;
      color: map-get($colors, grey-4) !important;
    }
  }
}