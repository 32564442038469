@import 'styles/base.scss';

.detail {
  @include column(center, center);
  width: 200px;
  padding: 28px 12px;
  text-align: center;

  @include for-size(mobile) {
    padding: 20px 0px;
  }

  @include for-size(350px) {
    width: 100%;
  }

  .imgContainer {
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    padding: 20px;
    border: solid 1px map-get($colors, lush);
    border-radius: 50%;
    @include for-size(mobile) {
      margin-bottom: 20px;
    }
  }

  img {
    width: 60px;
    height: 60px;
  }

  .link {
    letter-spacing: normal;
    text-decoration: underline;
    @include font($size: 16px, $color: midnight, $weight: medium);
  }
}
