@import 'styles/base.scss';

.socialIcon {
  display: inline-block;
  color: map-get($colors, midnight);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  margin-right: 30px;

  &:hover {
    cursor: pointer;
    color: map-get($colors, lush);
  }
}

.theme {
  &-corporate {
    &.socialIcon:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    &.socialIcon:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    &.socialIcon:hover {
      color: map-get($colors, health);
    }
  }
}
