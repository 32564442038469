@import 'styles/base.scss';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100dvw;
  height: 0px;
  transition: all 0.7s ease-in-out;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0);
  border-top: double 5px transparent;
  overflow: hidden;
  z-index: 9999999999999991;

  &--toggled {
    height: 80dvh;
    border-color: map-get($colors, midnight);
    background-color: rgba(255, 255, 255, 0.6);
  }

  .inner {
    display: block;
    width: 100%;
    max-height: 100%;
    padding: 16px 40px;
    overflow: auto;

    .log {
      @include font($size: 14px, $lineHeight: 16px);
      position: relative;
      background-color: rgba(0, 0, 0, 0.1);
      padding: 8px 8px 8px 44px;
      border-radius: 8px;
      margin-bottom: 4px;

      &--warning {
        color: yellow;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

      }

      &--error {
        color: map-get($colors, error-dark);

        .item {
          font-weight: bold !important;
        }
      }

      .item {
        @include font($size: 12px, $lineHeight: 14px);
        margin-right: 8px;
        font-family: monospace;
      }

      .index {
        @include font($size: 12px, $color: grey-6);
        @include row(center, center);
        position: absolute;
        top: 0;
        left: 0;
        padding: 9px 0px;
        width: 35px;
        font-family: monospace;
        height: 100%;
        border-right: solid 1px map-get($colors, grey-5);
        opacity: 0.4;
      }
    }
  }

  .clearIcon {
    position: absolute;
    top: 10px;
    left: 8px;
    cursor: pointer;
    color: black;
  }

  .copyIcon {
    position: absolute;
    top: 48px;
    left: 8px;
    cursor: pointer;
    color: black;

    &--copied {
      color: map-get($colors, actionable-green);
      opacity: 0.4;
    }
  }

  .closeIcon {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    color: black;
  }

  svg:active {
    opacity: 0.4;
  }
}

.button {
  @include font($size: 16px, $color: white);
  @include row(center, center);
  padding: 8px;
  background-color: rgba(map-get($colors, error), 0.8);
  position: fixed;
  bottom: 12px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  color: white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7);
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease;
  z-index: 9999999999999992;

  &:hover {
    background-color: map-get($colors, error);
    transform: scale(1.1);
  }

  &--position- {
    &left {
      left: 12px;
    }

    &right {
      right: 12px;
    }
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}