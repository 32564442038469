@import 'components/modals/templates/Modal/style.module.scss';

.modal {
  padding: 32px 16px 16px;
  max-width: 375px;
  border-radius: 10px;

  @include for-size(mobile) {
    max-width: 100vw;
    padding-bottom: 120px !important;
  }
}

.title {
  @include font($size: 24px, $lineHeight: 36px, $weight: bold);
  margin-bottom: 24px;
}
