@import 'styles/base.scss';

.icon {
  position: relative;
}

.CustomIconsVF {
  display: inline-block;
  font: normal normal normal 14px/1 CustomIconsVF;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hotspot {
  &:after {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    background-color: transparent;
    width: 200%;
    height: 200%;
    z-index: 1;
    border-radius: 50%;
  }
}

.edit:before {
  content: '\e800';
}
.textlink:before {
  content: '\e801';
}
.times:before {
  content: '\e803';
}
.user:before {
  content: '\e805';
}
.add:before {
  content: '\e806';
}
.add-circle:before {
  content: '\e84c';
}
.subtract-circle:before {
  content: '\e84b';
}
.exclamation-circle:before {
  content: '\e807';
}
.checkmark-circle:before {
  content: '\e804';
}
.times-circle:before {
  content: '\e824';
}
.times-full-circle:before {
  content: '\e849';
}
.cart:before {
  content: '\e808';
}
.question:before {
  content: '\e809';
}
.refresh:before {
  content: '\e80a';
}
.search:before {
  content: '\e80b';
}
.share:before {
  content: '\e80c';
}
.sleep:before {
  content: '\e80d';
}
.chat:before {
  content: '\e80e';
}
.hand:before {
  content: '\e80f';
}
.print:before {
  content: '\e810';
}
.pricetag:before {
  content: '\e811';
}
.preferences:before {
  content: '\e812';
}
.location:before {
  content: '\e813';
}
.phone:before {
  content: '\e814';
}
.pets:before {
  content: '\e815';
}
.notifications:before {
  content: '\e816';
}
.notes:before {
  content: '\e817';
}
.new-window:before {
  content: '\e818';
}
.navigate:before {
  content: '\e819';
}
.garbage:before {
  content: '\e81a';
}
.message:before {
  content: '\e81b';
}
.minus:before {
  content: '\e81c';
}
.dollar:before {
  content: '\e81d';
}
.duplicate:before {
  content: '\e81e';
}
.download:before {
  content: '\e81f';
}
.gear:before {
  content: '\e820';
}
.eyes-hide:before {
  content: '\e821';
}
.eyes-show:before {
  content: '\e822';
}
.orientation:before {
  content: '\e823';
}
.calendar:before {
  content: '\e825';
}
.checkmark:before {
  content: '\e826';
}
.creditcard:before {
  content: '\e827';
}
.clock-requests:before {
  content: '\e828';
}
.checkout:before {
  content: '\e829';
}
.adduser:before {
  content: '\e82a';
}
.arrow-up:before {
  content: '\e82b';
}
.arrow-down:before {
  content: '\e82d';
}
.arrow-left:before {
  content: '\e802';
}
.arrow-right:before {
  content: '\e82c';
}
.angle-up:before {
  content: '\e831';
}
.angle-down:before {
  content: '\e82e';
}
.angle-left:before {
  content: '\e82f';
}
.angle-right:before {
  content: '\e830';
}
.house:before {
  content: '\e832';
}
.hamburger:before {
  content: '\e833';
}
.kebab:before {
  content: '\e834';
}
.list:before {
  content: '\e835';
}
.meatballsub:before {
  content: '\e836';
}
.email:before {
  content: '\e837';
}
.gift:before {
  content: '\e838';
}
.stopwatch:before {
  content: '\e839';
}
.appointments:before {
  content: '\e83a';
}
.star-o:before {
  content: '\e83b';
}
.star:before {
  content: '\e83c';
}
.heart:before {
  content: '\e83d';
}
.table:before {
  content: '\e84a';
}
