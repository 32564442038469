@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 56px 0;
  position: relative;
  background: map-get($colors, white);

  @include for-size(mobile) {
    padding: 42px 0;
  }

  .content {
    @include row(flex-start, center);
    &--alignTop {
      @include row(flex-start, flex-start);
    }

    @include for-size(mobile) {
      display: block;
    }
  }

  h3 {
    @include font($size: 24px, $lineHeight: 36px, $weight: bold);
    width: 430px;
    padding-right: 48px;
    text-align: left;

    @include for-size(mobile) {
      text-align: center;
      width: 100%;
      padding-right: 0px;
      margin-bottom: 16px;
    }
  }

  .subtitle {
    @include font($size: 18px, $lineHeight: 25px, $weight: bold);

    @include for-size(mobile) {
      text-align: center;
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .subtitleContent {
    margin-top: 24px;
  }

  .description {
    @include font($size: 16px, $lineHeight: 25px, $weight: regular);
    width: 100%;

    @include for-size(mobile) {
      text-align: center;
    }
  }
}
