@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 56px 0;
  background: map-get($colors, white);
  text-align: center;

  &--natural {
    background: map-get($colors, natural);
  }

  &--beige {
    background: map-get($colors, beige);
  }

  &--slimPadding {
    padding: 28px 0;
  }

  .content {
    &-narrow {
      @extend .glContainerNarrow;
    }
    &-small {
      @extend .glContainerSmall;
    }
    &-normal {
      @extend .glContainer;
    }
    &-large {
      @extend .glContainer;
      width: 1440px;
      padding: 0px 24px;
      margin: 0 auto;
      position: relative;

      @include for-size(1440px) {
        width: 100%;
      }
    }
    &-full {
      width: 100%;
      padding: 0px 24px;
    }
    &-noHorizPadding {
      padding: 0px 0px;
    }
  }

  .topper {
    @include font($size: 16px, $color: grey-5, $lineHeight: 22px, $letterSpacing: 2px, $weight: bold);
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .title {
    @include font($size: 32px, $lineHeight: 38px, $family: primary);
    width: 100%;
    margin-bottom: 24px;

    &--hasDescription {
      margin-bottom: 16px;
    }
  }

  .description {
    @include font($size: 18px, $lineHeight: 24px);
    width: 100%;
    margin-bottom: 24px;
  }

  .half {
    @include for-size(desktop) {
      width: 45%;
      text-align: left;
      &-right {
        margin-left: auto;
      }
    }
  }

  &--lightText {
    .title,
    .topper,
    p {
      color: map-get($colors, white);
    }
  }
}
