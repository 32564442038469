@import '../inputs.module.scss';

.searchDropdown {
  position: absolute;
  top: 100%;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  background-color: map-get($colors, white);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.075);
  border-radius: 0px;
  min-height: 40px;
  max-height: 400px;
  overflow-y: auto;
  padding: 16px 0;

  @include for-size(mobile) {
    width: 100%;
    max-height: 300px;
  }

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;

    li {
      width: auto;
      white-space: nowrap;
      background-color: map-get($colors, white);
      cursor: pointer;
      display: block;
      clear: both;
      text-decoration: none;
      @include font($size: 16px, $color: midnight, $lineHeight: 50px, $weight: bold);
      text-align: left;
      padding: 0 16px;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;

      @include for-size(mobile) {
        width: 100%;
      }

      &:hover,
      &.selected {
        color: map-get($colors, lush);
      }

      &:last-of-type {
        border: none;
      }
    }

    .resultFocussed {
      background-color: map-get($colors, natural);
    }
  }

  .dropdownSeparator {
    width: calc(100% - 32px);
    border-top: solid 1px map-get($colors, grey-1);
    position: absolute;
    top: 0;
    left: 16px;
  }

  .noResults {
    font-size: 16px;
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 10px 20px;
    cursor: default;
    color: map-get($colors, midnight);
  }
}

.inputBox {
  overflow: visible;
}

.input {
  padding-left: 15px;
}

.wrapper {
  position: relative;
}

.loaderContainer {
  @include row(center, center);
  width: 100%;
  .loader {
    color: map-get($colors, lush);
  }
}

.magnifyingIcon,
.clearIcon {
  font-size: 22px;
  top: 3px;
  right: 8px;
}

.clearIcon {
  cursor: pointer;
  &:hover {
    color: map-get($colors, lush);
  }
}

.clearIcon {
}
