@import 'components/modals/templates/Modal/style.module.scss';

.modal {
  width: 100vw;
  padding: 0 !important;
  background-color: transparent;
  overflow: visible;
  pointer-events: none;

  i {
    color: map-get($colors, white);
  }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.65);
}

.content {
  padding: 60px 0;
  width: 100vw;
  height: calc(100vh - 120px);
  margin: auto;

  video {
    pointer-events: all;
    width: 100%;
    height: 100%;
    background-color: black;
    outline: none;
  }

  .videoFiller {
    position: absolute;
    top: 60px;
    width: 100%;
    height: calc(100% - 120px);
    background-color: black;
  }
}

.arrows {
  @include row(space-between, center);
  pointer-events: all;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 0 16px;

  i {
    cursor: pointer;
    color: map-get($colors, white);
    font-size: 30px;

    &:hover {
      color: map-get($colors, grey-4);
    }
  }
}
