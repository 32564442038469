@import 'styles/base.scss';

.link {
  font-family: map-get($font-family, secondary-bold);
  text-decoration: underline;
  cursor: pointer;
}

.input {
  margin-bottom: 0 !important;
}

.terms {
  margin: 8px 0;
  text-align: center;

  @include for-size(mobile) {
    font-size: 14px;

    @include for-size(330px) {
      font-size: 12px;
    }
  }
}

.secondInput {
  margin-bottom: 0;
}

.linkButton {
  height: 24px;
  font-family: map-get($font-family, secondary-medium);
  font-size: 14px;
  line-height: 24px;
  color: map-get($colors, midnight);
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 15px;

  @include for-size(mobile) {
    float: left;
    width: 100%;
    margin-bottom: 0;
  }

  &:hover {
    color: map-get($colors, lush);
  }
}
