@import 'styles/base.scss';

.selectBox {
  width: 100%;
  text-align: left;
  position: relative;

  label {
    width: 100%;
    height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    @include font($size: 14px, $color: grey-5, $weight: 500, $family: tertiary);

    .labelMore {
      float: right;

      a {
        text-decoration: underline;
        cursor: pointer;
        color: map-get($colors, midnight);
      }
    }
  }
}

.createLabel {
  @include font($size: 16px, $weight: bold);
  cursor: pointer;
}

.multiple {
  [class*='select__control'] {
    height: auto !important;
    min-height: 50px;
  }
}

.wrapLabel {
  label {
    overflow: visible;
    height: auto;
    white-space: normal;
    text-overflow: inherit;
    margin-bottom: 4px;
  }
}

.focused {
  display: none;
}

.select {
  width: 100%;
}

:global(.select__control) {
  height: 50px !important;
  border: 1px solid map-get($colors, grey-3) !important;
  background-color: map-get($colors, white) !important;
  outline: none !important;
  box-shadow: none !important;
  font-style: normal !important;
  font-stretch: normal !important;
  letter-spacing: normal !important;
  @include font($size: 16px, $color: midnight, $lineHeight: normal, $weight: medium);
  font-size: 16px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

:global(.select__control--is-focused) {
  border-color: map-get($colors, lush-alt) !important;
}

:global(.select__control--menu-is-open) {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-color: map-get($colors, lush-alt) !important;
}

:global(.select__value-container) {
  padding-left: 14px !important;
}

:global(.select__indicator-separator) {
  display: none !important;
}

:global(.select__menu) {
  margin: 0px !important;
  box-shadow: none !important;
  background-color: map-get($colors, white) !important;
  border: 1px solid map-get($colors, grey-3) !important;
  border-radius: 0px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  margin-top: -1px !important;
}

:global(.select__menu-list) {
  padding: 0px !important;

  > :last-child {
    border-bottom: 1px solid transparent !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

:global(.select__option) {
  height: 50px !important;
  line-height: 46px !important;
  padding: 0px 14px 0px 14px !important;
  font-style: normal !important;
  font-stretch: normal !important;
  letter-spacing: normal !important;
  border-bottom: 1px solid map-get($colors, grey-3) !important;
  @include font($size: 16px, $color: midnight, $weight: medium);
  color: map-get($colors, midnight) !important;

  &:hover {
    cursor: pointer !important;
  }
}

:global(.select__option--is-focused) {
  background: map-get($colors, midnight-hover) !important;
  color: map-get($colors, white) !important;
}

:global(.select__option--is-selected) {
  background: map-get($colors, midnight) !important;
  color: map-get($colors, white) !important;
  @include font($weight: bold);
}

.validationIcon {
  position: absolute;
  right: 5px;
  bottom: 16px;
}

.validationText {
  min-height: 17px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  @include font($size: 14px, $lineHeight: 17px);
}

.valid {
  .validationIcon {
    color: map-get($colors, lush);
  }

  .validationText {
    color: map-get($colors, lush);
  }

  :global(.select__control) {
    //border-color: map-get($colors, lush);
  }
}

.invalid {
  .validationIcon {
    color: map-get($colors, error);
  }

  .validationText {
    color: map-get($colors, error);
  }

  :global(.select__control) {
    border-color: map-get($colors, error) !important;
  }
}

.disabled {
  opacity: 0.5;
}

.theme {
  &-corporate {
    &.valid {
      .validationIcon {
        color: map-get($colors, teal);
      }

      .validationText {
        color: map-get($colors, teal);
      }
    }

    :global(.select__control--menu-is-open),
    :global(.select__control--is-focused) {
      border-color: map-get($colors, teal) !important;
    }
  }

  &-medical {
    &.valid {
      .validationIcon {
        color: map-get($colors, dusty-blue);
      }

      .validationText {
        color: map-get($colors, dusty-blue);
      }
    }

    :global(.select__control--menu-is-open),
    :global(.select__control--is-focused) {
      border-color: map-get($colors, dusty-blue) !important;
    }
  }

  &-health {
    &.valid {
      .validationIcon {
        color: map-get($colors, health);
      }

      .validationText {
        color: map-get($colors, health);
      }
    }

    :global(.select__control--menu-is-open),
    :global(.select__control--is-focused) {
      border-color: map-get($colors, health) !important;
    }
  }

  &-atWork {
    &.valid {
      .validationIcon {
        color: map-get($colors, blue-zeel-work);
      }

      .validationText {
        color: map-get($colors, blue-zeel-work);
      }
    }

    :global(.select__control--menu-is-open),
    :global(.select__control--is-focused) {
      border-color: map-get($colors, blue-zeel-work) !important;
    }
  }
}
