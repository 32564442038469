@import 'styles/base.scss';

.base {

}

.failure {
  @include font($weight: medium);
  color: map-get($colors, error-dark);
  text-align: center;
  margin-bottom: 24px;
}

.success {
  @include font($weight: medium);
  color: map-get($colors, actionable-green);
  text-align: center;
  margin-bottom: 24px;
}

.message {

}

.details {
  margin-top: 16px;
}