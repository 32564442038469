@import 'styles/base.scss';

.payment {
  width: 100%;
  border: solid 1px map-get($colors, grey-3);
  border-radius: 12px;
  padding: 24px;
  float: left;
  cursor: pointer;
  background: map-get($colors, white);
  margin-bottom: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 1px transparent;

  &:hover:not(.disabled) {
    border-color: map-get($colors, lush);
    box-shadow: 0 0 0 1px map-get($colors, lush);
  }

  &.selected {
    border-color: map-get($colors, lush);
    box-shadow: 0 0 0 1px map-get($colors, lush);
  }

  &.disabled {
    opacity: 0.4;
  }
}

.labels {
  width: 100%;
  text-align: left;
  @include font($size: 16px, $color: midnight, $lineHeight: 24px);

  .label1 {
    @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
  }

  .label2,
  .label3 {
    font-size: 14px;
    line-height: 24px;
  }
}

.badStatus {
  border-color: map-get($colors, error) !important;
  box-shadow: 0 0 0 1px map-get($colors, error) !important;
  .labels {
    color: map-get($colors, error);
  }
}

.failedMessage {
  @include font($size: 14px, $lineHeight: 24px);
  color: map-get($colors, error);
  margin-top: 12px;
}

.actions {
  flex: 0 0 70px;
  line-height: 44px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;

  i {
    font-size: 20px;
    margin-left: 15px;
    width: 20px;
    height: 20px;

    &:hover {
      color: map-get($colors, lush);
      cursor: pointer;
    }
  }
}

.theme {
  &-corporate {
    &.payment {
      &:hover:not(.disabled) {
        border-color: map-get($colors, teal);
        box-shadow: 0 0 0 1px map-get($colors, teal);
      }

      &.selected {
        border-color: map-get($colors, teal);
        box-shadow: 0 0 0 1px map-get($colors, teal);
      }
    }
    .actions i:hover {
      color: map-get($colors, teal);
    }
  }
  &-medical {
    &.payment {
      &:hover:not(.disabled) {
        border-color: map-get($colors, dusty-blue);
        box-shadow: 0 0 0 1px map-get($colors, dusty-blue);
      }

      &.selected {
        border-color: map-get($colors, dusty-blue);
        box-shadow: 0 0 0 1px map-get($colors, dusty-blue);
      }
    }
    .actions i:hover {
      color: map-get($colors, dusty-blue);
    }
  }
  &-health {
    &.payment {
      &:hover:not(.disabled) {
        border-color: map-get($colors, health);
        box-shadow: 0 0 0 1px map-get($colors, health);
      }

      &.selected {
        border-color: map-get($colors, health);
        box-shadow: 0 0 0 1px map-get($colors, health);
      }
    }
    .actions i:hover {
      color: map-get($colors, health);
    }
  }
}
