@import 'styles/base.scss';

.item {
  @include font($size: 11px, $color: white, $lineHeight: 14px, $weight: medium);
  display: inline-block;
  height: 24px;
  width: auto;
  border-radius: 12px;
  background: map-get($colors, lush-alt);
  opacity: 0.8;
  padding: 4px 9px 2px;
  user-select: none;
  text-align: center;
  transition: all 0.1s ease;
  position: relative;

  &:hover {
    border-color: map-get($colors, lush);
  }
}

.light {
  background-color: map-get($colors, white);
  color: map-get($colors, midnight);
}

.outlined {
  border: solid 1px map-get($colors, actionable-green);
  background-color: map-get($colors, white);
  color: map-get($colors, actionable-green);
}

.opaque {
  opacity: 1 !important;
}

.clickable {
  cursor: pointer;
}

.hasStartIcon {
  padding-left: 20px;
}

.startIcon {
  position: absolute !important;
  left: 2px;
  top: 2px;
}

.hasEndIcon {
  padding-right: 20px;
}

.endIcon {
  position: absolute !important;
  right: 2px;
  top: 2px;
}

.margin {
  &-both {
    margin-left: 6px;
    margin-right: 6px;
  }

  &-left {
    margin-left: 6px;
  }

  &-right {
    margin-right: 6px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.theme {
  &-corporate {
    background-color: map-get($colors, teal);
  }

  &-medical {
    background-color: map-get($colors, dusty-blue);
  }

  &-health {
    background-color: map-get($colors, health);
  }
}
