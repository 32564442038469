@import 'components/modals/templates/Modal/style.module.scss';

.customHeader {
  margin-top: 89px;
  margin-bottom: 40px;
}

.customText {
  font-size: 16px;
  line-height: 26px;
  max-width: 80%;
  margin: 30px auto;
}

.customIcon {
  font-size: 70px;
  height: 70px;
}
