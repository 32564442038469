@import 'styles/base.scss';

.block {
  min-height: 240px;
  width: 100%;
  text-align: center;
  padding: 56px 0px;
  background-color: map-get($colors, white);

  @include for-size(mobile) {
    padding: 40px 0px;
  }

  h3 {
    padding: 0px 30px;
    width: 100%;
    margin-bottom: 14px;
    @include font($size: 32px, $color: midnight, $lineHeight: 1.36, $family: primary);

    @include for-size(mobile) {
      font-size: 28px;
    }
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 0px 30px;
    text-align: center;
    width: 100%;
    @include font($size: 16px, $color: midnight, $lineHeight: 24px);

    @include for-size(mobile) {
      width: 100%;
      margin-bottom: 35px;
    }
  }
}

.desktopMassageContainer {
  display: block;
  @include row(center, center);
  @include for-size(mobile) {
    display: none;
  }
}

.carousel {
  display: none;
  padding: 0px 24px;

  @include for-size(mobile) {
    display: block;
  }
}

.card {
  min-height: 280px !important;
}

.detailedCarousel {
  width: 992px;
  margin: auto;

  @include for-size(mobile) {
    width: 90%;
  }
}

.button {
  margin-top: 45px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  @include for-size(585px) {
    width: calc(100% - 48px);
    margin-top: 28px;
  }
}
