@import 'styles/base.scss';

.base {
  width: 100%;
  margin-top: 17px;
}

.checkboxWrapper {
  @include row(flex-start, center);
  text-align: left;
  position: relative;
  @include font($size: 14px, $weight: medium, $lineHeight: 24px);
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.4;
}

.validationText {
  margin-top: 2px;
  color: map-get($colors, error);
  min-height: 17px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  @include font($size: 14px, $lineHeight: 17px);
}

.circular {
  .checkbox {
    border-radius: 50%;

    &:focus {
      border-radius: 50%;
    }
  }

  .checkbox label {
    border-radius: 50%;
  }

  .checkbox input[type='checkbox']:checked + label::after {
    border-radius: 50%;
    content: '';
    border: solid 3px white;
  }
}

.checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: fit-content;

  input {
    &:checked + .checkboxIcon {
      border-color: map-get($colors, lush);
      background: map-get($colors, lush);

      &::before {
        content: '';
      }
    }

    .checkbox:focus {
      box-shadow: 0px 0px 0px 3px map-get($colors, teal);
    }
  }

  .checkboxIcon {
    position: relative;
    display: inline-block;
    min-width: 24px;
    min-height: 24px;
    margin-right: 10px;
    border: 1px solid map-get($colors, grey-3);
    background-color: map-get($colors, white);
    border-radius: 3px;
    transition: all 0.15s ease-in-out;

    &::before {
      transition: all 0.15s ease-in-out;
      display: block;
      width: 9px;
      height: 15px;
      border-right: 2px solid map-get($colors, white);
      border-bottom: 2px solid map-get($colors, white);
      transform: rotate(45deg) translate(5px, -4px);
    }
  }
}

.circular {
  .checkboxIcon {
    border-radius: 50% !important;

    &::before {
      display: none;
    }
    &::after {
      position: absolute;
      display: block;
      border-radius: 50% !important;
      width: 100%;
      height: 100%;
      content: '';
      border: solid 2px white;
    }

    &:focus {
      border-radius: 50% !important;
    }
  }

  .checkbox {
    border-radius: 50% !important;
  }

  .checkbox input[type='checkbox']:checked + .checkboxIcon {
    border-radius: 50% !important;
    content: 'a';
    border: solid 3px white;
    border-color: map-get($colors, lush);
    background: map-get($colors, lush);
  }
}

.alignTop {
  align-items: flex-start;

  .checkboxIcon {
    margin-top: 6px;
  }
}

.theme {
  &-corporate {
    .checkbox input[type='checkbox']:checked + .checkboxIcon {
      border-color: map-get($colors, lush);
      background: map-get($colors, lush);
    }

    // .checkbox input[type='checkbox']:checked + label {
    //   border: 1px solid map-get($colors, teal);
    // }

    .checkbox:focus {
      box-shadow: 0px 0px 0px 3px map-get($colors, dusty-blue);
    }
  }
  &-medical {
    .checkbox input[type='checkbox']:checked + .checkboxIcon {
      border-color: map-get($colors, dusty-blue);
      background: map-get($colors, dusty-blue);
    }
  }
  &-health {
    .checkbox input[type='checkbox']:checked + .checkboxIcon {
      border-color: map-get($colors, health);
      background: map-get($colors, health);
    }

    .checkbox:focus {
      box-shadow: 0px 0px 0px 3px map-get($colors, health);
    }
  }
}
