@import 'components/modals/templates/Modal/style.module.scss';

.base {
  width: 660px;

  b {
    @include font($weight: bold);
  }

  ul {
    margin-top: 0;
    margin-bottom: 24px;
  }
}