@import 'styles/base.scss';

.physicalTherapyLeadFormBlock {
  background-color: map-get($colors, dusty-blue-dark);

  &-content {
    @include row(stretch, flex-start);
    @include for-size(mobile) {
      display: block;
    }
  }

  h2 {
    @include font($size: 32px, $lineHeight: 46px, $color: white, $weight: medium);
  }

  .formTitle {
    max-width: 490px;
    color: map-get($colors, white);
    text-align: left;

    h2 {
      @include font($size: 32px, $lineHeight: 46px, $family: secondary, $weight: medium);
    }

    p {
      @include font($size: 20px, $lineHeight: 30px, $family: secondary, $weight: light);
    }

    .question {
      @include font($size: 20px, $lineHeight: 30px, $family: secondary, $weight: medium);
      margin-top: 30px;

      a {
        @include font($size: 20px, $lineHeight: 30px, $family: secondary, $weight: medium);
        color: map-get($colors, white);
        text-decoration: underline;
      }
    }

    @include for-size(mobile) {
      max-width: unset;
      margin-bottom: 56px;

      h2 {
        @include font($size: 28px, $lineHeight: 38px, $family: secondary, $weight: medium);
      }

      p {
        @include font($size: 16px, $lineHeight: 26px, $family: secondary, $weight: light);
      }

      .question {
        @include font($size: 16px, $lineHeight: 26px, $family: secondary, $weight: medium);
        margin-top: 24px;

        a {
          @include font($size: 16px, $lineHeight: 26px, $family: secondary, $weight: medium);
        }
      }
    }
  }

  .successContainer {
    padding: 52.5px;
    margin: auto;
    width: auto;
    @include column(center, center);
    svg {
      color: map-get($colors, white);
      margin-bottom: 16px;
    }

    p {
      margin: 16px 0 60px;
      @include font($size: 18px, $lineHeight: 28px, $color: white);
    }

    @include for-size(mobile) {
      padding: 52.5px 0;
    }
  }

  .formContainer {
    flex: 1;
    margin-left: 80px;
    text-align: right;
    min-width: 400px;

    .input {
      label,
      input {
        color: white;
      }
    }
    .inputWrapper {
      border-bottom-color: white;
    }
    .select {
      :global(.select__control) {
        background-color: transparent !important;
        border-color: white !important;
      }
      :global(.select__value-container *) {
        color: white !important;
      }
    }
    .button {
      min-width: 160px;
    }

    @include for-size(mobile) {
      margin-left: 0px;
      min-width: unset;
    }
  }
}
