@import 'components/modals/templates/Modal/style.module.scss';

.title {
  @include for-size(mobile) {
    font-size: 24px;
  }
}

.modalBody {
  margin-top: 24px;
  padding: 8px;

  @include for-size(mobile) {
    padding-bottom: 120px;
  }
}

.topLabelContainer {
  @include row(center, center);
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: map-get($colors, lush);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  @include font($size: 12px, $color: white);
}

.therapistAvatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-size: cover;
  margin: auto;
  margin-bottom: 16px;
}

.therapistName {
  @include font($size: 20px, $weight: bold);
}

.detailLine {
  margin: 0px !important;
  font-size: 16px !important;
  @include font();
}

.separator {
  border-bottom: solid 1px map-get($colors, grey-2);
  width: 100%;
  margin: 28px 0;
}

.stars {
  position: relative;
  top: -6px;
}

.starIcon {
  font-size: 60px;

  @include for-size(mobile) {
    font-size: 48px;
  }
}

.chips {
  margin-top: 12px;
}

.level {
  margin-top: 24px;
}

.comments {
  margin-top: 18px;
  height: 100px !important;
  max-height: 100px;
}

.fetching {
  overflow: hidden;
}
