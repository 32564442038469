@import 'components/modals/templates/Modal/style.module.scss';

.modalBodyWrapper {
  margin-top: 35px;
}
.inputsWrapper {
  @include row(center, center);
  flex-wrap: nowrap;
  gap: 16px;
}

.inputsWrapperDisabled {
  pointer-events: none;
}

.input {
  width: 100%;
  height: 87px !important;
  background: map-get($colors, grey-1);
  font-size: 28px;
  font-family: map-get($font-family, secondary-medium);
  text-align: center;
  outline: none;
  padding: 0;
  border: 0;
  border-radius: 6px;
  margin-bottom: 10px !important;
  flex-shrink: 1;

  @include for-size(mobile) {
    width: 69px;

    @include for-size(330px) {
      width: 55px;
      height: 75px !important;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.codeError {
  color: map-get($colors, error);
}

u {
  @include font($size: 14px, $lineHeight: 24px, $color: midnight);
  display: block;
  width: fit-content;
  margin: 24px auto 0;
  cursor: pointer;
}
