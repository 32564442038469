@import 'styles/base.scss';

.base {
  width: 100%;
  margin: 0 auto;

  .errorBox {
    @include row(stretch, flex-start);
    background-color: map-get($colors, error-light-bg);
    border-radius: 12px;
    padding: 16px;
    margin: 8px 0;

    .content {
      text-align: left;

      h2 {
        @include font($size: 16px, $lineHeight: 24px, $weight: semibold, $color: midnight);
        margin-bottom: 4px;
      }

      p {
        @include font($size: 14px, $lineHeight: 24px, $color: grey-12);
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  .title {
    @include row(flex-start, center);
    @include font($family: secondary, $weight: bold, $size: 24px);
    margin: 0 0 24px;
    position: relative;

    .backIcon {
      top: 0px;
      left: -4px;

      &:hover {
        color: map-get($colors, actionable-green)
      }
    }
  }

  .description {
  }

  .button {
    width: 100%;
    display: block;
    margin-top: 16px;
    border-radius: 12px;
    background-color: map-get($colors, actionable-green) !important;
    border-color: transparent !important;

    &:hover {
      background-color: map-get($colors, actionable-green-hover) !important;
    }

    &--disabled {
      background-color: map-get($colors, grey-2) !important;
      opacity: 0.4;
      color: map-get($colors, grey-4) !important;
    }
  }

  .skeletonField {
    height: 56px;
    width: 100%;
    margin-bottom: 16px;
  }
}