@import 'styles/base.scss';

.calendar {
  max-width: 100%;
  width: 500px;

  .calendarNavigation {
    @include row(flex-start, center);
    margin-bottom: 22px;

    span {
      @include font($size: 20px, $lineHeight: 22px, $color: middle, $weight: medium);
      margin-right: 3px;
    }

    svg:last-child {
      margin-left: 8px;
    }

    .iconDisabled {
      color: map-get($colors, grey-3);
      cursor: not-allowed;
    }
  }

  .weekdays {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    margin-bottom: 12.67px;

    span {
      width: calc(100% / 7);
      text-align: center;
      @include font($size: 12.03px, $lineHeight: 14px, $color: midnight);
    }
  }

  .weekRow {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;

    .day {
      width: calc(100% / 7);
      @include column(center, flex-end);
      margin-bottom: 5.4px;
      color: map-get($colors, midnight);

      &.disabled {
        color: map-get($colors, grey-3);
        pointer-events: none;
      }

      &.firstDay {
        color: #646464;
      }

      span:first-child {
        height: 12px;
        @include font($size: 10.34px, $lineHeight: 12px);
      }

      span:last-child {
        @include row(center, center);
        width: 35px;
        height: 35px;
        @include font($size: 18.96px, $lineHeight: 22.22px);
        border-radius: 50%;
        cursor: pointer;

        &.selected {
          color: map-get($colors, white);
          background-color: map-get($colors, dusty-blue);
        }

        &.highlighted {
          background-color: map-get($colors, lush-light);
          color: map-get($colors, midnight);
          &:hover {
            background-color: rgba(map-get($colors, lush), 0.3);
          }
        }

        @media (hover: hover) {
          &:not(.selected):not(.highlighted):hover {
            background-color: map-get($colors, grey-2);
          }
        }
      }
    }
  }
}

.theme {
  &-medical {
    .day {
      span:last-child {
        &.selected {
          background-color: map-get($colors, dusty-blue) !important;
        }

        &.highlighted {
          background-color: map-get($colors, dusty-blue-light);
          &:hover {
            background-color: rgba(map-get($colors, dusty-blue), 0.3) !important;
          }
        }
      }
    }
  }
  &-corporate {
    .day {
      span:last-child {
        &.selected {
          background-color: map-get($colors, lush) !important;
        }

        &.highlighted {
          background-color: map-get($colors, lush-light);
          &:hover {
            background-color: rgba(map-get($colors, lush), 0.3) !important;
          }
        }
      }
    }
  }
  &-health {
    .day {
      span:last-child {
        &.selected {
          background-color: map-get($colors, health) !important;
        }

        &.highlighted {
          background-color: map-get($colors, health-light);
          &:hover {
            background-color: rgba(map-get($colors, health), 0.3) !important;
          }
        }
      }
    }
  }
}
