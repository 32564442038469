@import 'styles/base.scss';

.notification {
  @include row(space-between, center);
  @include font($size: 14px, $lineHeight: 14px, $weight: bold);
  position: relative;
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  margin-bottom: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;

  @include for-size(mobile) {
    padding: 12px 16px;
    text-align: center;
    z-index: 1;
  }
}

.text {
  @include font($size: 14px, $lineHeight: 14px, $weight: bold);
  margin: 0;
  margin-top: 0px;
  text-align: left;

  @include for-size(mobile) {
    font-size: 14px;
  }
}

.action {
  @include font($weight: bold);
  text-decoration: underline;
  cursor: pointer;
  flex-shrink: 0;

  @include for-size(mobile) {
    margin-left: 5px;
    font-size: 14px;
  }

  &:hover {
    color: map-get($colors, grey-2);
  }
}

.type {
  color: map-get($colors, midnight);
  background-color: map-get($colors, beige);

  &-info {
    color: map-get($colors, midnight);
    background-color: map-get($colors, beige);
    a:hover {
      color: map-get($colors, lush);
    }
  }

  &-success {
    color: map-get($colors, white);
    background-color: map-get($colors, lush);
  }

  &-error {
    color: map-get($colors, white);
    background-color: map-get($colors, error);
  }
}

.theme {
  &-corporate {
    &.type {
      &-info a:hover {
        color: map-get($colors, teal);
      }
      &-success {
        background-color: map-get($colors, teal);
      }
    }
  }
  &-medical {
    &.type {
      &-info a:hover {
        color: map-get($colors, dusty-blue);
      }
      &-success {
        background-color: map-get($colors, dusty-blue);
      }
    }
  }
  &-health {
    &.type {
      &-info a:hover {
        color: map-get($colors, health);
      }
      &-success {
        background-color: map-get($colors, health);
      }
    }
  }
}
