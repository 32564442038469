@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 32px 0px 28px;
  background: map-get($colors, white);
  text-align: center;

  h3 {
  }

  h3 {
    @include font($family: primary, $size: 32px, $lineHeight: 38px);
    padding: 0px 24px;
  }

  p {
    padding: 25px 24px;
    @include font($size: 16px, $color: midnight);
  }

  @include for-size(mobile) {
    padding: 28px 0px;
  }
}

.form {
  padding-top: 35px;
}

.confirmation {
  padding: 0;
  margin: 80px 0;
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
}

.proceedButton {
  width: 225px;
  margin: 10px auto 0;
}

.terms {
  width: 100%;
  margin-top: 20px;
  font-family: map-get($font-family, secondary);
  color: map-get($colors, grey-5);

  .link {
    font-family: map-get($font-family, secondary-medium);
    font-weight: 500;
    letter-spacing: 0px;
    text-decoration: underline;
    color: map-get($colors, grey-5);

    &:hover {
      color: map-get($colors, lush);
      cursor: pointer;
    }
  }
}
