@import 'styles/base.scss';

.block {
  @include column(center, flex-start);
  background: map-get($colors, natural);
  width: 100%;
  padding: 56px 0px 41px 0px;

  @include for-size(mobile) {
    padding: 40px 0px 25px 0px;
  }
}

.hsafsa {
  margin-bottom: 20px;
  margin-top: 20px;
}

.top {
  @include row(center, flex-start);
  margin-bottom: 15px;

  @include for-size(mobile) {
    @include column(center, center);
  }

  h2 {
    text-align: center;
    margin-bottom: 8px;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      font-size: 28px;
    }
  }

  .editableBox {
    position: relative;
    margin-left: 8px;
    padding-bottom: 4px;
    border-bottom: solid 1.3px map-get($colors, midnight);

    &.disableOnStretch {
      display: none;
    }

    .zipInput {
      margin: 0;
      width: 130px;
      height: 38px;
      border-bottom: 0px;
      margin-top: -4px;
      overflow: hidden;
      padding-left: 8px;

      input {
        text-align: left;
        border: none;
        padding: 0;
        height: 100%;
        @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

        @include placeholder {
          @include font($size: 32px, $color: grey-3, $lineHeight: 38px, $family: primary);
        }
      }

      @include for-size(mobile) {
        font-size: 28px;
      }
    }

    .zipInputWrapper {
      background-color: transparent !important;
    }

    i, svg {
      position: absolute;
      right: 0;
      top: 0;
      margin-left: 3px;
      margin-top: 4px;
      font-size: 30px;
      cursor: pointer;
    }
  }
}

.description {
  text-align: center;
  @include font($size: 16px, $color: grey-5, $lineHeight: 25px);

  @include for-size(mobile) {
    width: 250px;
  }
}

.pricingsContainer {
  @include row(center, center);
  margin-top: 22px;

  @include for-size(mobile) {
    @include column(stretch, center);
  }
}
