@import 'styles/base.scss';

.block {
  width: 100%;
  padding: 0px;
  background-color: map-get($colors, white);

  h3 {
    width: 100%;
    padding: 56px 24px 32px;
    text-align: center;
    @include font($size: 32px, $color: midnight, $lineHeight: 38px, $family: primary);

    @include for-size(mobile) {
      padding: 38px 24px 20px;
      font-size: 32px;
    }
  }
}

.glContainerSmall {
  @include row(center, flex-start);

  @include for-size(mobile) {
    @include column(center, flex-start);
  }
}
