@import 'styles/base.scss';

.block {
  width: 100%;
  height: 56px;
  background-color: white;
  background-repeat: repeat-x;
  background-size: 1280px;

  @include for-size(mobile) {
    height: 50px;
  }
}
