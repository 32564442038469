// Global classes which can be accessed in all components and screens

/*
* Containers
* All container classes are prefixed with 'gl' to avoid class name conflicts, since 'container' is a generic name that is used often.
*/
.glContainer {
  width: 1220px;
  padding: 0px 24px;
  margin: 0 auto;
  position: relative;

  @include for-size(1220px) {
    width: 100%;
  }
}

.glContainerNarrow {
  width: 750px;
  padding: 0px 24px;
  margin: 0 auto;
  position: relative;

  @include for-size(750px) {
    width: 100%;
  }
}

.glContainerSmall {
  width: 1048px;
  padding: 0px 24px;
  margin: 0 auto;
  position: relative;

  @include for-size(1028px) {
    width: 100%;
  }
}

.glContainerStart {
  @extend .glContainer;
  margin-top: 56px;
}

.glContainerFull {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  > :last-child {
    margin-bottom: 0px;
  }

  &:empty {
    margin-bottom: 0px;
  }
}

.glContainerHalf {
  width: calc(50% - 35px);
  margin-bottom: 40px;

  > :last-child {
    margin-bottom: 0px;
  }

  &:empty {
    margin-bottom: 0px;
  }
}

.glContainerHalfCenter {
  width: 345px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;

  > :last-child {
    margin-bottom: 0px;
  }
}

.glContainerInside {
  width: 810px;
  padding: 0px 24px;
  margin-top: 56px;
  text-align: center;
  color: map-get($colors, midnight);

  @include for-size(810px) {
    width: 100%;
  }

  @include for-size(mobile) {
    margin-top: 22px;
  }
}

.glVisibleOnMobile {
  display: none;

  @include for-size(mobile) {
    display: block;
  }
}

.glVisibleOnDesktop {
  display: block;

  @include for-size(mobile) {
    display: none;
  }
}

.glMobileLineBreak {
  display: none;
  @include for-size(mobile) {
    display: block;
  }
}

.glTooltip {
  background-color: map-get($colors, white) !important;
  border-radius: 2px !important;
  color: map-get($colors, midnight) !important;
  font-size: 14px !important;
  padding: 14px !important;
  line-height: 19px !important;
  font-family: map-get($font-family, secondary-medium) !important;
  opacity: 1 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;
  max-width: 345px;
  border-top: 1px solid map-get($colors, grey-1) !important;
  text-align: left !important;
  white-space: normal !important;

  @include for-size(mobile) {
    max-width: calc(100% - 24px) !important;
  }

  &:after {
    border-top-color: map-get($colors, white) !important;
  }
}

.glTooltipIcon {
  color: map-get($colors, grey-3);
  width: 20px;
  height: 20px;
  top: 4px;
  left: 3px;
}

.glItalic {
  font-family: map-get($font-family, primary-italic);
}

.glBoldLink {
  font-family: map-get($font-family, secondary-semibold);
  text-decoration: underline;
  cursor: pointer !important;
}

.glUnderlineLink {
  text-decoration: underline;

  &:hover {
    color: map-get($colors, lush);
    cursor: pointer;
  }
}

.glCursorPointer {
  cursor: pointer;
}
