@import 'styles/base.scss';

.button {
  min-width: 100px;
  width: auto;
  height: 50px;
  display: inline-block;
  letter-spacing: 0.3px;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border-radius: 2px;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px !important;
  transition: all 0.2s;
  overflow: hidden;
  position: relative;
  user-select: none;
  @include font($size: 18px, $lineHeight: 38px, $weight: semibold);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px map-get($colors, lush);
  }

  &.primary {
    color: map-get($colors, white);
    background-color: map-get($colors, midnight);
    border-color: map-get($colors, midnight);

    .pill {
      background-color: map-get($colors, white);
      color: map-get($colors, midnight);
    }

    &:hover {
      background-color: map-get($colors, midnight-hover);
      border-color: map-get($colors, midnight);
    }
  }

  &.primary-green {
    color: map-get($colors, white);
    background-color: map-get($colors, actionable-green);
    border-color: map-get($colors, actionable-green);

    .pill {
      background-color: map-get($colors, white);
      color: map-get($colors, actionable-green);
    }

    &:hover {
      background-color: map-get($colors, actionable-green-hover);
      border-color: map-get($colors, actionable-green);
    }
  }

  &.accent {
    color: map-get($colors, white);
    background-color: map-get($colors, lush);
    border-color: map-get($colors, lush);

    .pill {
      background-color: map-get($colors, white);
      color: map-get($colors, lush);
    }

    &:hover {
      background-color: darken(map-get($colors, lush), 0.1);
      border-color: darken(map-get($colors, lush), 0.1);
    }
  }

  &.accent-alt {
    color: map-get($colors, white);
    background-color: map-get($colors, lush-alt);
    border-color: map-get($colors, lush-alt);

    .pill {
      background-color: map-get($colors, white);
      color: map-get($colors, lush-alt);
    }

    &:hover {
      background-color: darken(map-get($colors, lush-alt-hover), 0.1);
      border-color: darken(map-get($colors, lush-alt-hover), 0.1);
    }
  }

  &.inverse {
    color: map-get($colors, midnight);
    background-color: map-get($colors, white);
    border-color: map-get($colors, white);
    line-height: 36px;

    .pill {
      background-color: map-get($colors, midnight);
      color: map-get($colors, white);
    }

    &:hover {
      color: map-get($colors, white);
      background-color: map-get($colors, midnight-hover);
      border-color: map-get($colors, midnight-hover);
    }
  }

  &.secondary {
    color: map-get($colors, midnight);
    background-color: map-get($colors, white);
    border-color: map-get($colors, midnight);

    .pill {
      background-color: map-get($colors, midnight);
      color: map-get($colors, white);
    }

    &:hover {
      color: map-get($colors, white);
      background-color: map-get($colors, midnight-hover);
      border-color: map-get($colors, midnight);
    }
  }

  &.transparent {
    color: map-get($colors, white);
    background-color: transparent;
    border-color: map-get($colors, white);

    .pill {
      background-color: map-get($colors, midnight);
      color: map-get($colors, white);
    }

    &:hover {
      color: map-get($colors, white);
      background-color: map-get($colors, midnight-hover);
      border-color: map-get($colors, midnight-hover);
    }
  }

  &.destructive {
    color: map-get($colors, error);
    background-color: transparent;
    border-color: transparent;

    .pill {
      background-color: map-get($colors, error);
      color: map-get($colors, white);
    }

    &:hover {
      color: map-get($colors, error-dark);
      background-color: rgba(map-get($colors, grey-3), 0.1);
      border-color: transparent;
    }
  }

  &.destructive-alt {
    color: map-get($colors, white);
    background-color: map-get($colors, error-alt);
    border-color: map-get($colors, error-alt);

    .pill {
      background-color: map-get($colors, white);
      color: map-get($colors, error-alt);
    }

    &:hover {
      background-color: map-get($colors, error-dark);
      border-color: map-get($colors, error-alt);
    }
  }

  &.disabled {
    background-color: map-get($colors, grey-2) !important;
    border-color: map-get($colors, grey-2) !important;
    color: map-get($colors, grey-3) !important;
    cursor: default !important;
    pointer-events: none !important;
  }

  &.loading {
    background-color: map-get($colors, grey-5) !important;
    border-color: map-get($colors, grey-5) !important;
    color: map-get($colors, grey-5) !important;
    cursor: default;
    pointer-events: none;
  }

  &.flex {
    width: 100%;
    min-width: 0px;
  }

  &.flexMobile {
    @include for-size(mobile) {
      width: 100%;
      min-width: 0px;
    }
  }
}

.iconLeft,
.iconRight {
  position: absolute;
  top: calc(50% - 12.5px);
}

.iconLeft {
  left: 6px;
}

.iconRight {
  right: 6px;
}

.hasIconLeft {
  padding-left: 34px !important;
}

.hasIconRight {
  padding-right: 34px !important;
}

.pill {
  position: relative;
  top: -2px;

  &:first-of-type {
    margin-left: 10px;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  color: map-get($colors, white);
  margin-left: -12px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px !important;
}

.theme {
  &-corporate {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px map-get($colors, teal);
    }

    &.accent {
      background-color: map-get($colors, teal);
      border-color: map-get($colors, teal);

      &:hover {
        background-color: darken(map-get($colors, teal), 0.1);
        border-color: darken(map-get($colors, teal), 0.1);
      }
    }
  }

  &-medical {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px map-get($colors, dusty-blue);
    }

    &.accent {
      background-color: map-get($colors, dusty-blue);
      border-color: map-get($colors, dusty-blue);

      &:hover {
        background-color: darken(map-get($colors, dusty-blue), 0.1);
        border-color: darken(map-get($colors, dusty-blue), 0.1);
      }
    }
  }

  &-health {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px map-get($colors, health);
    }

    &.accent {
      background-color: map-get($colors, health);
      border-color: map-get($colors, health);

      &:hover {
        background-color: darken(map-get($colors, health), 0.1);
        border-color: darken(map-get($colors, health), 0.1);
      }
    }
  }

  &-spa {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px #759a9f !important;
    }

    &.accent {
      background-color: #759a9f !important;
      border-color: #759a9f !important;

      &:hover {
        background-color: darken(#759a9f, 0.1) !important;
        border-color: darken(#759a9f, 0.1) !important;
      }
    }
  }

  &-atWork {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px map-get($colors, blue-zeel-work) !important;
    }

    &.accent {
      background-color: map-get($colors, blue-zeel-work) !important;
      border-color: map-get($colors, blue-zeel-work) !important;

      &:hover {
        background-color: map-get($colors, blue-zeel-work-hover) !important;
        border-color: map-get($colors, blue-zeel-work-hover) !important;
      }

      &.disabled {
        background-color: map-get($colors, grey-2) !important;
        border-color: map-get($colors, grey-2) !important;
        color: map-get($colors, grey-3) !important;
        cursor: default !important;
        pointer-events: none !important;
      }
    }

    &.secondary {
      color: map-get($colors, blue-zeel-work) !important;
      background-color: map-get($colors, white);
      border-color: map-get($colors, blue-zeel-work) !important;

      .pill {
        background-color: map-get($colors, blue-zeel-work) !important;
        color: map-get($colors, white);
      }

      &:hover {
        color: map-get($colors, white) !important;
        background-color: map-get($colors, blue-zeel-work-hover) !important;
        border-color: map-get($colors, blue-zeel-work) !important;
      }
    }
  }
}
