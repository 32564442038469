@import 'styles/base.scss';

.block {
  padding-bottom: 40px;
}

.imagesGridContainer {
  @include row(flex-start, flex-start);
  flex-wrap: wrap;

  @include for-size(mobile) {
    display: none;
  }
}

.imagesCarouselContainer {
  display: none;

  @include for-size(mobile) {
    display: block;
  }
}

.imageWrapper {
  position: relative;
  width: 33.33%;

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  @include for-size(mobile) {
    width: 100%;
    height: 90vw;
  }
}

.image {
  position: absolute;
  left: 24px;
  top: 24px;
  height: calc(100% - 48px);
  width: calc(100% - 48px);
  background-size: cover;
  background-position: center;

  @include for-size(mobile) {
    left: 12px;
    top: 12px;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
  }
}
