@import 'styles/base.scss';

.link {
  @include row(flex-start, center);
  display: inline-flex;
  color: map-get($colors, midnight);
  cursor: pointer;

  &:hover {
    color: map-get($colors, lush);
  }
}

.hasPointer {
  cursor: pointer;
}

.iconAfterText {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.theme {
  &-corporate {
    &:hover {
      color: map-get($colors, teal) !important;
    }
  }

  &-medical {
    &:hover {
      color: map-get($colors, dusty-blue) !important;
    }
  }

  &-health {
    &:hover {
      color: map-get($colors, health) !important;
    }
  }

  &-atWork {
    color: map-get($colors, blue-zeel-work) !important;

    &:hover {
      color: map-get($colors, blue-zeel-work-hover) !important;
      text-decoration: underline;
    }
  }
}
