@import 'styles/base.scss';

.base {
  @include column(center, flex-end);
  min-height: 130px;
  width: 160px;
  height: 160px;
  margin: 10px;
  border-radius: 3.2px;
  padding: 22px 0px;
  border: solid 1.5px transparent;

  @include for-size(1100px) {
    width: 140px;
  }

  @include for-size(980px) {
    width: 120px;

    img {
      width: 100px;
    }
  }

  @include for-size(850px) {
    width: 100px;

    img {
      width: 80px;
    }
  }

  @include for-size(767px) {
    width: calc(25vw - 40px);

    img {
      width: auto;
    }
  }

  @include for-size(755px) {
    width: calc(33.3vw - 40px);
  }

  @include for-size(585px) {
    width: calc(50vw - 42px);
    margin-right: 0px;
    margin-left: 0px;
  }

  @include for-size(380px) {
    img {
      width: calc(50vw - 80px);
    }
  }

  @include for-size(300px) {
    width: calc(100vw - 90px);
  }

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border: solid 1.5px map-get($colors, lush);
  }

  @include for-size(mobile) {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      border: solid 1.5px transparent;
    }
  }
}

.quote {
  width: 40px;
  margin-bottom: 18px;

  @include for-size(mobile) {
    width: 32px;
  }
}

.source {
  padding-top: 24px;
  text-align: center;
  @include font($size: 16px, $color: midnight, $lineHeight: 18px);

  @include for-size(mobile) {
    font-size: 14px;
  }
}
