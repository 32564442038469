@import 'styles/base.scss';

.detail {
  width: 285px;
  padding: 28px 12px;
  text-align: center;

  @include for-size(mobile) {
    padding: 20px 0px;
  }

  @include for-size(350px) {
    width: 100%;
  }

  h5 {
    margin-bottom: 16px;
    @include font($size: 18px, $color: midnight, $lineHeight: 22px, $weight: semibold);
  }

  p {
    letter-spacing: normal;
    @include font($size: 16px, $color: midnight, $lineHeight: 23px);
  }

  img {
    margin-bottom: 16px;
    display: inline-block;
    //height:70px;
    max-height: 70px;

    @include for-size(mobile) {
      margin-bottom: 20px;
    }
  }

  a {
    letter-spacing: normal;
    text-decoration: underline;
    @include font($size: 16px, $color: midnight);
  }

  .content {
    a {
      letter-spacing: normal;
      color: map-get($colors, midnight) !important;
      text-decoration: underline !important;
      @include font($size: 16px, $color: midnight);
    }
  }
}
