@import 'styles/base.scss';

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  user-select: all;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.3);
}

.hidden {
  display: none;
  pointer-events: none;
}

.modal {
  position: absolute;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: map-get($colors, white);
  width: 500px;
  height: auto;
  max-height: 96vh;
  overflow: auto;
  border-radius: 4px;
  user-select: none;
  padding: 50px 56px;

  &.loading {
    width: 100px;
    height: 56px;
    padding: 20px;

    @include for-size(mobile) {
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      padding: 0px;
      max-height: 100vh;
      border-radius: 0px;
      transform: none;
    }

    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 20px !important;
      color: map-get($colors, grey-4);
      margin-top: -9px;
      margin-left: -12px;
    }
  }

  &.loaded {
    @include for-size(mobile) {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      left: 0;
      top: 0;
      transform: none;
      overflow-y: auto;
      border-radius: 0px;
      padding: 43px 25px 30px 28px;
      display: flex;
      align-items: center;
    }
  }
}

.mobile-fullscreen {
  @include for-size(mobile) {
    height: 100vh;
  }
}

.mobile-drawer {
  @include for-size(mobile) {
    height: auto !important;
    bottom: 0 !important;
    top: auto !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

.modalHeader {
  width: 100%;
  text-align: center;
}

.modalBodyWrapper {
  margin-top: 25px;
  overflow-y: auto;
  width: calc(100% + 17px);
  padding-right: 17px;
  height: 100%;

  @include for-size(mobile) {
    margin-bottom: 82px;
  }

  textarea {
    margin-bottom: 0px;
    @include font();

    @include placeholder {
      @include font();
    }
  }
}

.modalBody {
  text-align: center;
  @include font($size: 14px, $color: midnight, $lineHeight: 22px);

  &.expandTop {
    margin-top: 50px;
  }

  &.expandDown {
    margin-bottom: 50px;
  }

  > :last-child {
    margin-bottom: 0px;
  }
}

.modalFooter {
  position: relative;
  margin-top: 25px;
  width: 100%;
  padding-top: 16px;
  flex: 1 1;

  @include for-size(mobile) {
    position: fixed;
    padding: 16px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid map-get($colors, grey-3);
  }
}

.close {
  position: absolute !important;
  pointer-events: all !important;
  top: 20px;
  right: 20px;
  font-size: 25px !important;
  line-height: 19px !important;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  color: map-get($colors, grey-4);

  &:hover {
    color: map-get($colors, grey-5);
  }
}

.back {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 25px !important;
  line-height: 19px !important;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  color: map-get($colors, grey-4);

  &:hover {
    color: map-get($colors, grey-5);
  }
}

.error {
}

.insideModal {
  @include column(stretch, flex-start);
  position: relative;
  height: auto;
  width: 100%;

  @include for-size(mobile) {
    height: 100%;
    max-height: max-content;
  }
}

.icon {
  margin-bottom: 30px;
  font-size: 54px;
  height: 54px;
  color: map-get($colors, lush);
}

.title {
  width: 100%;
  min-height: 28px;
  @include font($size: 28px, $color: midnight, $lineHeight: 32px, $weight: medium);
}

.subtitle {
  margin-top: 10px;
  width: 100%;
  @include font($size: 14px, $color: midnight, $lineHeight: 22px);

  &:empty {
    display: none;
  }
}

.divider {
  width: 43px;
  display: inline-block;
  border-top: 1px solid map-get($colors, grey-3);
  height: 1px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.centerLink {
  height: 29px;
  display: inline-block;
  @include font($size: 14px, $color: midnight, $lineHeight: 29px, $weight: bold);

  i {
    font-weight: bold;
  }

  &:hover {
    color: map-get($colors, lush);
    cursor: pointer;
  }
}

.insideModal p {
  text-align: center;
  width: 100%;
  margin-top: 16px;
  @include font($size: 14px, $lineHeight: 22px);
}

.button {
  width: 100%;
  min-width: 100%;
}

.input {
  margin-bottom: 40px !important;
}

.halfInput {
  width: calc(50% - 10px);
  margin-right: 20px;

  + .halfInput {
    margin-right: 0px;
  }
}

.justify {
  text-align: justify;
}
