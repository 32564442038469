@import 'styles/base.scss';

.block {
  .blockTitle {
    margin-bottom: 60px;
  }

  .content {
    @include row(space-between, center);
  }

  .carousel {
    .logoContainer {
      min-height: 100px;
      max-width: 250px;
      margin: auto;
      padding-bottom: 24px;

      .logo {
        width: 100%;
        height: 150px;
        object-fit: contain;
        position: relative;
        margin-bottom: 16px;
        padding: 0px 16px;
      }

      p {
        @include font($size: 16px, $lineHeight: 24px, $color: grey-4, $weight: bold);
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      h4 {
        @include font($size: 20px, $lineHeight: 24px, $color: midnight, $weight: semibold);
      }

      &--clickable:hover {
        h4 {
          color: map-get($colors, lush);
        }
      }
    }

    button[class*='slick-arrow '] {
      &:before {
        display: inline-block;
        font: normal normal normal 14px/1 CustomIconsVF !important;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 50px !important;
        color: map-get($colors, grey-5) !important;
        line-height: 30px !important;

        &:hover {
          color: map-get($colors, midnight) !important;
        }
      }

      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    button[class*='slick-disabled'] {
      display: none !important;
    }

    button[class*='slick-next'] {
      &:before {
        content: '\e830' !important;
      }

      right: -45px;

      @include for-size(1040px) {
        right: -15px;
      }
    }

    button[class*='slick-prev'] {
      &:before {
        content: '\e82f' !important;
      }

      left: -70px;

      @include for-size(1040px) {
        left: -34px;
      }
    }
  }

  :global(.slick-dots) {
    bottom: 0px;
    li {
      margin: 0;

      button:before {
        color: white !important;
        text-shadow: 0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight),
          0px 0px 1px map-get($colors, midnight), 0px 0px 1px map-get($colors, midnight);
      }
    }
  }
}
