@import 'styles/base.scss';

.linkButton {
  display: inline-block;
  height: 24px;
  letter-spacing: 0px;
  cursor: pointer;
  @include font($size: 16px, $color: midnight, $weight: medium, $lineHeight: 24px);

  .iconBeforeText {
    margin-right: 5px;
  }

  .iconAfterText {
    margin-left: 5px;
  }

  i {
    font-weight: bold;
  }

  &:hover {
    color: map-get($colors, lush);
  }
}

.theme {
  &-corporate {
    .linkButton {
      &:hover {
        color: map-get($colors, teal);
      }
    }
  }
  &-medical {
    .linkButton {
      &:hover {
        color: map-get($colors, dusty-blue);
      }
    }
  }
  &-health {
    .linkButton {
      &:hover {
        color: map-get($colors, health);
      }
    }
  }
}
