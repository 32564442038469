@import 'styles/base.scss';

.product {
  @include column(center, flex-start);
  width: 180px;
  transform: scale(1);
  transition: all 0.3s ease;
  padding-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px transparent;
  user-select: none;

  h5 {
    @include font($size: 18px, $color: midnight, $lineHeight: 22px, $weight: semiBold);
    margin: 6px 0;
    text-align: center;
  }

  .description {
    @include font($size: 14px, $color: grey-4, $lineHeight: 24px, $weight: light);
    text-align: center;
    padding: 0 10px;
  }

  &:hover {
    transform: scale(1.05);
    background-color: rgba(map-get($colors, white), 0.3);
    box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.03);
  }

  &:active {
    transform: scale(1);
    transition: all 0.1s ease;
  }

  @include for-size(mobile) {
    width: 100%;
  }
}

.imgContainer {
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.priceRow {
  @include row(center, center);
  margin-top: 8px;

  .price {
    &-striked {
      text-decoration: line-through;
    }
  }

  p {
    @include font($size: 16px, $color: midnight, $weight: light);
    margin: 0 4px;
  }

  .discount {
    @include font($size: 16px, $color: lush, $weight: bold);
  }
}
