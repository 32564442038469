@import 'styles/base.scss';

.section {
  display: flex;
  justify-content: center;

  .stackedItems {
    width: 550px;
    background: transparent;

    h3 {
      text-align: left;
      padding: 20px 0;
    }

    > div {
      width: 100%;
    }

    .details {
      width: 100%;
      padding: 10px 0;
    }

    @include for-size(mobile) {
      h3 {
        text-align: center;
      }
      .details {
        display: flex;

        > div {
          text-align: left;
          font-size: 18px;
        }
        .icon {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }
    }
  }

  button {
    margin-top: 30px;
  }

  .phone {
    width: 275px;
    transform: scale(2);
    position: relative;
    top: 19px;
  }

  .rightSide,
  .leftSide {
    text-align: left;
    width: 50%;
  }
  &.phoneOnLeft .leftSide {
    text-align: center;
  }
  &:not(.phoneOnLeft) .rightSide {
    text-align: center;
  }

  @include for-size(mobile) {
    flex-direction: column;

    .phone {
      width: 220px;
      top: 11px;
    }

    .stackedItems {
      width: 100%;

      .details .icon {
        width: 45px;
        height: 45px;
        margin-right: 15px;
      }
    }

    button {
      margin-top: 10px;
    }

    .leftSide {
      width: 100%;
      margin-bottom: 80px;
    }

    .rightSide {
      width: 100%;
    }

    &:not(.phoneOnLeft) {
      .rightSide {
        order: 1;
      }
      .leftSide {
        order: 2;
      }
    }
  }

  @include between-size($desktopWidth, 1024px) {
    .stackedItems {
      width: auto;
    }
  }
}
