@import 'styles/base.scss';

.pricing {
  @include column(center, center);
  width: 350px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: map-get($colors, white);
  margin: 15px;
  padding: 17px;
  transition: opacity 0.3s ease;

  @include for-size(mobile) {
    margin: 8px;
  }

  h4 {
    text-align: center;
    margin-top: 12px;
    @include font($size: 20px, $color: midnight, $lineHeight: 1, $weight: medium);
  }

  .cost {
    text-align: center;
    margin: 18px 0 12px;
    @include font($size: 36px, $color: midnight, $lineHeight: 1.11);
  }

  .highlightCost {
    color: map-get($colors, lush);
  }

  .details {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    @include font($size: 14px, $color: grey-5, $lineHeight: 1.43);
  }

  .button {
    margin: 20px 0 0;
    width: 100%;
  }
}

.loading {
  opacity: 0.4;
  pointer-events: none;
}

.invalid {
  opacity: 0.05;
  pointer-events: none;
}
