@import 'styles/base.scss';

.base {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}

.center {
  .wrapper {
    @include row(center, center);
  }
  label {
    text-align: center;
  }
  .color {
    margin-right: 8px;
    margin-left: 8px;
  }
}

.color {
  position: relative;
  margin-top: 7px;
  margin-right: 16px;
  display: inline-block;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  background-color: map-get($colors, grey-1);
  user-select: none;
  outline: none;

  .colorLabel {
    @include row(center, center);
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 100%;
    max-width: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;

    p {
      @include font($size: 14px, $weight: bold, $color: midnight);
      text-align: center;
      width: auto;
      text-transform: capitalize;
    }
  }

  .icon {
    opacity: 0;
    transition: opacity 0.2s ease;
    position: absolute;
    top: 5px;
    left: 5px;
    color: map-get($colors, white);
    font-size: 22px;
  }

  &.active {
    .icon {
      opacity: 1;
    }
    .colorLabel {
      opacity: 1;
    }
  }

  .invisible {
    display: none;
  }
}

.wrapper {
  width: 100%;
  text-align: left;
  outline: none;
}

label {
  display: block;
  width: 100%;
  text-align: left;
  height: 16px;
  user-select: none;
  @include font($size: 14px, $color: grey-5);
}

.validationText {
  min-height: 17px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  color: map-get($colors, error);
  @include font($size: 14px, $lineHeight: 17px);
}
